export const ACCESS_TOKEN = 'Access-Token'
export const ACCESS_TOKEN1 = 'Access-Token1'
export const CURRENT_GENKEY = 'Current-GenKey' // 本地存aesKey
export const GENKEY = 'GenKey' // 本地存aesKey
export const SIDEBAR_TYPE = 'SIDEBAR_TYPE'
export const DEFAULT_THEME = 'DEFAULT_THEME'
export const DEFAULT_LAYOUT_MODE = 'DEFAULT_LAYOUT_MODE'
export const DEFAULT_COLOR = 'DEFAULT_COLOR'
export const DEFAULT_COLOR_WEAK = 'DEFAULT_COLOR_WEAK'
export const DEFAULT_FIXED_HEADER = 'DEFAULT_FIXED_HEADER'
export const DEFAULT_FIXED_SIDEMENU = 'DEFAULT_FIXED_SIDEMENU'
export const DEFAULT_FIXED_HEADER_HIDDEN = 'DEFAULT_FIXED_HEADER_HIDDEN'
export const DEFAULT_CONTENT_WIDTH_TYPE = 'DEFAULT_CONTENT_WIDTH_TYPE'
export const DEFAULT_MULTI_PAGE = 'DEFAULT_MULTI_PAGE'
export const USER_NAME = 'Login_Username'
export const USER_INFO = 'Login_Userinfo'
export const USER_AUTH = 'LOGIN_USER_BUTTON_AUTH'
export const SYS_BUTTON_AUTH = 'SYS_BUTTON_AUTH'
export const LOCALE = 'LOCALE'
export const ENABLE_I18N = 'ENABLE_I18N'
export const TABLE_BORDERED = 'TABLE_BORDERED'
export const MODAL_BTN_RIGHT = 'MODAL_BTN_RIGHT'
export const IS_TAB_HOME_PAGE = 'IS_TAB_HOME_PAGE'
export const ENABLE_SCROLL_BAR = 'ENABLE_SCROLL_BAR'
export const UI_CACHE_DB_DICT_DATA = 'UI_CACHE_DB_DICT_DATA'
export const CORP_CHOOSE_TYPE = 'CORP_CHOOSE_TYPE'

/* 项目相关 */
export const PRJ_NAME = 'PRJ_NAME'
export const PRJ_ID = 'PRJ_ID'
export const PROJECT_ID = 'PROJECT_ID'
export const PROJECT_MANAGER_FULLNAME = 'PROJECT_MANAGER_FULLNAME'
export const PROJECT_MANAGER_NAME = 'PROJECT_MANAGER_NAME'
export const CATEGORY = 'CATEGORY'
export const TYPE = 'TYPE'
export const START_DATE = 'START_DATE'
export const FINISH_DATE = 'FINISH_DATE'
export const PROJECT_MANAGER_GROUPNAME = 'PROJECT_MANAGER_GROUPNAME'
export const PRJ_CLASS = 'PRJ_CLASS'

// 判断企业级项目级
export const COR_PRO_FLAG = 'COR_PRO_FLAG'
export const PRJ_CODE = 'PRJ_CODE'

//左侧菜单
export const side_MENU = 'side_MENU'

export const CONTENT_WIDTH_TYPE = {
  Fluid: 'Fluid',
  Fixed: 'Fixed'
}
