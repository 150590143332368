import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import router from './router'
import 'element-ui/lib/theme-chalk/index.css'
import axios from 'axios'
import '@/assets/css/main.css'
import ECharts from 'vue-echarts'
import Storage from 'vue-ls'
import htmlToPdf from '@/utils/htmlToPdf'
Vue.use(htmlToPdf)

let options = {
  namespace: 'vuejs__', // key键前缀
  name: 'ls', // 命名Vue变量.[ls]或this.[$ls],
  storage: 'local', // 存储名称: session, local, memory
}
// import 'echarts/lib/chart/pie'

Vue.component('v-chart', ECharts)
Vue.prototype.$axios = axios
Vue.use(ElementUI)
Vue.use(Storage, options)
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
