<template>
  <div id="app">
    <el-container>
      <el-header>
        <div id="bannerWrap_tem_82_29"
             class="fullcolumn-outer">
          <div>您好，欢迎访问深圳市延嘉数字科技有限公司！</div>
          <div>服务热线：0755-21036315 15767911394</div>
        </div>
        <div class="nav">
          <a href="https://www.szyanjia.com/sy"
             target="_self"
             class="w-nav-item-link">
            <el-image style="width: 185px; height: 70px;"
                      fit="contain"
                      :title="'首页'"
                      :src="require('@/assets/img/logo.jpg')">
            </el-image>
          </a>
          <div id="div-nav nav_tem_85_5">
            <ul class="w-nav"
                navstyle="style1"
                style="height: 42px; width: 888px;margin-top: 16px;">
              <li class="w-nav-inner"
                  style="height:43px;line-height:43px;width:12.5%;"
                  @mouseover="hoverColour($event)"
                  @mouseout="hoverHidden($event)">
                <div class="w-nav-item">
                  <i class="w-nav-item-line"></i>
                  <a href="https://www.szyanjia.com/sy"
                     target="_self"
                     class="w-nav-item-link">
                    <span class="mw-iconfont"></span>
                    <span class="w-link-txt">首页</span>
                  </a>
                </div>
              </li>
              <li class="w-nav-inner"
                  style="height:43px;line-height:43px;width:12.5%;"
                  @mouseover="hoverColour($event)"
                  @mouseout="removeHidden($event)">
                <div class="w-nav-item">
                  <i class="w-nav-item-line"></i>
                  <a a
                     href="https://www.szyanjia.com/gsjj"
                     target="_self"
                     class="w-nav-item-link">
                    <span class="mw-iconfont"></span>
                    <span class="w-link-txt">关于我们</span>
                  </a>
                </div>
                <ul class="w-subnav"
                    style="width:111px;display: none;height: 120px;padding-top: 0px;margin-top: 0px;padding-bottom: 0px;margin-bottom: 0px;">
                  <li class="w-subnav-item userWidth">
                    <a a
                       href="https://www.szyanjia.com/gsjj"
                       target="_self"
                       class="w-subnav-link"
                       style="height:40px;line-height:40px;">
                      <span class="mw-iconfont"></span>
                      <span class="w-link-txt">公司简介</span>
                    </a>
                  </li>
                  <li class="w-subnav-item userWidth">
                    <a a
                       href="https://www.szyanjia.com/qywh"
                       target="_self"
                       class="w-subnav-link"
                       style="height:40px;line-height:40px;">
                      <span class="mw-iconfont"></span>
                      <span class="w-link-txt">企业文化</span>
                    </a>
                  </li>
                  <li class="w-subnav-item userWidth">
                    <a a
                       href="https://www.szyanjia.com/zjtd"
                       target="_self"
                       class="w-subnav-link"
                       style="height:40px;line-height:40px;">
                      <span class="mw-iconfont"></span>
                      <span class="w-link-txt">专家团队</span>
                    </a>
                  </li>
                  <li class="w-subnav-item userWidth">
                    <a a
                       href="https://www.szyanjia.com/hzgx"
                       target="_self"
                       class="w-subnav-link"
                       style="height:40px;line-height:40px;">
                      <span class="mw-iconfont"></span>
                      <span class="w-link-txt">合作关系</span>
                    </a>
                  </li>
                  <!-- <li class="w-subnav-item userWidth">
                    <a href="https://www.szyanjia.com/yqlj"
                       target="_self"
                       class="w-subnav-link"
                       style="height:40px;line-height:40px;">
                      <span class="mw-iconfont"></span>
                      <span class="w-link-txt">友情链接</span>
                    </a>
                  </li> -->
                </ul>
              </li>
              <li class="w-nav-inner"
                  style="height:43px;line-height:43px;width:12.5%;"
                  @mouseover="hoverColour($event)"
                  @mouseout="removeHidden($event)">
                <div class="w-nav-item">
                  <i class="w-nav-item-line"></i>
                  <a a
                     href="https://www.szyanjia.com/jyjy"
                     target="_self"
                     class="w-nav-item-link">
                    <span class="mw-iconfont"></span>
                    <span class="w-link-txt">智能制造</span>
                  </a>

                </div>
                <ul class="w-subnav"
                    style="width:111px;display: none;height: 120px;padding-top: 0px;margin-top: 0px;padding-bottom: 0px;margin-bottom: 0px;">
                  <li class="w-subnav-item userWidth">
                    <a a
                       href="https://www.szyanjia.com/jyjy"
                       target="_self"
                       class="w-subnav-link"
                       style="height:40px;line-height:40px;">
                      <span class="mw-iconfont"></span>
                      <span class="w-link-txt">精益经营</span>
                    </a>
                  </li>
                  <li class="w-subnav-item userWidth">
                    <a a
                       href="https://www.szyanjia.com/zngcgh"
                       target="_self"
                       class="w-subnav-link"
                       style="height:40px;line-height:40px;">
                      <span class="mw-iconfont"></span>
                      <span class="w-link-txt">智能工厂规划</span>
                    </a>
                  </li>
                  <li class="w-subnav-item userWidth">
                    <a a
                       href="https://www.szyanjia.com/szhgl"
                       target="_self"
                       class="w-subnav-link"
                       style="height:40px;line-height:40px;">
                      <span class="mw-iconfont"></span>
                      <span class="w-link-txt">数字化管理</span>
                    </a>
                  </li>
                  <li class="w-subnav-item userWidth">
                    <a a
                       href="https://www.szyanjia.com/csdpg"
                       target="_self"
                       class="w-subnav-link"
                       style="height:40px;line-height:40px;">
                      <span class="mw-iconfont"></span>
                      <span class="w-link-txt">成熟度评估</span>
                    </a>
                  </li>
                </ul>
              </li>
              <li class="w-nav-inner"
                  style="height:43px;line-height:43px;width:12.5%;"
                  @mouseover="hoverColour($event)"
                  @mouseout="removeHidden($event)">
                <div class="w-nav-item">
                  <i class="w-nav-item-line"></i>
                  <a href="https://www.szyanjia.com/zhny"
                     target="_self"
                     class="w-nav-item-link">
                    <span class="mw-iconfont"></span>
                    <span class="w-link-txt">智慧平台</span>
                  </a>

                </div>
                <ul class="w-subnav"
                    style="width:111px;display: none;height: 120px;padding-top: 0px;margin-top: 0px;padding-bottom: 0px;margin-bottom: 0px;">
                  <li class="w-subnav-item userWidth">
                    <a a
                       href="https://www.szyanjia.com/zhny"
                       target="_self"
                       class="w-subnav-link"
                       style="height:40px;line-height:40px;">
                      <span class="mw-iconfont"></span>
                      <span class="w-link-txt">智慧水产</span>
                    </a>
                  </li>
                  <li class="w-subnav-item userWidth">
                    <a a
                       href="https://www.szyanjia.com/zhyq"
                       target="_self"
                       class="w-subnav-link"
                       style="height:40px;line-height:40px;">
                      <span class="mw-iconfont"></span>
                      <span class="w-link-txt">智慧园区</span>
                    </a>
                  </li>
                </ul>
              </li>
              <li class="w-nav-inner"
                  style="height:43px;line-height:43px;width:12.5%;"
                  @mouseover="hoverColour($event)"
                  @mouseout="removeHidden($event)">
                <div class="w-nav-item">
                  <i class="w-nav-item-line"></i>
                  <a a
                     href="https://www.szyanjia.com/jyjy_cgal"
                     target="_self"
                     class="w-nav-item-link">
                    <span class="mw-iconfont"></span>
                    <span class="w-link-txt">成功案例</span>
                  </a>

                </div>
                <ul class="w-subnav"
                    style="width:111px;display: none;height: 120px;padding-top: 0px;margin-top: 0px;padding-bottom: 0px;margin-bottom: 0px;">
                  <li class="w-subnav-item userWidth">
                    <a a
                       href="https://www.szyanjia.com/jyjy_cgal"
                       target="_self"
                       class="w-subnav-link"
                       style="height:40px;line-height:40px;">
                      <span class="mw-iconfont"></span>
                      <span class="w-link-txt">精益经营</span>
                    </a>
                  </li>
                  <li class="w-subnav-item userWidth">
                    <a href="https://www.szyanjia.com/zngcgh_cgal"
                       target="_self"
                       class="w-subnav-link"
                       style="height:40px;line-height:40px;">
                      <span class="mw-iconfont"></span>
                      <span class="w-link-txt">智能工厂规划</span>
                    </a>
                  </li>
                  <li class="w-subnav-item userWidth">
                    <a href="https://www.szyanjia.com/szhgl_cgal"
                       target="_self"
                       class="w-subnav-link"
                       style="height:40px;line-height:40px;">
                      <span class="mw-iconfont"></span>
                      <span class="w-link-txt">数字化管理</span>
                    </a>
                  </li>
                  <li class="w-subnav-item userWidth">
                    <a href="https://www.szyanjia.com/zhpt_cgal"
                       target="_self"
                       class="w-subnav-link"
                       style="height:40px;line-height:40px;">
                      <span class="mw-iconfont"></span>
                      <span class="w-link-txt">智慧平台</span>
                    </a>
                  </li>
                  <li class="w-subnav-item userWidth">
                    <a href="https://www.szyanjia.com/gxzz_cgal"
                       target="_self"
                       class="w-subnav-link"
                       style="height:40px;line-height:40px;">
                      <span class="mw-iconfont"></span>
                      <span class="w-link-txt">共享制造</span>
                    </a>
                  </li>
                </ul>
              </li>
              <li class="w-nav-inner"
                  style="height:43px;line-height:43px;width:12.5%;"
                  @mouseover="hoverColour($event)"
                  @mouseout="removeHidden($event)">
                <div class="w-nav-item">
                  <i class="w-nav-item-line"></i>
                  <a href="https://www.szyanjia.com/zngcsys_cxzx"
                     target="_self"
                     class="w-nav-item-link">
                    <span class="mw-iconfont"></span>
                    <span class="w-link-txt">创新中心</span>
                  </a>

                </div>
                <ul class="w-subnav"
                    style="width:111px;display: none;height: 120px;padding-top: 0px;margin-top: 0px;padding-bottom: 0px;margin-bottom: 0px;">
                  <li class="w-subnav-item userWidth">
                    <a href="https://www.szyanjia.com/zngcsys_cxzx"
                       target="_self"
                       class="w-subnav-link"
                       style="height:40px;line-height:40px;">
                      <span class="mw-iconfont"></span>
                      <span class="w-link-txt">智能工厂实验室</span>
                    </a>
                  </li>
                  <!-- <li class="w-subnav-item userWidth">
                    <a href="https://www.szyanjia.com/fzzx_cxzx"
                       target="_self"
                       class="w-subnav-link"
                       style="height:40px;line-height:40px;">
                      <span class="mw-iconfont"></span>
                      <span class="w-link-txt">仿真中心</span>
                    </a>
                  </li> -->
                  <li class="w-subnav-item userWidth">
                    <a href="https://www.szyanjia.com/zsfx_cxzx"
                       target="_self"
                       class="w-subnav-link"
                       style="height:40px;line-height:40px;">
                      <span class="mw-iconfont"></span>
                      <span class="w-link-txt">知识分享</span>
                    </a>
                  </li>
                </ul>
              </li>
              <li class="w-nav-inner"
                  style="height:43px;line-height:43px;width:12.5%;"
                  @mouseover="hoverColour($event)"
                  @mouseout="removeHidden($event)">
                <div class="w-nav-item">
                  <i class="w-nav-item-line"></i>
                  <a href="https://www.szyanjia.com/jrwm"
                     target="_self"
                     class="w-nav-item-link">
                    <span class="mw-iconfont"></span>
                    <span class="w-link-txt">人力资源</span>
                  </a>

                </div>
                <ul class="w-subnav"
                    style="width:111px;display: none;height: 120px;padding-top: 0px;margin-top: 0px;padding-bottom: 0px;margin-bottom: 0px;">
                  <li class="w-subnav-item userWidth">
                    <a href="https://www.szyanjia.com/jrwm"
                       target="_self"
                       class="w-subnav-link"
                       style="height:40px;line-height:40px;">
                      <span class="mw-iconfont"></span>
                      <span class="w-link-txt">加入我们</span>
                    </a>
                  </li>
                  <li class="w-subnav-item userWidth">
                    <a href="https://www.szyanjia.com/ypzn"
                       target="_self"
                       class="w-subnav-link"
                       style="height:40px;line-height:40px;">
                      <span class="mw-iconfont"></span>
                      <span class="w-link-txt">应聘指南</span>
                    </a>
                  </li>
                </ul>
              </li>
              <li class="w-nav-inner"
                  style="height:43px;line-height:43px;width:12.5%;"
                  @mouseover="hoverColour($event)"
                  @mouseout="removeHidden($event)">
                <div class="w-nav-item">
                  <i class="w-nav-item-line"></i>
                  <a href="https://www.szyanjia.com/khfk"
                     target="_self"
                     class="w-nav-item-link">
                    <span class="mw-iconfont"></span>
                    <span class="w-link-txt">联系我们</span>
                  </a>

                </div>
                <ul class="w-subnav"
                    style="width:111px;display: none;height: 120px;padding-top: 0px;margin-top: 0px;padding-bottom: 0px;margin-bottom: 0px;">
                  <li class="w-subnav-item userWidth">
                    <a href="https://www.szyanjia.com/khfk"
                       target="_self"
                       class="w-subnav-link"
                       style="height:40px;line-height:40px;">
                      <span class="mw-iconfont"></span>
                      <span class="w-link-txt">客户反馈</span>
                    </a>
                  </li>
                  <li class="w-subnav-item userWidth">
                    <a href="https://www.szyanjia.com/sysq"
                       target="_self"
                       class="w-subnav-link"
                       style="height:40px;line-height:40px;">
                      <span class="mw-iconfont"></span>
                      <span class="w-link-txt">使用申请</span>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </el-header>
      <el-main>
        <router-view></router-view>
        <div id="smv_tem_121_14"
             ctype="qqservice"
             class="esmartMargin smartAbs smartFixed   "
             cpid="30459"
             cstyle="Style1"
             ccolor="Item0"
             areaId="Area3"
             isContainer="False"
             pvid=""
             tareaId="Area3"
             re-direction="x"
             daxis="All"
             isdeletable="True"
             style="height: 305px; width: 61px; right: 0px; top: 0px;bottom:0px;margin:auto;z-index:37;">
          <div class="yibuFrameContent tem_121_14  qqservice_Style1">
            <div class="tem_121_14_c w-cs"
                 id="qqservice_tem_121_14">
              <ul class="w-cs-btn">
                <li class="w-cs-list w-cs-phoneBtn"
                    @mouseover="over($event)"
                    @mouseout="out($event)">
                  <a href="javascript:void(0);"
                     class="w-cs-icon"><i class="iconfont icon-iconphone">&#xb045;</i></a>

                  <ul class="w-cs-menu w-cs-phone">
                    <li>客服电话</li>
                    <li>0755-29471589</li>
                    <li>18507551589</li>
                  </ul>
                </li>
                <li class="w-cs-list w-cs-qqBtn"
                    @mouseover="over($event)"
                    @mouseout="out($event)">
                  <a href="javascript:void(0);"
                     class="w-cs-icon"><i class="iconfont icon-QQ">&#xb029;</i></a>
                  <ul class="w-cs-menu w-cs-qq">
                    <li><a href="//wpa.qq.com/msgrd?v=3&amp;uin=6555706&amp;site=qq&amp;menu=yes"
                         target="_blank">QQ客服</a></li>
                  </ul>
                </li>
                <li class="w-cs-list w-cs-clockBtn"
                    @mouseover="over($event)"
                    @mouseout="out($event)">
                  <a href="javascript:void(0);"
                     class="w-cs-icon"><i class="iconfont icon-icon-clock-">&#xb025;</i></a>
                  <ul class="w-cs-menu w-cs-clock">
                    <li>服务时间</li>
                    <li>周一至周五 9:00-18:00</li>
                  </ul>
                </li>
                <li class="w-cs-list w-cs-qrcodeBtn"
                    @mouseover="over($event)"
                    @mouseout="out($event)">
                  <a href="javascript:void(0);"
                     class="w-cs-icon"><i class="iconfont icon-qrcode">&#xb083;</i></a>
                  <ul class="w-cs-menu w-cs-qrcode">
                    <li style="text-align: center;">微信二维码</li>
                    <li class="w-cs-qrcode-img"><img src="//nwzimg.wezhan.cn/contents/sitefiles2044/10223864/images/20164089.png"
                           alt=""></li>
                  </ul>
                </li>
                <li class="w-cs-list w-cs-upBtn ">
                  <a href="javascript:void(0);"
                     @click="pageScroll"
                     class="w-cs-icon"><i class="iconfont icon-icon_up">&#xb155;</i></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </el-main>
    </el-container>
  </div>
</template>
<script>
// import $ from 'jquery'
export default {
  name: 'App',
  components: {
  },
  data () {
    return {

    }
  },
  methods: {
    pageScroll () {
      //把内容滚动指定的像素数（第一个参数是向右滚动的像素数，第二个参数是向下滚动的像素数）
      window.scrollBy(0, -100)
      //延时递归调用，模拟滚动向上效果
      let scrolldelay = setTimeout(this.pageScroll(), 100)
      //获取scrollTop值，声明了DTD的标准网页取document.documentElement.scrollTop，否则取document.body.scrollTop；因为二者只有一个会生效，另一个就恒为0，所以取和值可以得到网页的真正的scrollTop值
      var sTop = document.documentElement.scrollTop + document.body.scrollTop
      //判断当页面到达顶部，取消延时代码（否则页面滚动到顶部会无法再向下正常浏览页面）
      if (sTop == 0) clearTimeout(scrolldelay)
    },
    hoverColour ($event) {
      $event.currentTarget.firstElementChild.className = "w-nav-item current"
      $event.currentTarget.firstElementChild.lastElementChild.className = "w-nav-item-link"
      if ($event.currentTarget.lastElementChild) {
        $event.currentTarget.lastElementChild.style = "display:block;"
      }
    },
    removeHidden ($event) {
      $event.currentTarget.firstElementChild.className = "w-nav-item"
      if ($event.currentTarget.lastElementChild) {
        $event.currentTarget.lastElementChild.style = "display:none;"
      }
    },
    hoverHidden ($event) {
      $event.currentTarget.firstElementChild.className = "w-nav-item"
    },
    over ($event) {
      $event.currentTarget.lastElementChild.style.visibility = 'visible'
      $event.currentTarget.lastElementChild.style.right = '60px'
    },
    out ($event) {
      $event.currentTarget.lastElementChild.style.visibility = 'hidden'
      $event.currentTarget.lastElementChild.style.right = '0'
    }
  }
}
</script>

<style scoped>
element.style {
  height: 42px;
  line-height: 42px;
  width: 12.5%;
}
.fullcolumn-outer {
  background-color: #f5f5f5;
  background-image: none;
  background-position: 50% 50%;
  background-repeat: repeat;
  background: -moz-linear-gradient(top, none, none);
  background: -o-linear-gradient(top, none, none);
  background: linear-gradient(top, none, none);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(none),
    to(none)
  );
  background-size: auto;
  z-index: -1;
  display: flex;
  justify-content: space-around;
  height: 40px;
  line-height: 40px;
}
.w-nav .w-subnav-item .w-subnav-link {
  margin: 0;
  padding: 0 15px;
  height: 100%;
  line-height: 40px;
  outline: none;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 40px;
  color: #383838;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
  text-align: center;
}
.w-nav .w-subnav-item {
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: rgb(255, 255, 255);
  background-image: none;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background: -webkit-linear-gradient(none, none);
  background: -moz-linear-gradient(none, none);
  background: -ms-linear-gradient(none, none);
  background: linear-gradient(none, none);
  background-size: auto;
}
.w-nav .w-nav-item.current .w-nav-item-link {
  color: #ffffff;
  font-weight: 400;
  font-style: normal;
  text-decoration: none;
}
.w-nav .w-nav-item.current {
  background-color: rgb(218, 37, 29);
  background-image: none;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background: -webkit-linear-gradient(none, none);
  background: -moz-linear-gradient(none, none);
  background: -ms-linear-gradient(none, none);
  background: linear-gradient(none, none);
  background-size: auto;
}
.w-nav .w-nav-inner:first-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.w-nav .w-nav-inner {
  padding: 0;
  margin: 0;
  float: left;
  list-style: none;
  width: 23%;
  height: 43px;
  line-height: 43px;
  background-color: #fff;
  background-image: none;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background: -webkit-linear-gradient(none, none);
  background: -moz-linear-gradient(none, none);
  background: -ms-linear-gradient(none, none);
  background: linear-gradient(none, none);
  background-size: auto;
}
.w-nav .w-subnav {
  margin: 0;
  padding: 0;
  position: relative;
  display: none;
  overflow: hidden;
}
.w-nav .w-nav-item {
  padding: 0;
  margin: 0;
  position: relative;
  height: 100%;
  line-height: inherit;
}
.w-nav .w-nav-inner:first-child .w-nav-item .w-nav-item-line {
  display: none;
}
.w-nav .w-nav-item .w-nav-item-link {
  margin: 0;
  padding: 0;
  text-decoration: none;
  outline: none;
  display: block;
  height: 100%;
  color: #444444;
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.w-nav .w-nav-item .w-nav-item-link .w-link-txt {
  font-family: Tahoma;
  font-size: 16px;
}
#div-nav {
  background-repeat: repeat-x;
}

#div-nav li {
  float: left;
  width: 150px;
  height: 80px;
}
#div-nav a {
  width: 100px;
  height: 78px;
  line-height: 80px;
  font-size: 20px;
  text-align: center;
  border-left: solid 1px white;
  text-decoration: none;
  display: block;
}

#div-nav a:hover {
  /* color: white; */
  font-weight: bold;
  border-bottom: 2px solid #0493f3;
  /* color: #996633;  */
}

/* 右侧固定栏 */
.smartFixed {
  position: fixed !important;
}
.yibuFrameContent {
  height: 100%;
}
.tem_121_14_c.w-cs {
  position: absolute;
  right: 0;
  z-index: 9999;
}
.tem_121_14_c.w-cs ul.w-cs-btn {
  margin: 0;
  padding: 0;
  list-style: none;
}
.tem_121_14_c.w-cs ul.w-cs-btn li.w-cs-list {
  position: relative;
}
.tem_121_14_c.w-cs ul.w-cs-btn .w-cs-list {
  box-shadow: 0px 0px 0px rgb(0 0 0 / 0%);
}
ul,
li,
ol {
  margin: 0;
  padding: 0;
  list-style: none outside none;
}
.tem_121_14_c.w-cs ul.w-cs-btn li a.w-cs-icon {
  display: block;
  position: relative;
  z-index: 99;
  width: 60px;
  height: 60px;
  background-color: rgb(218, 37, 29);
  background-image: none;
  background-repeat: repeat;
  background-position: 0 0;
  border-top-color: #fff;
  border-top-width: 0;
  border-top-style: solid;
  border-right-color: #fff;
  border-right-width: 0;
  border-right-style: solid;
  border-bottom-color: #fff;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-left-color: #fff;
  border-left-width: 1px;
  border-left-style: solid;
  border-radius: 0px;
  text-align: center;
  color: rgba(255, 255, 255, 1);
  text-decoration: none;
}
.tem_121_14_c.w-cs ul.w-cs-btn li a.w-cs-icon i.icon-phone,
.tem_121_14_c.w-cs ul.w-cs-btn li a.w-cs-icon i.icon-up,
.tem_121_14_c.w-cs ul.w-cs-btn li a.w-cs-icon i.icon-clock {
  font-size: 30px;
}
.tem_121_14_c.w-cs ul.w-cs-btn li a.w-cs-icon i {
  line-height: 60px;
  color: rgba(255, 255, 255, 1);
  font-size: 34px;
}
.mw-iconfont {
  font-family: "mw-iconfont" !important;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
}
.tem_121_14_c.w-cs .w-cs-menu {
  visibility: hidden;
  margin: 0;
  position: absolute;
  top: 0;
  right: 61px;
  padding: 10px 0;
  background-color: rgb(232, 92, 86);
  background-image: none;
  background-repeat: repeat;
  background-position: 0 0;
  border-top-color: #fff;
  border-top-width: 0;
  border-top-style: solid;
  border-right-color: #fff;
  border-right-width: 0;
  border-right-style: solid;
  border-bottom-color: #fff;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-left-color: #fff;
  border-left-width: 0;
  border-left-style: solid;
  border-radius: 0px;
  list-style: none;
  white-space: nowrap;
  z-index: 1;
}
.tem_121_14_c.w-cs .w-cs-menu li {
  line-height: 2.2em;
  padding: 0 20px;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  text-decoration: none;
  text-align: left;
}
.tem_121_14_c.w-cs .w-cs-menu li h3 {
  margin: 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
}
.tem_121_14_c.w-cs ul.w-cs-btn li.w-cs-list {
  position: relative;
}
.tem_121_14_c.w-cs .w-cs-menu.w-cs-qq li {
  padding: 0 10px !important;
}
.tem_121_14_c.w-cs .w-cs-menu li a {
  display: block;
  padding: 0 10px;
  color: #fff;
  text-decoration: none;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
}
.tem_121_14_c.w-cs .w-cs-menu.w-cs-qrcode {
  top: auto;
  bottom: 0;
}
.tem_121_14_c.w-cs .w-cs-qrcode .w-cs-qrcode-img {
  margin-top: 5px;
  margin-bottom: 7px;
}
.tem_121_14_c.w-cs .w-cs-qrcode li img {
  margin: 0 auto;
  display: block;
  width: 160px;
  height: auto;
}
img {
  border: 0;
  vertical-align: middle;
  -ms-interpolation-mode: bicubic;
}
</style>
