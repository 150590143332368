import Vue from 'vue'
import { USER_INFO, LOCALE, ENABLE_SCROLL_BAR } from '@/store/mutation-types'
// import { ENABLE_I18N } from './mutation-types'
const getters = {
  device: state => state.app.device,
  theme: state => state.app.theme,
  color: state => state.app.color,
  token: state => state.user.token,
  avatar: state => { state.user.avatar = Vue.ls.get(USER_INFO).avatar; return state.user.avatar },
  username: state => state.user.username,
  nickname: state => { state.user.realname = Vue.ls.get(USER_INFO).realname; return state.user.realname },
  welcome: state => state.user.welcome,
  permissionList: state => state.user.permissionList,
  userInfo: state => { state.user.info = Vue.ls.get(USER_INFO); return state.user.info },
  addRouters: state => state.permission.addRouters,
  userId: state => { state.user.userId = Vue.ls.get(USER_INFO).username; return state.user.userId },
  listRefresh: state => state.app.listRefresh,
  locale: state => { state.app.locale = Vue.ls.get(LOCALE); return state.app.locale },
  enableScrollBar: state => { state.app.enableScrollBar = Vue.ls.get(ENABLE_SCROLL_BAR); return state.app.enableScrollBar },
  prjCode: state => { state.app.prjCode = Vue.ls.get('PRJ_CODE'); return state.app.prjCode }
}

export default getters
