<template>
  <!-- 离散型成熟度要求调查表 -->
  <div id="questionnaire">
    <div class="subject">
      <h2 style="text-align:center; margin-bottom:20px">{{this.recordType == 1 ? '离散型成熟度要求调查表' : '流程型成熟度要求调查表'}}</h2>
      <el-row style="margin-bottom: 30px">
        <div class="block">
          <el-row style="margin-bottom:6px;text-align:center">当前第{{newPage || 0}}题/共{{tableData.length || 0}}题</el-row>
          <el-progress :text-inside="true"
                       :stroke-width="14"
                       :percentage="Number((this.newPage/this.tableData.length*100).toFixed(0))"></el-progress>
        </div>
        <el-table v-loading="loading"
                  :data="currentData"
                  :cell-style="cellStyle"
                  highlight-current-row
                  :header-cell-style="{ color: '#a1a3a6', borderColor: '#a1a3a6' }"
                  :span-method="objectSpanMethod"
                  class="tableData"
                  style="width: 100%; margin-bottom:10px">
          <el-table-column align="center"
                           type="index">
          </el-table-column>
          <el-table-column label="能力要素"
                           width="78"
                           align="center">
            <template slot-scope="scope">
              <span style="margin-left: 10px">{{ scope.row.optionContent }}</span>
            </template>
          </el-table-column>
          <el-table-column label="能力域"
                           width="88"
                           align="center">
            <template slot-scope="scope">
              <span style="margin-left: 10px">{{ scope.row.powerDpomain }}</span>
            </template>
          </el-table-column>
          <el-table-column label="能力子域"
                           width="88"
                           align="center">
            <template slot-scope="scope">
              <span style="margin-left: 10px">{{ scope.row.abilitySubdomains }}</span>
            </template>
          </el-table-column>
          <el-table-column label="成熟度级别"
                           width="64"
                           align="center">
            <template slot-scope="scope">
              <span style="margin-left: 10px">{{ scope.row.level }}</span>
            </template>
          </el-table-column>
          <el-table-column label="成熟度要求"
                           header-align="center"
                           align="left"
                           :show-overflow-tooltip='false'>
            <template slot-scope="scope">
              <span style="margin-left: 10px">{{ scope.row.askFor }}</span>
            </template>
          </el-table-column>
          <el-table-column label="选项（单选）"
                           header-align="center">
            <el-table-column label="A(0分)不满足"
                             width="78px"
                             max-width="78px"
                             min-width="78"
                             align="center">
              <template slot-scope="scope">
                <div @click="selectRadio(scope.$index,scope.row)">
                  <el-radio v-model="scope.row.radio"
                            label="A"></el-radio>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="B(0.5分)部分满足"
                             width="78px"
                             max-width="78px"
                             min-width="78"
                             align="center">
              <template slot-scope="scope">
                <div @click="selectRadio1(scope.$index,scope.row)">
                  <el-radio v-model="scope.row.radio"
                            label="B"></el-radio>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="C(0.8分)大部分满足"
                             width="78px"
                             max-width="78px"
                             min-width="78"
                             align="center">
              <template slot-scope="scope">
                <div @click="selectRadio2(scope.$index,scope.row)">
                  <el-radio v-model="scope.row.radio"
                            label="C"></el-radio>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="D(1分)全部满足"
                             width="78px"
                             max-width="78px"
                             min-width="78"
                             align="center">
              <template slot-scope="scope">
                <div @click="selectRadio3(scope.$index,scope.row)">
                  <el-radio v-model="scope.row.radio"
                            label="D"></el-radio>
                </div>
              </template>
            </el-table-column>
          </el-table-column>
        </el-table>
        <el-row style="margin-bottom:30px;float:right;">
          <el-button type="primary"
                     v-if="currentPage != 1"
                     @click="handlePreviousPage">上一页</el-button>
          <el-button type="primary"
                     v-if="currentPage !== allType.length"
                     @click="handleNextPage">下一页</el-button>
          <el-button type="primary"
                     v-if="currentPage === allType.length || this.newPage === this.tableData.length"
                     @click="handleOk">提交</el-button>
        </el-row>
      </el-row>
      <el-backtop></el-backtop>
    </div>
  </div>
</template>

<script>
import { postAction } from '@/api/manage'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import Vue from 'vue'
export default {
  name: 'questionnaire',
  components: {
  },
  data () {
    return {
      loading: true,
      curPage: 1,
      tableData: [],
      tableData1: [],
      spanArr: [],//用于存放每一行记录的合并数
      spanCodeArr: [],
      spanabArr: [],
      pos: '',
      codePos: '',
      abPos: '',
      surveyDataSource: [],
      recordType: null,
      currentPage: 0,
      allType: [],
      currentData: [],
      newPage: 0,
      masterId: '',
      url: {
        computeScore: '/recordMaster/pre_compute_score', // 提前计算得分项
        recordTable: '/recordMaster/commit_record_table' // 提交
      }
    }
  },
  created () {
    this.loading = true
    let currentData = JSON.parse(sessionStorage.getItem('currentData'))
    this.surveyDataSource = JSON.parse(sessionStorage.getItem('surveyDataSource'))
    this.newPage = JSON.parse(sessionStorage.getItem('newPage'))
    this.recordType = this.$route.query.recordType
    if (!currentData) {
      let tableData1 = []
      this.surveyDataSource.records.map(item => {
        item.optional = ''
        item.children.map(items => {
          items.children.map(itemss => {
            itemss.children.map(itemses => {
              itemses.children.map(itemes => {
                let resData = null
                resData = {
                  optional: '',
                  isHidden: itemss.isHidden,
                  templateId: itemes.templateId,
                  optionContent: item.optionContent,
                  powerDpomain: items.optionContent,
                  abilitySubdomains: itemss.optionContent,
                  level: itemses.optionContent,
                  askFor: itemes.optionContent,
                }
                tableData1.push(resData)
              })
            })
          })
        })
      })
      this.allType = []
      this.tableData = tableData1.filter(item => {
        if (this.allType.indexOf(item.abilitySubdomains) === -1) {
          this.allType.push(item.abilitySubdomains)
        }
        return item.isHidden
      })
    } else {
      this.allType = []
      this.tableData = currentData.filter(item => {
        if (this.allType.indexOf(item.abilitySubdomains) === -1) {
          this.allType.push(item.abilitySubdomains)
        }
        return item.isHidden
      })
    }
    this.currentPage = 1
  },
  watch: {
    currentPage (newVal) {
      this.currentData = []
      setTimeout(() => {
        this.currentData = this.tableData.filter(item => {
          return item.abilitySubdomains === this.allType[newVal - 1]
        })
      }, 1000)
    }
  },
  mounted () {
    let that = this
    setTimeout(() => {
      that.getSpanArr(that.tableData)
      this.loading = false
    }, 500)
  },
  beforeDestroy () {
    sessionStorage.setItem("surveyDataSource", JSON.stringify(this.surveyDataSource))
    sessionStorage.setItem("currentData", JSON.stringify(this.tableData))
    sessionStorage.setItem("newPage", JSON.stringify(this.newPage))
  },
  methods: {
    cellStyle () {
      return "height:35px!important; border-color:#a1a3a6!important; color:#000!important; padding:0px!important; height:40px!important";
    },
    // 上一页
    handlePreviousPage () {
      this.currentPage--
    },
    // 下一页
    handleNextPage () {
      let errerArr = []
      this.currentData.map((item, index) => {
        if (item.optional === "") {
          errerArr.push('第' + (index + 1) + '行为空,请填写完再进入下一页')
        }
      })
      if (errerArr.length > 0) {
        this.$message.error(errerArr[0])
        return
      }
      this.currentPage += 1
      this.getSpanArr(this.allType)
    },
    getSpanArr (data) {
      this.spanArr = []
      this.spanCodeArr = []
      this.spanabArr = []
      for (var i = 0; i < data.length; i++) {
        if (i === 0) {
          this.spanArr.push(1)
          this.spanCodeArr.push(1)
          this.spanabArr.push(1)
          this.pos = 0
          this.codePos = 0
          this.abPos = 0
        } else {
          if (data[i].optionContent === data[i - 1].optionContent) {
            this.spanArr[this.pos] += 1
            this.spanArr.push(0)
          } else {
            this.spanArr.push(1)
            this.pos = i
          }
          if (data[i].powerDpomain === data[i - 1].powerDpomain) {
            this.spanCodeArr[this.codePos] += 1
            this.spanCodeArr.push(0)
          } else {
            this.spanCodeArr.push(1)
            this.codePos = i
          }

          if (data[i].abilitySubdomains === data[i - 1].abilitySubdomains) {
            this.spanabArr[this.abPos] += 1
            this.spanabArr.push(0)
          } else {
            this.spanabArr.push(1)
            this.abPos = i
          }
        }
      }
    },
    objectSpanMethod ({ rowIndex, columnIndex }) {
      if (columnIndex == 1) {
        const _row = this.spanArr[rowIndex]
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: 200,
          colspan: _col
        }
      }
      if (columnIndex == 2) {
        const _row = this.spanCodeArr[rowIndex]
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: 200,
          colspan: _col
        }
      }
      if (columnIndex == 3) {
        const _row = this.spanabArr[rowIndex]
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: 200,
          colspan: _col
        }
      }
    },
    selectRadio (val, row) {
      if (!row.optional) {
        this.newPage++
      }
      row.optional = 'A'
    },
    selectRadio1 (val, row) {
      if (!row.optional) {
        this.newPage++
      }
      row.optional = 'B'
    },
    selectRadio2 (val, row) {
      if (!row.optional) {
        this.newPage++
      }
      row.optional = 'C'
    },
    selectRadio3 (val, row) {
      if (!row.optional) {
        this.newPage++
      }
      row.optional = 'D'
    },
    handleOk () {
      let recordMaster = {}
      let errerArr = []
      this.surveyDataSource.records.map(item => {
        this.masterId = item.masterId
        item.children.map(items => {
          items.children.map(itemss => {
            itemss.children.map(itemses => {
              itemses.children.map(itemseses => {
                this.tableData.map((itemsss, index) => {
                  if (itemseses.templateId == itemsss.templateId) {
                    itemseses.optional = itemsss.optional
                    if (itemsss.optional == '') {
                      errerArr.push('第' + (index + 1) + '行为空')
                    }
                  }
                })
              })
            })
          })
        })
      })
      if (errerArr.length > 0) {
        this.$message.error(errerArr[0])
        return
      }
      this.loading = true
      let recordes = this.surveyDataSource.records
      postAction(this.url.computeScore, recordes).then(res => {
        if (res.success) {
          let records = res.result
          recordMaster = {
            recordType: this.recordType,
            records: records,
            id: this.masterId
          }
          postAction(this.url.recordTable, recordMaster).then(res => {
            if (res.success) {
              let username = res.result.username
              this.$message.success("提交成功！")
              this.loading = false
              this.$router.push({ path: '/appraisalReport', query: { masterId: this.masterId, recordType: this.recordType, username: username } })
              // this.$router.push({ path: '/singlePlayerSheet', query: { masterId: this.masterId, recordType: this.recordType, username: username } })
            } else {
              this.loading = false
              this.$message.error(res.message)
              if (res.message == '无法获得企业信息，请登记企业信息后再重试') {
                this.$router.push({ path: '/' })
                Vue.ls.remove(ACCESS_TOKEN)
                window.location.reload()
              }
            }
          })
        } else {
          this.loading = false
          this.$message.error(res.message)
          if (res.message == '无法获得企业信息，请登记企业信息后再重试') {
            this.$router.push({ path: '/' })
            Vue.ls.remove(ACCESS_TOKEN)
            window.location.reload()
          }
        }
      })
    }
  }
}
</script>

<style>
.el-main {
  text-align: left !important;
  padding: 0 !important;
}
h2 {
  font-size: 24px;
}
.el-table td {
  padding: 0 !important;
}
.el-button {
  margin: 3px 5px !important;
}
.block {
  width: 320px;
  margin: 14px 0;
  float: right;
}
.el-progress-bar,
.el-progress-bar__inner::after,
.el-progress-bar__innerText,
.el-spinner {
  display: block !important;
}
.el-table {
  color: #000 !important;
}
.el-pagination button,
.el-pagination span:not([class*="suffix"]) {
  font-size: 16px !important;
  min-width: 40px !important;
  height: 30px !important;
  line-height: 30px !important;
}
.el-pager li {
  font-size: 18px !important;
  min-width: 40px !important;
  height: 30px !important;
  line-height: 30px !important;
}
.el-pagination .btn-next .el-icon,
.el-pagination .btn-prev .el-icon {
  font-size: 22px !important;
}
.el-table td,
.el-table th.is-leaf,
.el-table--border,
.el-table--group {
  border-color: #a1a3a6 !important;
}
.el-table--border::after,
.el-table--group::after,
.el-table::before {
  background-color: #a1a3a6 !important;
}
.el-table th > .cell {
  color: #74787c !important;
}
.el-table th.gutter {
  display: table-cell !important;
}
.el-table colgroup.gutter {
  display: table-cell !important;
}
.tableData .el-radio {
  width: 100%;
  height: 34px;
  line-height: 34px !important;
}
.tableData .el-radio__label {
  display: none;
}
.tableData .el-radio__inner {
  width: 20px !important;
  height: 20px !important;
  border: 1px solid #4d4b4b !important;
}
</style>
