<template>
  <!-- 整体成熟度得分分析图表 -->
  <div id="Echart">
    <div class="subject">
      <h2 style="text-align:center; margin-bottom:20px">整体成熟度得分分析图表</h2>
      <el-row>
        <el-col :span="12"
                style="padding-right:20px;">
          <div class="backdrop">
            <el-row>
              <el-col :span="12">
                <dimensionScores id="dimensionScores"
                                 :text="text"
                                 :indicator="indicator"
                                 :value="dimensionScoresValue" />
              </el-col>
              <el-col :span="12"
                      style="margin-top:50px">
                <el-row>
                  <el-col :span="8"
                          v-for="(item,index) in legendList"
                          :key="index"
                          style="height: 30px;line-height: 30px;text-align: center;">
                    {{item}}
                  </el-col>
                </el-row>
                <el-row v-for="(item,index) in valueData"
                        :key="index"
                        style="height: 30px;line-height: 30px;text-align: center;">
                  <el-col :span="8">
                    {{item.name}}
                  </el-col>
                  <el-col :span="8">{{item.value}}</el-col>
                  <el-col :span="8">{{item.factor}}</el-col>
                </el-row>
              </el-col>
            </el-row>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="backdrop">
            <el-row>
              <el-col :span="12">
                <dimensionScores id="dimensionScores1"
                                 :text="text1"
                                 :indicator="indicator1"
                                 :value="dimensionScoresValue1" />
              </el-col>
              <el-col :span="12"
                      style="margin-top:50px">
                <el-row>
                  <el-col :span="8"
                          v-for="(item,index) in legendList"
                          :key="index"
                          style="height: 30px;line-height: 30px;text-align: center;">
                    {{item}}
                  </el-col>
                </el-row>
                <el-row v-for="(item,index) in valueData1"
                        :key="index"
                        style="height: 30px;line-height: 30px;text-align: center;">
                  <el-col :span="8">
                    {{item.name}}
                  </el-col>
                  <el-col :span="8">{{item.value}}</el-col>
                  <el-col :span="8">{{item.dpomain}}</el-col>
                </el-row>
              </el-col>
            </el-row>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12"
                style="padding: 20px 20px 20px 0">
          <div class="backdrop">
            <el-row>
              <el-col :span="6"
                      style="float: right;">
                <el-select v-model="name"
                           @change="changeData"
                           placeholder="请选择">
                  <el-option v-for="(item, index) in nameAll"
                             :key="index"
                             :label="item"
                             :value="item">
                  </el-option>
                </el-select>
              </el-col>
              <el-col :span="12">
                <dimensionScores id="dimensionScores2"
                                 :text="text2"
                                 :indicator="indicator2"
                                 :value="dimensionScoresValue2" />
              </el-col>
              <el-col :span="12"
                      style="margin-top:50px">
                <el-row>
                  <el-col :span="8"
                          v-for="(item,index) in legendList"
                          :key="index"
                          style="height: 30px;line-height: 30px;text-align: center;">
                    {{item}}
                  </el-col>
                </el-row>
                <el-row v-for="(item,index) in valueData2"
                        :key="index"
                        style="height: 30px;line-height: 30px;text-align: center;">
                  <el-col :span="8">
                    {{item.name}}
                  </el-col>
                  <el-col :span="8">{{item.value}}</el-col>
                  <el-col :span="8">{{item.realWeight}}</el-col>
                </el-row>
              </el-col>
            </el-row>
          </div>
        </el-col>
        <el-col :span="12"
                style="padding: 440px 0 20px 0">
          <div style="overflow: hidden;">
            <router-link :to="{ path: '/appraisalReport', query: {num: this.num} }">
              <el-button type="primary"
                         style="float:right;">下一页</el-button>
            </router-link>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import dimensionScores from '@views/public/components/dimensionScores'
import { getAction } from '@/api/manage'
export default {
  name: 'Echart',
  components: {
    dimensionScores
  },
  data () {
    return {
      text: '要素维度得分',
      text1: '能力域维度得分',
      text2: '制造能力子域维度得分',
      legendList: ['项目', '得分', '权重'],
      nameAll: [],
      name: '制造',
      indicator: [],
      indicator1: [],
      indicator2: [],
      dimensionScoresValue: [],
      dimensionScoresValue1: [],
      dimensionScoresValue2: [],
      masterId: '',
      username: '',
      num: '',
      factor: [],
      dpomain: [],
      realWeight: [],
      valueData: [],
      valueData1: [],
      valueData2: [],
      url: {
        maturityModel: '/recordMaster/maturity_model'
      }
    }
  },
  created () {
    this.masterId = this.$route.query.masterId
    this.username = this.$route.query.username
    this.num = this.$route.query.num
    this.factor = this.$route.query.factor
    this.dpomain = this.$route.query.dpomain
    this.realWeight = this.$route.query.realWeight
    getAction(this.url.maturityModel, { masterId: this.masterId, username: this.username }).then(res => {
      if (res.success) {
        this.indicator = res.result[0].indicator
        let value1 = []
        res.result[0].data[0].value.map(item => {
          value1.push(Number(item.toFixed(1)))
        })
        res.result[0].data[0].value = value1
        this.dimensionScoresValue = res.result[0].data
        let valueData = []
        let resData = null
        this.indicator.map((item, index) => {
          this.dimensionScoresValue[0].value.map((items, indexs) => {
            this.factor.map((itemes, indexes) => {
              if (index === indexs && index === indexes) {
                resData = {
                  name: item.name,
                  value: items.toFixed(1),
                  factor: itemes
                }
                valueData.push(resData)
              }
            })
          })
        })
        this.valueData = valueData
        this.indicator1 = res.result[1].indicator
        let value2 = []
        res.result[1].data[0].value.map(item => {
          value2.push(Number(item.toFixed(1)))
        })
        res.result[1].data[0].value = value2
        this.dimensionScoresValue1 = res.result[1].data
        let valueData1 = []
        let resData1 = null
        this.indicator1.map((item, index) => {
          this.dimensionScoresValue1[0].value.map((items, indexs) => {
            this.dpomain.map((itemes, indexes) => {
              if (index === indexs && index === indexes) {
                resData1 = {
                  name: item.name,
                  value: items.toFixed(1),
                  dpomain: itemes
                }
                valueData1.push(resData1)
              }
            })
          })
        })
        this.valueData1 = valueData1
        // this.indicator2 = res.result[2].data[3].indicator
        // res.result[2].data.map(item => {
        //   this.nameAll.push(item.name)
        // })
        // let data = [{ name: '能力子域', value: [] }]
        // let value = []
        // res.result[2].data[3].data.value.map(item => {
        //   value.push(item.toFixed(1))
        // })
        // data[0].value = value
        // this.dimensionScoresValue2 = data
        // let valueData2 = []
        // let resData2 = null
        // this.indicator2.map((item, index) => {
        //   this.dimensionScoresValue2[0].value.map((items, indexs) => {
        //     this.realWeight.map((itemes, indexes) => {
        //       if (index === indexs && index === indexes) {
        //         resData2 = {
        //           name: item.name,
        //           value: items,
        //           realWeight: itemes
        //         }
        //         valueData2.push(resData2)
        //       }
        //     })
        //   })
        // })
        // this.valueData2 = valueData2
        this.changeData('制造')
      }
    })
  },
  methods: {
    changeData (value) {
      this.nameAll = []
      getAction(this.url.maturityModel, { masterId: this.masterId, username: this.username }).then(res => {
        if (res.success) {
          res.result[2].data.map(item => {
            this.nameAll.push(item.name)
          })
          let data = [{ name: '能力子域', value: [] }]
          let values = []
          if (value == '人员') {
            this.indicator2 = res.result[2].data[0].indicator
            res.result[2].data[0].data.value.map(item => {
              values.push(item.toFixed(1))
            })
          }
          if (value == '技术') {
            this.indicator2 = res.result[2].data[1].indicator
            res.result[2].data[1].data.value.map(item => {
              values.push(item.toFixed(1))
            })
          }
          if (value == '资源') {
            this.indicator2 = res.result[2].data[2].indicator
            res.result[2].data[2].data.value.map(item => {
              values.push(item.toFixed(1))
            })
          }
          if (value == '制造') {
            this.indicator2 = res.result[2].data[3].indicator
            res.result[2].data[3].data.value.map(item => {
              values.push(item.toFixed(1))
            })
          }
          data[0].value = values
          this.dimensionScoresValue2 = data
          let valueData2 = []
          let resData2 = null
          this.indicator2.map((item, index) => {
            this.dimensionScoresValue2[0].value.map((items, indexs) => {
              this.realWeight.map((itemes, indexes) => {
                if (index === indexs && index === indexes) {
                  resData2 = {
                    name: item.name,
                    value: items,
                    realWeight: itemes
                  }
                  valueData2.push(resData2)
                }
              })
            })
          })
          this.valueData2 = valueData2
        }
      })
    }
  }
}
</script>

<style>
.el-main {
  text-align: left !important;
  padding: 0 !important;
}
h2 {
  font-size: 24px;
}
.el-button {
  margin: 3px 5px !important;
}
.backdrop {
  background: #f4f5f7;
}
</style>
