<template>
  <!-- 离散型成熟度得分分析表-单人 -->
  <div id="singlePlayerSheet">
    <div class="subject">
      <!-- <el-breadcrumb separator-class="el-icon-arrow-right"
                     style="margin-top:20px;font-size:16px">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/learnMore' }">详情页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/weightChoose' }">调整权重页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/questionnaire' }">调查表</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/singlePlayerSheet' }">单人分析表</el-breadcrumb-item>
      </el-breadcrumb> -->
      <h2 style="text-align:center; margin-bottom:20px">{{this.recordType == 1 ? '离散型成熟度得分分析表' : '流程型成熟度得分分析表'}}</h2>
      <el-table :data="tableData"
                border
                :span-method="objectSpanMethod"
                style="width: 100%; margin-bottom:10px">
        <el-table-column width="60"
                         align="center"
                         type="index">
        </el-table-column>
        <el-table-column label="能力要素"
                         align="center"
                         width="80">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.optionContent }}</span>
          </template>
        </el-table-column>
        <el-table-column label="能力域"
                         align="center"
                         width="120">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.powerDpomain }}</span>
          </template>
        </el-table-column>
        <el-table-column label="能力子域"
                         align="center"
                         width="120">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.abilitySubdomains }}</span>
          </template>
        </el-table-column>
        <el-table-column label="权重"
                         header-align="center">
          <el-table-column label="能力子域"
                           align="center"
                           width="78">
            <template slot-scope="scope">
              <span style="margin-left: 10px">{{ scope.row.realWeight }}</span>
            </template>
          </el-table-column>
          <el-table-column label="能力域"
                           align="center"
                           width="78">
            <template slot-scope="scope">
              <span style="margin-left: 10px">{{ scope.row.dpomain }}</span>
            </template>
          </el-table-column>
          <el-table-column label="能力要素"
                           align="center"
                           width="78">
            <template slot-scope="scope">
              <span style="margin-left: 10px">{{ scope.row.factor }}</span>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column label="能力子域级别得分"
                         header-align="center">
          <el-table-column label="1级"
                           align="center"
                           width="60">
            <template slot-scope="scope">
              <span style="margin-left: 10px">{{ scope.row.level1 }}</span>
            </template>
          </el-table-column>
          <el-table-column label="2级"
                           align="center"
                           width="60">
            <template slot-scope="scope">
              <span style="margin-left: 10px">{{ scope.row.level2 }}</span>
            </template>
          </el-table-column>
          <el-table-column label="3级"
                           align="center"
                           width="60">
            <template slot-scope="scope">
              <span style="margin-left: 10px">{{ scope.row.level3 }}</span>
            </template>
          </el-table-column>
          <el-table-column label="4级"
                           align="center"
                           width="60">
            <template slot-scope="scope">
              <span style="margin-left: 10px">{{ scope.row.level4 }}</span>
            </template>
          </el-table-column>
          <el-table-column label="5级"
                           align="center"
                           width="60">
            <template slot-scope="scope">
              <span style="margin-left: 10px">{{ scope.row.level5 }}</span>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column label="得分"
                         header-align="center">
          <el-table-column label="能力子域"
                           align="center"
                           width="60">
            <template slot-scope="scope">
              <span style="margin-left: 10px">{{ scope.row.subdomains1 }}</span>
            </template>
          </el-table-column>
          <el-table-column label="能力域"
                           align="center"
                           width="60">
            <template slot-scope="scope">
              <span style="margin-left: 10px">{{ scope.row.dpomain1 }}</span>
            </template>
          </el-table-column>
          <el-table-column label="能力要素"
                           align="center"
                           width="60">
            <template slot-scope="scope">
              <span style="margin-left: 10px">{{ scope.row.reactiveScore }}</span>
            </template>
          </el-table-column>
          <el-table-column label="智能制造"
                           align="center"
                           width="60">
            <template slot-scope="scope">
              <span style="margin-left: 10px">{{ scope.row.manufacturing }}</span>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column label="成熟度级别"
                         align="center"
                         width="122">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.maturityLevel }}</span>
          </template>
        </el-table-column>
      </el-table>
      <div style="overflow: hidden;">
        <router-link :to="{ path: '/MultiplayerAnalysisTable', query: {recordType: this.recordType, masterId: this.masterId, username: this.username, num: this.num} }">
          <el-button type="primary"
                     style="float:right;">查看多人表</el-button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { getAction } from '@/api/manage'
export default {
  name: 'singlePlayerSheet',
  components: {
  },
  data () {
    return {
      masterId: '',
      recordType: null,
      username: '',
      num: '',
      spanArr: [],//用于存放每一行记录的合并数
      spanCodeArr: [],
      spanabArr: [],
      pos: '',
      codePos: '',
      abPos: '',
      manufacturing: [],
      tableData: [],
      url: {
        historyRecordScore: '/recordMaster/history_record_score'
      }
    }
  },
  created () {
    this.masterId = this.$route.query.masterId
    this.recordType = this.$route.query.recordType
    this.username = this.$route.query.username
    getAction(this.url.historyRecordScore, { masterId: this.masterId, username: this.username, type: 1 }).then(res => {
      if (res.success) {
        let tableData = []
        let arrs = null
        res.result.records.map(item => {
          this.manufacturing.push(item.reactiveScore.toFixed(1))
          item.children.map((items, indexs) => {
            items.children.map((itemss, indexss) => {
              let str = Number(itemss.realWeight * 100).toFixed(0)
              str += "%"
              let arr = Number(items.realWeight * 100)
              arr += "%"
              let strs = Number(item.realWeight * 100)
              strs += "%"
              itemss.children.map(itemes => {
                if (itemes.optionContent == '1级') {
                  itemss.level1 = itemes.reactiveScore.toFixed(1)
                }
                if (itemes.optionContent == '2级') {
                  itemss.level2 = itemes.reactiveScore.toFixed(1)
                }
                if (itemes.optionContent == '3级') {
                  itemss.level3 = itemes.reactiveScore.toFixed(1)
                }
                if (itemes.optionContent == '4级') {
                  itemss.level4 = itemes.reactiveScore.toFixed(1)
                }
                if (itemes.optionContent == '5级') {
                  itemss.level5 = itemes.reactiveScore.toFixed(1)
                }
              })
              let resData = null
              if (indexss === 0 && indexs === 0) {
                let sum = 0
                for (let i = 0; i < this.manufacturing.length; i++) {
                  sum += Number(this.manufacturing[i])
                }
                arrs = sum / this.manufacturing.length
                resData = {
                  isHidden: itemss.isHidden,
                  optionContent: item.optionContent,
                  powerDpomain: items.optionContent,
                  abilitySubdomains: itemss.optionContent,
                  realWeight: str,
                  dpomain: arr,
                  factor: strs,
                  reactiveScore: item.reactiveScore.toFixed(1),
                  dpomain1: items.reactiveScore.toFixed(1),
                  subdomains1: itemss.reactiveScore.toFixed(1),
                  level1: itemss.level1,
                  level2: itemss.level2,
                  level3: itemss.level3,
                  level4: itemss.level4,
                  level5: itemss.level5
                }
              } else {
                resData = {
                  isHidden: itemss.isHidden,
                  optionContent: item.optionContent,
                  powerDpomain: items.optionContent,
                  abilitySubdomains: itemss.optionContent,
                  realWeight: str,
                  dpomain: indexss >= 1 || indexs >= 5 ? "" : arr,
                  factor: indexss >= 1 || indexs >= 1 ? "" : strs,
                  dpomain1: indexss >= 1 || indexs >= 5 ? "" : items.reactiveScore.toFixed(1),
                  subdomains1: itemss.reactiveScore.toFixed(1),
                  level1: itemss.level1,
                  level2: itemss.level2,
                  level3: itemss.level3,
                  level4: itemss.level4,
                  level5: itemss.level5
                }
              }
              tableData.push(resData)
            })
          })
        })
        tableData = tableData.filter(item => {
          return item.isHidden
        })
        tableData[0].manufacturing = arrs.toFixed(1)
        let num = Number(tableData[0].manufacturing)
        this.num = num
        tableData[0].maturityLevel = num < 1.8 ? '1级 规划级' : num < 2.8 ? '2级 规范级' : num < 3.8 ? '3级 集成级' : num < 4.8 ? '4级 优化级' : '5级 引领级'
        this.tableData = tableData
      }
    })
  },
  mounted () {
    let that = this
    setTimeout(() => {
      that.getSpanArr(that.tableData)
    }, 500)
  },
  methods: {
    getSpanArr (data) {
      this.spanArr = []
      this.spanCodeArr = []
      this.spanabArr = []
      for (var i = 0; i < data.length; i++) {
        if (i === 0) {
          this.spanArr.push(1)
          this.spanCodeArr.push(1)
          this.spanabArr.push(1)
          this.pos = 0
          this.codePos = 0
          this.abPos = 0
        } else {
          if (data[i].optionContent === data[i - 1].optionContent) {
            this.spanArr[this.pos] += 1
            this.spanArr.push(0)
          } else {
            this.spanArr.push(1)
            this.pos = i
          }

          if (data[i].powerDpomain === data[i - 1].powerDpomain) {
            this.spanCodeArr[this.codePos] += 1
            this.spanCodeArr.push(0)
          } else {
            this.spanCodeArr.push(1)
            this.codePos = i
          }

          if (data[i].abilitySubdomains === data[i - 1].abilitySubdomains) {
            this.spanabArr[this.abPos] += 1
            this.spanabArr.push(0)
          } else {
            this.spanabArr.push(1)
            this.abPos = i
          }
        }
      }
    },
    objectSpanMethod ({ rowIndex, columnIndex }) {
      if (columnIndex == 1) {
        const _row = this.spanArr[rowIndex]
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
      if (columnIndex == 2) {
        const _row = this.spanCodeArr[rowIndex]
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
      if (columnIndex == 3) {
        const _row = this.spanabArr[rowIndex]
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
    }
  }
}
</script>

<style>
.el-main {
  text-align: left !important;
  padding: 0 !important;
}
h2 {
  font-size: 24px;
}
.el-table td {
  padding: 0 !important;
}
.el-button {
  margin: 3px 5px !important;
}
</style>
