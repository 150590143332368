import Vue from 'vue'
import Router from 'vue-router'
// import Test from '@/views/public/test'
import LearnMore from '@/views/public/learnMore'
import WeightChoose from '@/views/public/weightChoose'
import WeightAdjustment from '@/views/public/weightAdjustment'
import MaturityAdjustment from '@views/public/maturityAdjustment'
import Questionnaire from '@views/public/questionnaire'
import ScoringStatistics from '@views/public/scoringStatistics'
import SinglePlayerSheet from '@views/public/singlePlayerSheet'
import MultiplayerAnalysisTable from '@views/public/multiplayerAnalysisTable'
import Echart from '@views/public/Echart'
import AppraisalReport from '@views/public/appraisalReport'
import Delay from '@views/public/delay'

Vue.use(Router)
export default new Router({
  mode: 'history',
  routes: [
    // {
    //   path: '/',
    //   component: HomePage
    // },
    {
      path: '/',
      component: LearnMore
    },
    {
      path: '/weightChoose',
      component: WeightChoose
    },
    {
      path: '/weightAdjustment',
      component: WeightAdjustment
    },
    {
      path: '/maturityAdjustment',
      component: MaturityAdjustment
    },
    {
      path: '/questionnaire',
      component: Questionnaire
    },
    {
      path: '/scoringStatistics',
      component: ScoringStatistics
    },
    {
      path: '/singlePlayerSheet',
      component: SinglePlayerSheet
    },
    {
      path: '/multiplayerAnalysisTable',
      component: MultiplayerAnalysisTable
    },
    {
      path: '/Echart',
      component: Echart
    },
    {
      path: '/appraisalReport',
      component: AppraisalReport
    },
    {
      path: '/delay',
      component: Delay
    }
  ],
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    }
  }
})