<template>
  <div id="barChart">
    <div :id="this.$props.id"
         style="width:100%;height:350px;"></div>
  </div>
</template>
<script>
let elementResizeDetectorMaker = require('element-resize-detector')
export default {
  props: ['id', 'text', 'indicator', 'value'],
  data () {
    return {
    }
  },
  watch: {
    value: {
      handler: function () {
        this.getDimensionScores()
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    getDimensionScores () {
      var echarts = require('echarts')
      var option = {
        title: {
          text: this.$props.text,
          top: 8,
          left: 10
        },
        tooltip: {},
        radar: {
          indicator: this.$props.indicator,
          radius: 100,
          center: ['53%', '56%'],
        },
        series: [
          {
            type: 'radar',
            tooltip: {
              trigger: 'item'
            },
            areaStyle: {},
            data: this.$props.value,
            label: {
              normal: {
                  show: true,
                  color: "#FF0000",
                  padding: [0, 0, -6],
                  formatter:function(params) {
                      return params.value;
                  }
              }
            }
          }
        ]
      }
      console.log(this.$props.value)
      this.$nextTick(function () {
        let myChart = echarts.init(document.getElementById(this.$props.id))
        myChart.setOption(option, true)
        // 监听最外层元素宽度变化重绘echart
        let erd = elementResizeDetectorMaker()
        erd.listenTo(document.getElementById(this.$props.id), element => {
          console.log(element)
          this.$nextTick(function () {
            // 使echarts尺寸重置
            myChart.resize()
          })
        })
      })
    }
  }
}
</script>

<style lang="less" scoped></style>
