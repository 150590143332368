<template>
  <!-- 成熟度要求权重调整 -->
  <div id="maturityAdjustment">
    <div class="subject">
      <h2 style="text-align:center; margin-bottom:20px">成熟度要求权重调整</h2>
      <div style="float: right;margin-bottom: 10px;">
        <el-button @click="handleEdit"
                   type="primary"
                   style="margin-right:10px">编辑</el-button>
        <el-button @click="handleOk"
                   type="primary">保存</el-button>
      </div>
      <el-table :data="tableData"
                border
                :span-method="objectSpanMethod"
                style="width: 100%; margin-bottom:10px">
        <el-table-column align="center"
                         type="index">
        </el-table-column>
        <el-table-column label="能力要素"
                         align="center">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.optionContent }}</span>
          </template>
        </el-table-column>
        <el-table-column label="能力域"
                         align="center">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.powerDpomain }}</span>
          </template>
        </el-table-column>
        <el-table-column label="能力子域"
                         align="center">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.abilitySubdomains }}</span>
          </template>
        </el-table-column>
        <el-table-column label="成熟度级别"
                         align="center">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.level }}</span>
          </template>
        </el-table-column>
        <el-table-column label="成熟度要求"
                         width="660px"
                         header-align="center">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.askFor }}</span>
          </template>
        </el-table-column>
        <el-table-column label="要求权重"
                         align="center">
          <template slot-scope="scope">
            <el-input v-model="scope.row.forWeight"
                      v-if="scope.row.seen"
                      @blur="loseFcous(scope.$index, scope.row)"> </el-input>
            <span style="margin-left: 10px"
                  v-else>{{ scope.row.forWeight }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column label="操作"
                         align="center">
          <template slot-scope="scope">
            <el-button @click="handleEdit(scope.$index, scope.row)"
                       style="margin-right:10px">编辑</el-button>
            <el-button @click="handleOk(scope.$index, scope.row)">保存</el-button>
          </template>
        </el-table-column> -->
      </el-table>
      <p style="margin-top:20px; margin-bottom:20px">能力子域级别权重默认100%，表格内数据为不可编辑状态；若点击调整权重按钮，则表格内数据为可编辑状态；点击确认权重，记录数据，表格内数据为不可编辑状态。</p>
      <p style="margin-bottom:20px">此表格数据由延嘉内部人员修改，使用人员跳过该页面。</p>
      <!-- <el-pagination :page-size="10"
                     :pager-count="11"
                     layout="prev, pager, next"
                     :total="100">
      </el-pagination> -->
      <el-backtop></el-backtop>
    </div>
  </div>
</template>

<script>
import { getAction, postAction } from '@/api/manage'
export default {
  name: 'maturityAdjustment',
  components: {
  },
  data () {
    return {
      tableData: [],
      templateType: '',
      spanArr: [],//用于存放每一行记录的合并数
      spanCodeArr: [],
      spanabArr: [],
      pos: '',
      codePos: '',
      abPos: '',
      url: {
        list: '/maturityTemplate/statistical_table',
        edit: '/maturityTemplate/editAll'
      }
    }
  },
  created () {
    this.templateType = this.$route.query.recordType
    getAction(this.url.list, { templateType: this.templateType }).then(res => {
      let tableData = []
      res.result.map(item => {
        item.seen = false
        item.children.map(items => {
          items.children.map(itemss => {
            itemss.children.map(itemses => {
              itemses.children.map(itemes => {
                let str = Number(itemes.categoryDefaultWeight * 100).toFixed(0)
                str += "%"
                let resData = null
                resData = {
                  seen: false,
                  id: itemes.id,
                  optionContent: item.optionContent,
                  powerDpomain: items.optionContent,
                  abilitySubdomains: itemss.optionContent,
                  level: itemses.optionContent,
                  askFor: itemes.optionContent,
                  forWeight: str,
                }
                tableData.push(resData)
              })
            })
          })
        })
      })
      this.dataSource = res.result
      this.tableData = tableData
    })
  },
  mounted () {
    let that = this
    setTimeout(() => {
      that.getSpanArr(that.tableData)
    }, 500)
  },
  methods: {
    getSpanArr (data) {
      this.spanArr = []
      this.spanCodeArr = []
      this.spanabArr = []
      for (var i = 0; i < data.length; i++) {
        if (i === 0) {
          this.spanArr.push(1)
          this.spanCodeArr.push(1)
          this.spanabArr.push(1)
          this.pos = 0
          this.codePos = 0
          this.abPos = 0
        } else {
          if (data[i].optionContent === data[i - 1].optionContent) {
            this.spanArr[this.pos] += 1
            this.spanArr.push(0)
          } else {
            this.spanArr.push(1)
            this.pos = i
          }

          if (data[i].powerDpomain === data[i - 1].powerDpomain) {
            this.spanCodeArr[this.codePos] += 1
            this.spanCodeArr.push(0)
          } else {
            this.spanCodeArr.push(1)
            this.codePos = i
          }

          if (data[i].abilitySubdomains === data[i - 1].abilitySubdomains) {
            this.spanabArr[this.abPos] += 1
            this.spanabArr.push(0)
          } else {
            this.spanabArr.push(1)
            this.abPos = i
          }
        }
      }
    },
    objectSpanMethod ({ rowIndex, columnIndex }) {
      if (columnIndex == 1) {
        const _row = this.spanArr[rowIndex]
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
      if (columnIndex == 2) {
        const _row = this.spanCodeArr[rowIndex]
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
      if (columnIndex == 3) {
        const _row = this.spanabArr[rowIndex]
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
    },
    handleEdit () {
      this.tableData.map(item => {
        item.seen = true
      })
    },
    loseFcous (index, row) {
      console.log(index, row)
      this.dataSource.map(item => {
        item.children.map(items => {
          items.children.map(itemss => {
            itemss.children.map(itemes => {
              itemes.children.map(itemess => {
                if (itemess.id == row.id) {
                  itemess.categoryDefaultWeight = parseInt(row.forWeight) / 100
                }
              })
            })
          })
        })
      })
      row.forWeight = row.forWeight.indexOf('%') !== -1 || !row.forWeight ? row.forWeight : row.forWeight + '%'
      row.seen = false
    },
    handleOk () {
      this.tableData.map(item => {
        item.seen = false
      })
      for (let i = 0; i < this.dataSource.length; i++) {
        for (let j = 0; j < this.dataSource[i].children.length; j++) {
          for (let k = 0; k < this.dataSource[i].children[j].children.length; k++) {
            for (let x = 0; x < this.dataSource[i].children[j].children[k].children.length; x++) {
              let firstTotal = 0
              for (let f = 0; f < this.dataSource[i].children[j].children[k].children[x].children.length; f++) {
                firstTotal += this.dataSource[i].children[j].children[k].children[x].children[f].categoryDefaultWeight
              }
              console.log(firstTotal)
              if (firstTotal.toFixed(1) != 1) {
                this.$message.error("请检查" + this.dataSource[i].children[j].children[k].optionContent + this.dataSource[i].children[j].children[k].children[x].optionContent + "中的要求权重总和是否已超过100%或者不足100%！")
                return
              }
            }
          }
        }
      }
      let maturityTemplateList = this.dataSource
      postAction(this.url.edit, maturityTemplateList).then(res => {
        if (res.success) {
          this.$message.success("提交成功！")
        } else {
          this.$message.error(res.message)
        }
      })
    }
  }
}
</script>

<style>
.el-main {
  text-align: left !important;
  padding: 0 !important;
}
h2 {
  font-size: 24px;
}
.el-table td {
  padding: 0 !important;
}
.el-button {
  margin: 3px 5px !important;
}
</style>
