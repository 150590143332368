import axios from 'axios'
import Vue from 'vue'
import store from '@/store'
import {
  VueAxios
} from './axios'
import {
  Notification
  // MessageBox
} from 'element-ui'
import {
  ACCESS_TOKEN,
  ACCESS_TOKEN1
} from '@/store/mutation-types'

// 创建 axios 实例
const service = axios.create({
  baseURL: 'api', // api base_url
  timeout: 60000 // 请求超时时间
})

let timeout
const err = (error) => {
  if (error.response) {
    // let data = error.response.data
    const token = Vue.ls.get(ACCESS_TOKEN)
    console.log('------异常响应------', token)
    console.log('------异常响应------', error.response.status)
    switch (error.response.status) {
      case 403:
        // simpleDebounceQly(requestFailed,3000)()
        // let timeout;

        // ---- start
        if (timeout) clearTimeout(timeout)
        timeout = setTimeout(() => {
          Notification.error({
            message: '系统提示',
            description: '拒绝访问',
            duration: 4
          })
        }, 1000)
        // ----end

        // notification.error({ message: '系统提示', description: '拒绝访问', duration: 4 })
        // simpleDebounceQly(function(){notification.error({ message: '系统提示', description: '拒绝访问!' })},4000)()
        break
      case 500:
        // notification.error({ message: '系统提示', description:'Token失效，请重新登录!',duration: 4})
        // if (token && data.message == 'Token失效，请重新登录') {
        // update-begin- --- author:scott ------ date:20190225 ---- for:Token失效采用弹框模式，不直接跳转----
        // store.dispatch('Logout').then(() => {
        //     window.location.reload()
        // })
        // MessageBox('登录已过期', {
        //   confirmButtonText: '确定',
        //   callback: () => {
        //     store.dispatch('/').then(() => {
        //       Vue.ls.remove(ACCESS_TOKEN)
        //       window.location.reload()
        //     })
        //   }
        // })
        // MessageBox.error({
        //   title: '登录已过期',
        //   content: '很抱歉，登录已过期，请重新登录',
        //   okText: '重新登录',
        //   mask: false,
        //   onOk: () => {
        //     store.dispatch('Logout').then(() => {
        //       Vue.ls.remove(ACCESS_TOKEN)
        //       window.location.reload()
        //     })
        //   }
        // })
        // update-end- --- author:scott ------ date:20190225 ---- for:Token失效采用弹框模式，不直接跳转----
        // }
        break
      case 404:
        // 未生效的防抖 simpleDebounceQly(function(){notification.error({ message: '系统提示', description: '很抱歉，资源未找到!', duration: 4 })},1000)()
        // notification.error({ message: '系统提示', description: '很抱歉，资源未找到!', duration: 4 })
        // ---- start
        if (timeout) clearTimeout(timeout)
        timeout = setTimeout(() => {
          Notification.error({
            message: '系统提示',
            description: '很抱歉，资源未找到!',
            duration: 4
          })
        }, 1000)
        // ----end

        break
      case 504:
        // 未生效的防抖 simpleDebounceQly(function () { Notification.error({ message: '系统提示', description: '网络超时', duration: 4 }) }, 1000)()
        // Notification.error({ message: '系统提示', description: '网络超时' })
        // ---- start
        if (timeout) clearTimeout(timeout)
        timeout = setTimeout(() => {
          Notification.error({
            message: '系统提示',
            description: '网络超时!',
            duration: 4
          })
        }, 1000)
        // ----end
        break
      case 401:
        console.log(1111)
        // 未生效的防抖 simpleDebounceQly(function () { notification.error({ message: '系统提示', description: '未授权，请重新登录', duration: 4 }) }, 1000)()
        // notification.error({ message: '系统提示', description: '未授权，请重新登录', duration: 4 })
        // ---- start
        if (timeout) clearTimeout(timeout)
        timeout = setTimeout(() => {
          Notification.error({
            message: '系统提示',
            description: '未授权，请重新登录!',
            duration: 4
          })
        }, 1000)
        // ----end
        if (token) {
          store.dispatch('/').then(() => {
            setTimeout(() => {
              window.location.reload()
            }, 1500)
          })
        }
        break
      default:
        console.log('系统提示暂时去除')
        // notification.error({
        //   message: '系统提示',
        //   description: data.message,
        //   duration: 4
        // })
        break
    }
  }

  // 异常处理
  const {
    config,
    code,
    message
  } = error
  if (code === 'ECONNABORTED' || message === 'Network Error') {
    config.headers = {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
    return new Promise(resolve => { // 请求超时6秒后重新发送请求
      // eslint-disable-next-line no-unused-vars
      setTimeout(async _ => {
        config.baseURL = ''
        resolve(await service.request(config))
      }, {
        baseURL: '', // api base_url
        timeout: 3000 // 请求超时时间
      }.retryDelay) // 设置发送间隔
    })
  }

  return Promise.reject(error)
}

// request interceptor
service.interceptors.request.use(config => {
  const token = Vue.ls.get(ACCESS_TOKEN)
  const token1 = Vue.ls.get(ACCESS_TOKEN1)
  if (token1) {
    config.headers['history-temp-token'] = token1 // 让每个请求携带自定义 token 请根据实际情况自行修改
  } else {
    config.headers['X-Access-Token'] = token // 让每个请求携带自定义 token 请根据实际情况自行修改
  }
  return config
}, (error) => {
  return Promise.reject(error)
})

service.interceptors.response.use((response) => {
  return response.data
}, err)

const installer = {
  vm: {},
  install(Vue, router = {}) {
    Vue.use(VueAxios, router, service)
  }
}

export {
  installer as VueAxios,
  service as axios
}