<template>
  <!-- 简介页面 -->
  <div id="learnMore">
    <div class="subject"
         style="overflow: hidden;">
      <h3 style="text-align:center">延嘉智能制造成熟度评价模型简介</h3>
      <p style="padding-bottom: 40px;text-indent:2em">延嘉智能制造能力成熟度评价模型遵照GB 39116-2020《智能制造能力成熟度模型》和GB/T39117-2020《智能制造能力成熟度评估方法》规定。本模型由成熟度等级、能力要素和成熟度要求构成，其中，成熟度等级明确了企业智能制造能力水平，能力要素提出了智能制造能力成熟度等级提升的关键方面，成熟度要求规定了能力要素在不同成熟度等级下应满足的具体条件。详见延嘉智能制造成熟度评价模型图：</p>
      <h3 style="text-align:center">延嘉智能制造成熟度评价模型</h3>
      <el-row style="height:300px">
        <el-image style="width:100%; height:300px !important;"
                  fit="contain"
                  :src="require('@assets/img/model.png')"></el-image>
      </el-row>
      <p style="padding-bottom: 40px;text-indent:2em">能力要素包括制造、人员、技术和资源，反映了人员将资源、技术应用于制造环节，提升智能制造能力的过程。其中：人员包括组织战略、人员技能 2 个能力域；技术包括数据、集成和信息安全 3 个能力域；资源包括装备、网络 2 个能力域；制造包括设计、生产、物流、销售和服务 5 个能力域。设计包括产品设计和工艺设计 2 个能力子域，生产包括采购、计划与调度、生产作业、设备管理、安全环保、仓储配送、能源管理 7 个能力子域，物流包括物流 1 个能力子域，销售包括销售 1 个能力子域，服务包括客户服务和产品服务 2 个能力子域。企业可根据自身业务特点对设计、物流、销售、服务4个能力域进行裁剪。</p>
      <h3 style="text-align:center">延嘉智能制造成熟度评价等级</h3>
      <p style="padding-bottom: 40px;text-indent:2em">延嘉智能制造能力成熟度模型采用了与CMMI类似的等级评价系统，将成熟度等级分成了五级，即：一级（规划级）、二级（规范级）、三级（集成集）、四级（优化级）、五级（引领级）。成熟度的评分方面，模型标准对每层级设置合理权重；每条要求的得分最高是1分，最低是0分；依据单项得分*权重分层级计算得分方式进行总评分，确定成熟度等级；总得分采用是5分制，具体分级如下：</p>
      <el-row style="height:300px">
        <el-image style="width:100%; height:300px !important;"
                  fit="contain"
                  :src="require('@assets/img/grade.png')"></el-image>
      </el-row>
      <h3 style="text-align:center">延嘉智能制造成熟度水平评价平台价值</h3>
      <h6>a)&nbsp;企业获知智能制造现状水平</h6>
      <p style="padding-top: 20px; padding-bottom: 20px; text-indent:2em">企业可在本平台进行成熟度自评，在线获取评价报告，获知企业当前智能制造发展水平、未来可能的改善方向建议。</p>
      <h6>b)&nbsp;获取专业智能制造水平评价及改进方向指导</h6>
      <p style="padding-top: 20px; padding-bottom: 20px;text-indent:2em">延嘉可接受企业委托，再对企业进行专业调研基础上，准确评价企业当前智能制造发展水平，提供详细调研评价报告和定制化的改进方向建议，助力企业“智造”水平提升。</p>
      <h3 style="text-align:center;margin-bottom:10px">延嘉智能制造成熟度水平评价平台操作指引</h3>
      <el-row style="height:300px">
        <el-image style="width:100%; height: 300px !important;"
                  fit="contain"
                  :src="require('@assets/img/flow.png')"></el-image>
      </el-row>
      <el-row style="margin-top:20px">
        <el-col>
          <p style="line-height:30px;text-indent:2em">全过程预计耗时：<i style="color: #ff0000; font-size: 20px">25</i>&nbsp;分钟。</p>
          <p style="line-height:30px;text-indent:2em">在操作过程中可随时点击右边联系方式与我们联系。</p>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="21">
          <el-button type="primary"
                     style="float: right;"
                     @click="centerDialogVisible = true, handleChangeType(1)">企业自评</el-button>
        </el-col>
        <el-col :span="3">
          <el-button type="primary"
                     @click="exDialogVisible = true">历史报告</el-button>
        </el-col>
      </el-row>
      <h3>延嘉可提供服务：</h3>
      <h6 style="text-indent:2em">a)&nbsp;精益生产与体系建设</h6>
      <p style="padding-top: 20px;padding-bottom: 20px;text-indent:4em">通过精益变革，帮助企业打造科学高效的精益管理体系，这是企业走向智能化道路的基础和前提。</p>
      <h6 style="text-indent:2em">b)&nbsp;智能工厂规划</h6>
      <p style="padding-top: 20px;padding-bottom: 20px;text-indent:4em">基于工业4.0标准和企业实际，进行智能成熟度评估、智能工厂规划，包括精益布局、精益供应链、精益物流、自动化规划、数字化平台规划、智能工厂实施的辅导。</p>
      <h6 style="text-indent:2em">c)&nbsp;数字化管理平台</h6>
      <p style="padding-top: 20px; padding-bottom: 40px;text-indent:4em">通过管理主题建模、大数据计算和工业APP的开发，实现管理的数字化和智能化应用，提升管理透明度和效率。</p>
    </div>
    <el-dialog :visible.sync="exDialogVisible"
               :before-close="handleClose"
               width="60%">
      <div slot="title"
           style="overflow: hidden;">
        <div style="height:28px"></div>
      </div>
      <span>
        <!-- 历史记录下载页面 -->
        <div id="selfAssessment"
             style="overflow: hidden;">
          <el-form :model="ruleForm"
                   :rules="rules"
                   ref="ruleForm"
                   label-width="120px"
                   class="demo-ruleForm">
            <el-row style="margin-bottom:20px; text-align:center; font-size:24px; font-weight:600">历史报告下载基本信息</el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="企业名称："
                              prop="enterpriseName">
                  <el-input v-model="ruleForm.enterpriseName"
                            placeholder="请输入企业名称"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="姓名："
                              prop="name">
                  <el-input v-model="ruleForm.name"
                            @change="handleName"
                            placeholder="请输入姓名"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="邮箱："
                              prop="email">
                  <el-input v-model="ruleForm.email"
                            placeholder="请输入邮箱"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="联系电话："
                              prop="phone">
                  <el-input v-model="ruleForm.phone"
                            placeholder="请输入联系电话"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-col :span="16">
                  <el-form-item label="验证码："
                                prop="code">
                    <el-input v-model="ruleForm.code"
                              placeholder="请输入验证码" />
                  </el-form-item>
                </el-col>
                <el-col :span="8"
                        id="button">
                  <el-button @click="getCode()"
                             style="width:96%"
                             :class="{'disabled-style':getCodeBtnDisable}"
                             :disabled="getCodeBtnDisable">{{codeBtnWord}}</el-button>
                </el-col>
              </el-col>
            </el-row>
          </el-form>
          <el-button type="primary"
                     @click="handleDown('ruleForm')"
                     style="float: right;">确认下载</el-button>
        </div>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="centerDialogVisible"
               :before-close="handleClose"
               width="70%">
      <div slot="title"
           style="overflow: hidden;">
        <el-image style="width:28px; height:28px; float:left"
                  fit="contain"
                  :src="require('@assets/add.png')"></el-image>
        <span class="title-text">基本信息</span>
      </div>
      <span>
        <!-- 基本信息页面 -->
        <div id="selfAssessment"
             style="overflow: hidden;">
          <el-form :model="ruleForm"
                   :rules="rules"
                   ref="ruleForm"
                   label-width="150px"
                   class="demo-ruleForm">
            <el-row style="margin-bottom:20px; text-align:center">企业基本信息</el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="企业名称："
                              prop="enterpriseName">
                  <el-input v-model="ruleForm.enterpriseName"
                            placeholder="请输入企业名称"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="企业类型："
                              title="离散型生产的特点: 它的产品是由许多零部件组成，各零部件的加工装配过程是彼此独立的，所以整个产品的加工工艺是离散的，制成的零件通过部件装配和总装配，最终成为成品，机械制造、电子设备制造行业的生产过程均属于这一类型。
                流程型生产的特点: 工艺过程是连续进行的，不能中断，工艺过程的顺序是固定不变的，生产设施按照工艺流程布置，原料经过固定的工艺流程连续不断的经过一系列设备和装置被加工成成品。化工、炼油、水泥、造纸都是流程性生产。"
                              prop="enterpriseType">
                  <el-select v-model="ruleForm.enterpriseType"
                             placeholder="请选择企业类型"
                             @change="handleChangeType"
                             style="width: 100%">
                    <el-option v-for="item in enterpriseTypeAll"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="企业所属行业："
                              prop="enterpriseIndustry"
                              :labelCol="labelCol"
                              :wrapperCol="wrapperCol">
                  <el-input v-model="ruleForm.enterpriseIndustry"
                            placeholder="请输入企业所属行业"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="企业人员规模："
                              prop="enterpriseStaffsSize">
                  <el-input v-model="ruleForm.enterpriseStaffsSize"
                            placeholder="请输入企业人员规模"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="企业业务模式："
                              prop="enterpriseBusinessModel">
                  <el-select v-model="ruleForm.enterpriseBusinessModel"
                             placeholder="请选择企业业务模式"
                             style="width: 100%">
                    <el-option v-for="item in businessModeAll"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="企业销售收入："
                              prop="enterpriseSalesRevenue">
                  <el-input v-model="ruleForm.enterpriseSalesRevenue"
                            placeholder="请输入企业销售输入"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col class="checked_lable">
                <el-form-item label="企业能力子域："
                              prop="enterpriseCapabilitySubdomain">
                  <div style="font-size: 18px;">
                    <el-checkbox :indeterminate="isIndeterminate"
                                 v-model="checkAll"
                                 disabled
                                 style="margin-right:10px"
                                 @change="handleCheckAllChange"></el-checkbox>必选
                  </div>
                  <el-checkbox-group v-model="ruleForm.enterpriseCapabilitySubdomain"
                                     @change="handleCheckedCitiesChange">
                    <span v-for="item in enterpriseCapabilityAll"
                          :key="item.optionContent">
                      <el-checkbox v-if="item.isRequired == 0"
                                   disabled
                                   :label="item.optionContent"
                                   :key="item.optionContent">{{item.optionContent}}</el-checkbox>
                    </span>
                  </el-checkbox-group>
                  <div style="font-size:18px">
                    <el-checkbox :indeterminate="isIndeterminate1"
                                 v-model="checkAll1"
                                 style="margin: 6px 0;margin-right:10px"
                                 @change="handleCheckAllChange1"></el-checkbox>可选
                  </div>
                  <el-checkbox-group v-model="ruleForm.enterpriseCapabilitySubdomain"
                                     @change="handleCheckedCitiesChange1">
                    <span v-for="item in enterpriseCapabilityAll"
                          :key="item.optionContent">
                      <el-checkbox v-if="item.isRequired == 1"
                                   :label="item.optionContent">{{item.optionContent}}</el-checkbox>
                    </span>
                  </el-checkbox-group>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row style="margin-bottom:20px; text-align:center">填写人基本信息</el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="姓名："
                              prop="name">
                  <el-input v-model="ruleForm.name"
                            @change="handleName"
                            placeholder="请输入姓名"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="性别："
                              prop="sex">
                  <el-select v-model="ruleForm.sex"
                             placeholder="请选择性别"
                             style="width: 100%">
                    <el-option v-for="item in sexAll"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="职务："
                              prop="duty">
                  <el-input v-model="ruleForm.duty"
                            placeholder="请输入职务"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="邮箱："
                              prop="email">
                  <el-input v-model="ruleForm.email"
                            placeholder="请输入邮箱"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="联系电话："
                              prop="phone">
                  <el-input v-model="ruleForm.phone"
                            placeholder="请输入联系电话"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-col :span="17">
                  <el-form-item label="验证码："
                                prop="code">
                    <el-input v-model="ruleForm.code"
                              placeholder="请输入验证码" />
                  </el-form-item>
                </el-col>
                <el-col :span="7"
                        id="button">
                  <el-button @click="getCode()"
                             style="width:96%"
                             :class="{'disabled-style':getCodeBtnDisable}"
                             :disabled="getCodeBtnDisable">{{codeBtnWord}}</el-button>
                </el-col>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12"
                      class="checked_privacy">
                <el-checkbox v-model="checked">已阅读，同意延嘉</el-checkbox><a style="color: #409EFF; font-size:16px;"
                   @click="dialogVisible = true">【隐私条例】</a>
              </el-col>
            </el-row>
          </el-form>
          <el-button type="primary"
                     title="请先勾选已阅读，同意延嘉隐私条例，才能进行下一步"
                     :disabled="this.checked == false"
                     @click="handleNext('ruleForm')"
                     style="float: right;">下一步</el-button>
        </div>
      </span>
    </el-dialog>
    <el-dialog title="【隐私条例】"
               :visible.sync="dialogVisible"
               width="60%">
      <span>
        <div>
          <h4>提示条款</h4>
          <p style="padding-bottom:20px;padding-top: 10px;line-height: 30px;text-indent:2em">
            尊敬的用户，我们深知信息安全的重要性，我们将按照法律法规要求，采取安全保护措施，保护您的信息安全。并提醒您： 在使用延嘉数字科技智能制造成熟度评价平台（以下简称延嘉成熟度评价平台）网站的各项服务前，请您务必仔细阅读并理解本《隐私政策》，在确认充分理解并同意后方使用相关产品和服务。一旦您开始使用延嘉成熟度评价平台服务，将被视为对本声明和政策内容的接受和认可<span style="display:block;text-indent:2em">如对本声明和政策内容有任何疑问、意见或建议，您可通过延嘉成熟度评价平台的邮箱或电话与我们联系。</span>
          </p>
          <h4>声明</h4>
          <h5 style="padding-top:10px">一、权利归属</h5>
          <p style="line-height:30px;text-indent:2em">（1） 延嘉成熟度评价平台所有图文、标识等，未经深圳市延嘉数字科技有限公司（以下简称延嘉）书面授权，任何人不得以任何方式展示、使用或做其他处理（包括但不限于复制、传播、展示、上传、下载），也不得向他人表明您有权展示、使用或做其他处理。</p>
          <p style="line-height:30px;text-indent:2em">（2）延嘉成熟度评价平台上所有的产品、服务、技术的知识产权均归属于延嘉或归其权利人所有。</p>
          <p style="line-height:30px;text-indent:2em">（3）除非延嘉另行声明，延嘉拥有在网站内发布文档等信息的所有权利。未经延嘉许可，任何人不得擅自使用。</p>
          <h5>二、责任限制</h5>
          <p style="line-height:30px;text-indent:2em">（1）延嘉用户提供给延嘉成熟度评价平台网站的信息，包括但不限于公司名称、 联系人及联络信息等，延嘉并不证实其内容的真 实性，用户对其提供的任何信息依法承担全部责任。</p>
          <p style="line-height:30px;text-indent:2em">（2）延嘉在此提示，您在使用延嘉成熟度评价平台服务期间应当遵守中华人民共和国的法律，不得危害网络安全，不得利用延嘉成熟度评价平台的服务从事他人侵犯名誉、隐私、知识产权和其他合法权益的活动，延嘉不对您使用延嘉成熟度评价平台服务的用途和目的承担任何责任。</p>
          <h5>三、知识产权保护</h5>
          <p style="line-height:30px;text-indent:2em">我们尊重知识产权，反对并打击侵犯知识产权的行为。任何组织或个人认为延嘉成熟度评价平台内容可能侵犯其合法权益的，可以通过客服、邮箱、电话等提出通知，我们将在收到知识产权权利人合格通知后依法尽快处理。</p>
          <h5>四、如何联系我们</h5>
          <p style="line-height:30px;padding-bottom:20px;text-indent:2em">您对本政策内容有任何疑问或建议，或者您对延嘉关于本隐私政策的实践以及操作上有任何疑问，您可通过延嘉的客服（13632960300 ）、邮箱（jason.leng@szyanjia.com）与我们联系。</p>
          <h4>向您提供服务</h4>
          <p style="line-height:30px;padding-top:10px;text-indent:2em">
            （1）根据中华人民共和国法律，在您使用具体的服务时，如有要求，您应遵守延嘉服务的相关流程及规范提供您的真实身份信息和您企业的相关信息（包括不限于公司名称、企业性质等）真实可靠。
          </p>
          <p style="line-height:30px;text-indent:2em">（2）当您使用延嘉成熟度评价平台提供的服务时，我们会自动收集您对我们服务的详细使用情况，作为有关网络日志保存。</p>
          <p style="line-height:30px;padding-bottom:20px;text-indent:2em">（3）我们在向您提供业务体服务时，我们会按照本政策以及相应的产品服务协议的约定收集、使用、存储、对外提供及保护您的信息；超出本政策以及相应的产品服务协议约定收集您的用户信息的，我们会另行向您说明信息收集的范围与目的，并征得您的同意后，收集相应服务所必要的您的信息。</p>
          <h4>我们何时会使用您的信息</h4>
          <p style="line-height:30px;padding-top:10px;text-indent:2em">我们出于如下目的，使用您提交以及我们收集的信息：</p>
          <p style="line-height:30px;text-indent:4em">（1）为了向您提供服务，我们会向您发送信息、通知或其他形式与您进行业务沟通。</p>
          <p style="line-height:30px;text-indent:4em">（2）分析和解决与产品或服务有关的问题。</p>
          <p style="line-height:30px;text-indent:4em">（3）组织调研。</p>
          <p style="line-height:30px;text-indent:4em">（4）组织市场营销活动，如填写活动报名信息、寄送礼品等。</p>
          <p style="line-height:30px;text-indent:4em">（5）在超出具有直接或合理关联的范围使用用户信息前，我们会再次向您告知并征得您的明确同意。</p>
          <h4 style="margin-top:20px;margin-bottom:10px">我们如何共享、公开披露您的用户信息</h4>
          <h5>（1）共享</h5>
          <p style="line-height:30px; text-indent:2em">我们不会与除延嘉或延嘉关联公司外的其他组织和个人共享您的用户信息，但以下情况除外：</p>
          <p style="line-height:30px; text-indent:4em"> 1、在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的用户信息。此外，我们可能会根据法律法规规定、诉讼或按行政司法机关依法提出的要求，对外共享您的用户信息；</p>
          <p style="line-height:30px; text-indent:4em"> 2、与授权合作伙伴共享：我们可能委托受信赖的合作伙伴来提供服务，因此我们可能会与合作伙伴共享您的某些用户信息，以提供更好的客户服务和优化用户体验。我们仅会出于合法、正当、必要、特定、明确的目的共享您的用户信息，并且只会共享提供服务所必要的用户信息。我们的合作伙伴无权将共享的用户信息用于任何其他用途。</p>
          <h5>（2）公开披露</h5>
          <p style="line-height:30px; text-indent:2em">我们仅会在以下情况下，公开披露您的用户信息：</p>
          <p style="line-height:30px; text-indent:4em">（1）获得您明确同意或基于您的主动选择，我们可能会公开披露您的信息；</p>
          <p style="line-height:30px; text-indent:4em">（2）或为保护延嘉及其关联公司用户或公众的人身财产安全免遭侵害，我们可能依据适用的法律或延嘉相关协议、条款披露关于您的信息。</p>
          <p style="line-height:30px; text-indent:4em">（3）共享、公开披露用户信息时事先征得授权同意的例外</p>
          <p style="line-height:30px; text-indent:2em">以下情形中，共享、转让、公开披露您的用户信息无需事先征得您的授权同意：</p>
          <p style="line-height:30px; text-indent:4em"> 1、与国家安全、国防安全、公共安全、公共卫生、重大公共利益有关的；</p>
          <p style="line-height:30px; text-indent:4em">2、与犯罪侦查、起诉、审判和判决执行等有关的；</p>
          <p style="line-height:30px; text-indent:4em">3、出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</p>
          <p style="line-height:30px; text-indent:4em">4、您自行向社会公众公开的个人信息；</p>
          <p style="line-height:30px; text-indent:4em"> 5、从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。</p>
          <h4 style="margin-top:20px;margin-bottom:10px">延嘉保护和保存信息</h4>
          <p style="line-height:30px; text-indent:2em">（1）延嘉非常重视信息安全，我们努力采取各种合理安全措施来保护您和您企业的信息。防止信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。</p>
          <p style="line-height:30px; text-indent:2em">（2） 在不幸发生用户信息安全事件（泄露、丢失等）后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、电话、推送通知等方式告知您，难以逐一告知用户信息主体时，我们会采取合理、有效的方式发布公告。</p>
          <h4 style="margin-top:20px;margin-bottom:10px">隐私政策的更新</h4>
          <p style="line-height:30px; text-indent:2em;margin-bottom:20px">我们的隐私政策可能修订，我们会在延嘉网站主页上发布对隐私权政策所做的任何修订，对于重大修订，我们还会提供更为直接、显著的通知（例如通过网站公告、弹窗提示等说明隐私权政策的具体变更内容）。</p>
          <em style="font-size:12px;display:block">更新于：2021年4月15日</em>
          <em style="font-size:12px;display:block">© 深圳市延嘉数字科技有限公司 版权所有，保留一切权利。</em>
          <em style="font-size:12px;display:block">最终解释权 本协议制定的各项规则最终解释权归深圳市延嘉数字科技有限公司所有。</em>
        </div>
      </span>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="dialogVisible = false">关闭</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import { getAction, postAction } from '@/api/manage'
import { ACCESS_TOKEN, ACCESS_TOKEN1 } from '@/store/mutation-types'
import Vue from 'vue'
export default {
  name: 'learnMore',
  components: {
  },
  data () {
    return {
      checked: false,
      exDialogVisible: false,
      centerDialogVisible: false,
      dialogVisible: false,
      ruleForm: {
        enterpriseName: null,
        enterpriseType: 1,
        enterpriseIndustry: null,
        enterpriseStaffsSize: null,
        enterpriseBusinessModel: null,
        enterpriseSalesRevenue: null,
        enterpriseCapabilitySubdomain: [],
        name: null,
        sex: null,
        phone: null,
        email: null,
        code: null,
        duty: null
      },
      token: '',
      codeBtnWord: "获取验证码", // 获取验证码按钮文字
      waitTime: 61, // 获取验证码按钮失效时间
      checkAll: true,
      checkAll1: true,
      isIndeterminate1: false,
      isIndeterminate: false,
      enterpriseCapabilityAll: [],
      recordType: null,
      masterId: '',
      username: '',
      enterpriseTypeAll: [
        {
          value: 1,
          label: '离散型'
        },
        {
          value: 2,
          label: '流程型'
        }
      ], // 企业类型
      businessModeAll: [
        {
          value: 1,
          label: 'OEM'
        },
        {
          value: 2,
          label: 'ODM'
        },
        {
          value: 3,
          label: 'OBM'
        }
      ], // 企业业务模式
      sexAll: [
        {
          value: 1,
          label: '男'
        },
        {
          value: 2,
          label: '女'
        }
      ], // 性别
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      rules: {
        enterpriseName: [
          { required: true, message: '请输入企业名称', trigger: 'blur' },
          { min: 1, max: 28, message: '长度在 1 到 28 个字符之间', trigger: 'blur' }
        ],
        enterpriseType: [
          { required: true, message: '请选择企业类型', trigger: 'blur' }
        ],
        enterpriseIndustry: [
          { min: 1, max: 32, message: '长度在 1 到 32 个字符之间', trigger: 'blur' }
        ],
        enterpriseStaffsSize: [
          { min: 1, max: 20, message: '长度在 1 到 20 个字符之间', trigger: 'blur' }
        ],
        enterpriseSalesRevenue: [
          { min: 1, max: 20, message: '长度在 1 到 20 个字符之间', trigger: 'blur' }
        ],
        enterpriseCapabilitySubdomain: [
          { required: true, message: '请选择企业能力子域', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
          { min: 2, max: 10, message: '长度在 2 到 10 个字符之间', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '请输入联系电话', trigger: 'change' },
          { validator: this.isPhone, trigger: 'change' }
        ],
        duty: [
          { min: 1, max: 18, message: '长度在 1 到 18 个字符之间', trigger: 'blur' }
        ],
        email: [
          { required: true, message: '请输入邮箱', trigger: 'blur' },
          { validator: this.isEmil, trigger: 'blur' }
        ],
        code: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
        ]
      },
      getCodeBtnDisable: true,
      url: {
        insert: '/enterprise/insert', // 提交基本信息
        sendMessage: '/api/enterprise/sendMessage', // 获取邮箱验证码
        userHistory: '/user_history/selectOne', // 通过姓名回填电话与邮箱
        lastCommitHistory: '/recordMaster/enterprise_last_commit_history' // 历史记录下载
      }
    }
  },
  watch: {
    getCodeBtnDisable: {
      handler (newVal) {
        this.$nextTick(() => {
          if (newVal) {
            this.getCode()
          }
        })
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    handleName (name) {
      let params = `${this.url.userHistory}?name=${encodeURI(name)}`
      getAction(params).then(res => {
        if (res.success) {
          let resData = res.result
          if (resData) {
            this.ruleForm.phone = resData.phone
            this.ruleForm.email = resData.email
          } else {
            this.ruleForm.phone = null
            this.ruleForm.email = null
          }
        } else {
          this.$message.error(res.message)
        }
      })
    },
    handleChangeType (value) {
      this.enterpriseCapabilityAll = []
      this.recordType = value
      this.ruleForm.enterpriseCapabilitySubdomain = []
      getAction('maturityTemplate', { templateType: value, categoryLevel: 3 }).then(res => {
        if (res.success) {
          let records = res.result.records
          this.enterpriseCapabilityAll = records
          records.map(item => {
            this.ruleForm.enterpriseCapabilitySubdomain.push(item.optionContent)
          })
        } else {
          this.$message.error(res.message)
        }
      })
    },
    handleDown (formName) {
      Vue.ls.remove(ACCESS_TOKEN)
      this.$refs[formName].validate((err) => {
        if (err) {
          let strFormData = Object.assign({}, this.ruleForm)
          strFormData.username = strFormData.name
          delete strFormData.duty
          delete strFormData.enterpriseBusinessModel
          delete strFormData.enterpriseCapabilitySubdomain
          delete strFormData.enterpriseIndustry
          delete strFormData.enterpriseSalesRevenue
          delete strFormData.enterpriseStaffsSize
          delete strFormData.enterpriseType
          delete strFormData.sex
          getAction(this.url.lastCommitHistory, strFormData).then(res => {
            if (res.success) {
              this.masterId = res.result.id
              this.username = res.result.username
              Vue.ls.set(ACCESS_TOKEN1, res.result.enterpriseId, 7 * 24 * 60 * 60 * 1000)
              this.$message.success("提交成功！")
              this.$router.push({ path: '/appraisalReport', query: { masterId: this.masterId, recordType: this.recordType, username: this.username } })
              this.handleClose()
            } else {
              this.$message.error(res.message)
            }
          })
        } else {
          return false
        }
      })
    },
    // 提交表单信息
    handleNext (formName) {
      Vue.ls.remove(ACCESS_TOKEN1)
      this.$refs[formName].validate((err) => {
        if (err) {
          sessionStorage.clear()
          let strFormData = Object.assign({}, this.ruleForm)
          strFormData.enterpriseCapabilitySubdomain = strFormData.enterpriseCapabilitySubdomain.toString()
          strFormData.type = 2
          postAction(this.url.insert, strFormData).then(res => {
            if (res.success) {
              Vue.ls.set(ACCESS_TOKEN, res.result.token, 7 * 24 * 60 * 60 * 1000)
              this.$message.success("提交成功！")
              this.$router.push({ path: '/weightChoose', query: { recordType: this.recordType } })
              this.handleClose()
            } else {
              this.$message.error(res.message)
            }
          })
        } else {
          return false
        }
      })
    },
    handleClose () {
      this.$refs.ruleForm.resetFields()
      this.checked = false
      this.checkAll = true
      this.checkAll1 = true
      this.exDialogVisible = false
      this.centerDialogVisible = false
    },
    handleCheckAllChange1 (val) {
      if (val) {
        this.enterpriseCapabilityAll.forEach(item => {
          this.ruleForm.enterpriseCapabilitySubdomain.push(item.optionContent)
          this.checkAll1 = true
        })
      } else {
        this.ruleForm.enterpriseCapabilitySubdomain = ['组织战略', '人员技能', '数据', '集成', '信息安全', '装备', '网络', '采购', '计划调度', '生产作业', '仓储配送', '客户服务']
        this.checkAll1 = false
      }
      this.isIndeterminate1 = false
    },
    handleCheckedCitiesChange1 (value) {
      let checkedCount = value.length
      this.checkAll1 = checkedCount === this.enterpriseCapabilityAll.length
      this.isIndeterminate1 = checkedCount > 0 && checkedCount < this.enterpriseCapabilityAll.length
    },
    handleCheckAllChange (val) {
      if (val) {
        this.enterpriseCapabilityAll.forEach(item => {
          this.ruleForm.enterpriseCapabilitySubdomain.push(item.optionContent)
          this.checkAll = true
        })
      } else {
        this.ruleForm.enterpriseCapabilitySubdomain = ['组织战略', '人员技能', '数据', '集成', '信息安全', '装备', '网络', '采购', '计划调度', '生产作业', '仓储配送', '客户服务']
        this.checkAll = false
      }
      this.isIndeterminate = false
    },
    handleCheckedCitiesChange (value) {
      let checkedCount = value.length
      this.checkAll = checkedCount === this.enterpriseCapabilityAll.length
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.enterpriseCapabilityAll.length
    },
    getCode () {
      if (!this.getCodeBtnDisable) {
        let params = `${this.url.sendMessage}?number=${this.ruleForm.phone}`
        // 调用获取短信验证码接口
        this.$axios.get(params).then(res => {
          res = res.data
          if (res.code == 200) {
            this.$message({
              message: "短信验证码已发送，请稍候...",
              type: "success",
              center: true
            })
          }
        })
        // 因为下面用到了定时器，需要保存this指向
        let that = this
        that.waitTime--
        that.getCodeBtnDisable = true
        this.codeBtnWord = `${this.waitTime}s 后重新获取`
        let timer = setInterval(function () {
          if (that.waitTime > 1) {
            that.waitTime--
            that.codeBtnWord = `${that.waitTime}s 后重新获取`
          } else {
            clearInterval(timer)
            that.codeBtnWord = "获取验证码"
            that.getCodeBtnDisable = false
            that.waitTime = 61
          }
        }, 1000)
      }
    },
    // getCode () {
    //   if (this.isEmil) {
    //     let params = `${this.url.sendMessage}?number=${this.ruleForm.email}&type=1`
    //     // 调用获取短信验证码接口
    //     this.$axios.get(params).then(res => {
    //       console.log(res)
    //       res = res.data
    //       if (res.code == 200) {
    //         this.$message({
    //           message: "邮箱验证码已发送，请稍候...",
    //           type: "success",
    //           center: true
    //         })
    //         // 因为下面用到了定时器，需要保存this指向
    //         let that = this
    //         that.waitTime--
    //         that.getCodeBtnDisable = true
    //         this.codeBtnWord = `${this.waitTime}s 后重新获取`
    //         let timer = setInterval(function () {
    //           if (that.waitTime > 1) {
    //             that.waitTime--
    //             that.codeBtnWord = `${that.waitTime}s 后重新获取`
    //           } else {
    //             clearInterval(timer)
    //             that.codeBtnWord = "获取验证码"
    //             that.getCodeBtnDisable = false
    //             that.waitTime = 61
    //           }
    //         }, 1000)
    //       } else {
    //         this.$message.error({
    //           message: "请确认邮箱是否输入正确",
    //           center: true
    //         })
    //       }
    //     })
    //   }
    // },
    // 验证手机号
    isPhone (rule, value, callback) {
      if (!value) {
        this.getCodeBtnDisable = true
        return callback(new Error('联系方式不能为空！'))
      }
      setTimeout(() => {
        if (!(/^(?:(?:\+|00)86)?1[3-9]\d{9}$/.test(value))) {
          this.getCodeBtnDisable = true
          callback(new Error('请输入正确的联系方式！'))
        } else {
          this.getCodeBtnDisable = false
          callback()
        }
      }, 1000)
    },
    // 验证邮箱
    isEmil (rule, value, callback) {
      if (!value) {
        return callback(new Error('邮箱不能为空！'))
      }
      setTimeout(() => {
        if (!(/^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/.test(value))) {
          callback(new Error('请输入正确的邮箱！'))
        } else {
          callback()
        }
      }, 1000)
    }
  }
}
</script>

<style>
.el-main {
  text-align: left !important;
  padding: 0 !important;
}
h2 {
  padding-top: 20px;
  font-size: 30px;
}
h3 {
  font-size: 24px;
  padding: 20px 0;
}
h6 {
  font-size: 18px;
}
p {
  font-size: 16px;
}
#button .el-button {
  padding: 10px !important;
  font-size: 18px !important;
  margin: 0 !important;
  margin-left: 5px !important;
}
.el-button {
  margin-bottom: 10px !important;
  font-size: 16px !important;
}
.el-dialog--center {
  padding: 0 30px 20px !important;
}
.title-text {
  padding-left: 4px;
  font-weight: 600;
  font-size: 22px !important;
}
.el-dialog__body {
  padding: 10px 20px !important;
  font-size: 20px !important;
  color: #000 !important;
}
.el-form-item__label {
  padding: 0 !important;
  font-size: 18px !important;
  color: #000 !important;
}
.el-button.disabled-style {
  background-color: #eeeeee;
  color: #cccccc;
}
.el-checkbox__label {
  font-size: 16px !important;
}
.el-input__inner {
  font-size: 18px !important;
}
.checked_privacy .el-checkbox__inner {
  width: 16px !important;
  height: 16px !important;
}
.checked_privacy .el-checkbox__inner::after {
  height: 8px !important;
  left: 5px !important;
  top: 1px !important;
}
.el-dialog__headerbtn {
  font-size: 28px !important;
}
.el-form-item__content .el-checkbox__label {
  font-size: 18px !important;
}
.el-form-item__content .el-checkbox__inner {
  width: 18px;
  height: 18px;
}
.el-form-item__content
  .el-checkbox__input.is-indeterminate
  .el-checkbox__inner::before {
  top: 7px !important;
}
.el-form-item__content .el-checkbox__inner::after {
  height: 8px !important;
  left: 6px !important;
  top: 2px !important;
}
.checked_lable .el-checkbox__label {
  padding-right: 20px;
}
.el-checkbox__input.is-disabled + span.el-checkbox__label {
  color: #409eff !important;
}
.el-image__inner {
  height: inherit !important;
}
</style>
