<template>
  <div id="delay"
       class="subject">
    <div class="delays">暂待开发......</div>
  </div>
</template>

<script>

export default {
  name: 'delay',
  components: {
  }
}
</script>

<style>
.delays {
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  margin-top: 25%;
}
</style>
