<template>
  <!-- 离散型成熟度要求得分统计 -->
  <div id="weightAdjustment">
    <div class="subject">
      <h2 style="text-align:center; margin-bottom:20px">离散型成熟度要求得分统计</h2>
      <el-table :data="tableData"
                border
                :span-method="objectSpanMethod"
                @cell-click="cellClick"
                style="width: 100%; margin-bottom:10px">
        <el-table-column width="60"
                         align="center"
                         type="index">
        </el-table-column>
        <el-table-column label="能力要素"
                         align="center"
                         width="90">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.capacityFactor }}</span>
          </template>
        </el-table-column>
        <el-table-column label="能力域"
                         align="center"
                         width="90">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.powerDpomain }}</span>
          </template>
        </el-table-column>
        <el-table-column label="能力子域"
                         align="center"
                         width="90">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.abilitySubdomains }}</span>
          </template>
        </el-table-column>
        <el-table-column label="成熟度级别"
                         align="center"
                         width="80">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.level }}</span>
          </template>
        </el-table-column>
        <el-table-column label="成熟度要求"
                         header-align="center"
                         width="373"
                         :show-overflow-tooltip='true'>
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.askFor }}</span>
          </template>
        </el-table-column>
        <el-table-column label="选项（单选）"
                         header-align="center">
          <el-table-column label="A(0分)"
                           align="center"
                           width="120">
            <template slot-scope="scope">
              <el-checkbox v-model="scope.row.checked">不满足</el-checkbox>
            </template>
          </el-table-column>
          <el-table-column label="B(0.5分)"
                           align="center"
                           width="120">
            <template slot-scope="scope">
              <el-checkbox v-model="scope.row.checked">部分满足</el-checkbox>
            </template>
          </el-table-column>
          <el-table-column label="C(0.8分)"
                           align="center"
                           width="120">
            <template slot-scope="scope">
              <el-checkbox v-model="scope.row.checked">大部分满足</el-checkbox>
            </template>
          </el-table-column>
          <el-table-column label="D(1分)"
                           align="center"
                           width="120">
            <template slot-scope="scope">
              <el-checkbox v-model="scope.row.checked">全部满足</el-checkbox>
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>
      <div style="overflow: hidden;">
        <router-link :to="{ path: '/questionnaire' }">
          <el-button type="primary"
                     @click="handleOk"
                     style="float:right;">提交</el-button>
        </router-link>
        <p style="margin-top:60px; margin-bottom:20px">基本信息->能力子域中未选择子域内容跳过不显示</p>
        <p style="margin-bottom:20px">点击提交后保存数据，进入下一页面</p>
      </div>
      <el-pagination :page-size="10"
                     :pager-count="11"
                     layout="prev, pager, next"
                     :total="100">
      </el-pagination>
    </div>
  </div>
</template>

<script>

export default {
  name: 'weightAdjustment',
  components: {
  },
  data () {
    return {
      total: 0,
      pageNo: 1,
      tableData: [{
        seen: false,
        capacityFactor: '人员',
        powerDpomain: '组织战略',
        abilitySubdomains: '组织战略',
        level: '1级',
        askFor: '企业应具备发展智能制造的愿景；'
      },
      {
        seen: false,
        capacityFactor: '人员',
        powerDpomain: '组织战略',
        abilitySubdomains: '组织战略',
        level: '1级',
        askFor: '企业应对发展智能制造所需的资源投入做出规划'
      },
      {
        seen: false,
        capacityFactor: '人员',
        powerDpomain: '组织战略',
        abilitySubdomains: '组织战略',
        level: '2级',
        askFor: '企业应具备智能制造的发展战略，对智能制造的资源投入、技术架构、人员配备等进行规划，形成具体的实施计划'
      },
      {
        seen: false,
        capacityFactor: '人员',
        powerDpomain: '组织战略',
        abilitySubdomains: '组织战略',
        level: '2级',
        askFor: '企业应综合考虑内外部环境的变化、生产经营的持续稳定发展、智能制造战略目标等关键因素，对内部组织结构进行规划；'
      },
      {
        seen: false,
        capacityFactor: '人员',
        powerDpomain: '组织战略',
        abilitySubdomains: '组织战略',
        level: '2级',
        askFor: '企业内部应明确智能制造责任部门和各关键岗位的责任人，并且明确各岗位的岗位职责；'
      },
      {
        seen: false,
        capacityFactor: '人员',
        powerDpomain: '组织战略',
        abilitySubdomains: '组织战略',
        level: '3级',
        askFor: '应对智能制造战略的执行情况进行监控与评测，通过对各指标、战略相关性检验和调整，持续优化战略；'
      },
      {
        seen: false,
        capacityFactor: '人员',
        powerDpomain: '组织战略',
        abilitySubdomains: '组织战略',
        level: '3级',
        askFor: '应建立优化岗位结构的机制并定期对岗位结构和岗位职责的适宜性进行评估，基于评估结果实施岗位结构优化和岗位调整'
      },
      {
        seen: false,
        capacityFactor: '人员',
        powerDpomain: '人员技能',
        abilitySubdomains: '人员技能',
        level: '1级',
        askFor: '应建立优化岗位结构的机制并定期对岗位结构和岗位职责的适宜性进行评估，基于评估结果实施岗位结构优化和岗位调整'
      },
      {
        seen: false,
        capacityFactor: '人员',
        powerDpomain: '人员技能',
        abilitySubdomains: '人员技能',
        level: '1级',
        askFor: '应培养或引进拟发展智能制造需要的人员'
      },
      {
        seen: false,
        capacityFactor: '人员',
        powerDpomain: '人员技能',
        abilitySubdomains: '人员技能',
        level: '2级',
        askFor: '企业应具备 IT 基础、自动化技术、数据分析、数据安全、信息安全等信息技术的人员'
      },
      {
        seen: false,
        capacityFactor: '人员',
        powerDpomain: '人员技能',
        abilitySubdomains: '人员技能',
        level: '2级',
        askFor: '应具备掌握设备操作、设备维护、操作编程等自动化技术的人员'
      },
      {
        seen: false,
        capacityFactor: '人员',
        powerDpomain: '人员技能',
        abilitySubdomains: '人员技能',
        level: '2级',
        askFor: '应制定适宜的智能制造人才培训体系、绩效考核机制等，及时有效的使员工获取新的技能和资格，以适应企业智能制造发展需要'
      },
      {
        seen: false,
        capacityFactor: '人员',
        powerDpomain: '人员技能',
        abilitySubdomains: '人员技能',
        level: '3级',
        askFor: '企业应具备智能制造统筹规划能力的个人或团队'
      },
      {
        seen: false,
        capacityFactor: '人员',
        powerDpomain: '人员技能',
        abilitySubdomains: '人员技能',
        level: '3级',
        askFor: '应具备创新管理机制，鼓励员工持续进行技术创新和管理创新'
      },
      {
        seen: false,
        capacityFactor: '人员',
        powerDpomain: '人员技能',
        abilitySubdomains: '人员技能',
        level: '3级',
        askFor: '应通过信息技术手段管理人员贡献的知识和经验，并结合智能制造业务需求，开展分析和应用'
      },
      {
        seen: false,
        capacityFactor: '人员',
        powerDpomain: '人员技能',
        abilitySubdomains: '人员技能',
        level: '4级',
        askFor: '应将人员知识、技能和经验进行数字化与代码化，实现人员知识、技能、经验的沉淀与传播'
      },
      {
        seen: false,
        capacityFactor: '技术',
        powerDpomain: '数据',
        abilitySubdomains: '数据',
        level: '1级',
        askFor: '通过人工方式采集制造活动所需的数据，在关键环节应用自动数据采集，实时获取数据'
      },
      {
        seen: false,
        capacityFactor: '技术',
        powerDpomain: '数据',
        abilitySubdomains: '数据',
        level: '1级',
        askFor: '基于人工经验开展常规数据分析'
      },
      {
        seen: false,
        capacityFactor: '技术',
        powerDpomain: '数据',
        abilitySubdomains: '数据',
        level: '2级',
        askFor: '应采用二维码、条形码、RFID等技术，实现数据采集'
      },
      {
        seen: false,
        capacityFactor: '技术',
        powerDpomain: '数据',
        abilitySubdomains: '数据',
        level: '2级',
        askFor: '应基于信息系统数据和人工经验开展数据分析，满足特定范围的数据使用需求'
      },
      {
        seen: false,
        capacityFactor: '技术',
        powerDpomain: '数据',
        abilitySubdomains: '数据',
        level: '2级',
        askFor: '数据分析结果的应用局限于部门内，跨部门的共享以线下为主'
      },
      {
        seen: false,
        capacityFactor: '技术',
        powerDpomain: '数据',
        abilitySubdomains: '数据',
        level: '3级',
        askFor: '应使用传感技术，实现制造关键环节数据的自动采集'
      },
      {
        seen: false,
        capacityFactor: '技术',
        powerDpomain: '数据',
        abilitySubdomains: '数据',
        level: '3级',
        askFor: '在企业层面建设统一数据平台，整合数据资源，支持跨部门及部门内部常规数据分析'
      },
      {
        seen: false,
        capacityFactor: '技术',
        powerDpomain: '数据',
        abilitySubdomains: '数据',
        level: '3级',
        askFor: '数据分析结果能在各个部门之间进行复用，数据分析口径定义明确'
      },
      {
        seen: false,
        capacityFactor: '技术',
        powerDpomain: '数据',
        abilitySubdomains: '数据',
        level: '4级',
        askFor: '能够基于一定的算法、模型对数据进行实时监测，并根据预设的阈值进行预警'
      },
      {
        seen: false,
        capacityFactor: '技术',
        powerDpomain: '数据',
        abilitySubdomains: '数据',
        level: '4级',
        askFor: '建立了常用数据分析模型库，支持业务人员快速进行数据探索和分析'
      },
      {
        seen: false,
        capacityFactor: '技术',
        powerDpomain: '数据',
        abilitySubdomains: '数据',
        level: '4级',
        askFor: '应采用大数据技术，应用各类型算法模型，预测制造环节状态，为制造运行维护活动提供优化建议和决策支持'
      },
      {
        seen: false,
        capacityFactor: '技术',
        powerDpomain: '数据',
        abilitySubdomains: '数据',
        level: '4级',
        askFor: '应量化评价数据分析效果，实现数据应用的量化分析'
      },
      {
        seen: false,
        capacityFactor: '技术',
        powerDpomain: '数据',
        abilitySubdomains: '数据',
        level: '5级',
        askFor: '能够对数据分析模型实时优化，实现基于数据的精准执行'
      },
      {
        seen: false,
        capacityFactor: '技术',
        powerDpomain: '集成',
        abilitySubdomains: '集成',
        level: '1级',
        askFor: '企业初步形成系统集成的需求分析'
      },
      {
        seen: false,
        capacityFactor: '技术',
        powerDpomain: '集成',
        abilitySubdomains: '集成',
        level: '2级',
        askFor: '企业初步规划整体系统集成架构，规划应包括网络、硬件、软件等的集成'
      },
      {
        seen: false,
        capacityFactor: '技术',
        powerDpomain: '集成',
        abilitySubdomains: '集成',
        level: '2级',
        askFor: '企业初步实现了关键业务领域设备、系统间的集成'
      },
      {
        seen: false,
        capacityFactor: '技术',
        powerDpomain: '集成',
        abilitySubdomains: '集成',
        level: '3级',
        askFor: '企业具备完整的系统集成架构'
      },
      {
        seen: false,
        capacityFactor: '技术',
        powerDpomain: '集成',
        abilitySubdomains: '集成',
        level: '3级',
        askFor: '具备设备、控制系统与软件系统间集成的技术方案,包括异构协议的集成方案、工业软件的接口规范等'
      },
      {
        seen: false,
        capacityFactor: '技术',
        powerDpomain: '集成',
        abilitySubdomains: '集成',
        level: '3级',
        askFor: '企业实现了跨业务领域设备、系统间的集成'
      },
      {
        seen: false,
        capacityFactor: '技术',
        powerDpomain: '集成',
        abilitySubdomains: '集成',
        level: '4级',
        askFor: '企业建立了统一的集成架构，应用企业服务总线（ESB）和操作数据存储系统（ODS）等方式，实现跨业务的集成'
      },
      {
        seen: false,
        capacityFactor: '技术',
        powerDpomain: '信息安全',
        abilitySubdomains: '信息安全',
        level: '1级',
        askFor: '已制定并落实工业信息安全管理机制'
      },
      {
        seen: false,
        capacityFactor: '技术',
        powerDpomain: '信息安全',
        abilitySubdomains: '信息安全',
        level: '1级',
        askFor: '已成立工业信息安全协调小组'
      },
      {
        seen: false,
        capacityFactor: '技术',
        powerDpomain: '信息安全',
        abilitySubdomains: '信息安全',
        level: '2级',
        askFor: '应定期对关键工业控制系统开展工业信息安全风险评估'
      },
      {
        seen: false,
        capacityFactor: '技术',
        powerDpomain: '信息安全',
        abilitySubdomains: '信息安全',
        level: '2级',
        askFor: '工业主机应安装正规的工业防病毒软件'
      },
      {
        seen: false,
        capacityFactor: '技术',
        powerDpomain: '信息安全',
        abilitySubdomains: '信息安全',
        level: '2级',
        askFor: '工业主机应进行安全配置和补丁管理'
      },
      {
        seen: false,
        capacityFactor: '技术',
        powerDpomain: '信息安全',
        abilitySubdomains: '信息安全',
        level: '3级',
        askFor: '工业控制网络边界应具有边界防护能力'
      },
      {
        seen: false,
        capacityFactor: '技术',
        powerDpomain: '信息安全',
        abilitySubdomains: '信息安全',
        level: '3级',
        askFor: '工业控制设备的远程访问应进行安全管理和加固'
      },
      {
        seen: false,
        capacityFactor: '技术',
        powerDpomain: '信息安全',
        abilitySubdomains: '信息安全',
        level: '4级',
        askFor: '工业网络应部署具有深度包解析功能的安全设备'
      },
      {
        seen: false,
        capacityFactor: '技术',
        powerDpomain: '信息安全',
        abilitySubdomains: '信息安全',
        level: '4级',
        askFor: '应自建离线测试环境，对工业现场使用的设备进行安全性测试'
      },
      {
        seen: false,
        capacityFactor: '技术',
        powerDpomain: '信息安全',
        abilitySubdomains: '信息安全',
        level: '4级',
        askFor: '在工业企业管理网中，采用具备自学习、自优化功能的安全防护措施'
      },
      {
        seen: false,
        capacityFactor: '资源',
        powerDpomain: '装备（设备）',
        abilitySubdomains: '装备（设备）',
        level: '1级',
        askFor: '企业应在关键工序应用自动化设备'
      },
      {
        seen: false,
        capacityFactor: '资源',
        powerDpomain: '装备（设备）',
        abilitySubdomains: '装备（设备）',
        level: '1级',
        askFor: '企业应对关键工序设备形成技改方案'
      },
      {
        seen: false,
        capacityFactor: '资源',
        powerDpomain: '装备（设备）',
        abilitySubdomains: '装备（设备）',
        level: '2级',
        askFor: '企业应在关键工序应用数字化设备'
      },
      {
        seen: false,
        capacityFactor: '资源',
        powerDpomain: '装备（设备）',
        abilitySubdomains: '装备（设备）',
        level: '2级',
        askFor: '设备应具备标准通讯接口(如：RJ45、RS232、RS485 等)，并 支持主流通讯协议 （ 如 ：OPC/OPC UA、MODBUS、PROFIBUS等）'
      },
      {
        seen: false,
        capacityFactor: '资源',
        powerDpomain: '装备（设备）',
        abilitySubdomains: '装备（设备）',
        level: '3级',
        askFor: '自动化设备、数控装备等应具备无纸化作业、在线加工、模拟加工、图形化编程等人机交互'
      },
      {
        seen: false,
        capacityFactor: '资源',
        powerDpomain: '装备（设备）',
        abilitySubdomains: '装备（设备）',
        level: '3级',
        askFor: '企业在关键工序实现设备互联互通，实现设备联网和数据采集'
      },
      {
        seen: false,
        capacityFactor: '资源',
        powerDpomain: '装备（设备）',
        abilitySubdomains: '装备（设备）',
        level: '3级',
        askFor: '企业生产设备、动力设备等具有远程监控和远程诊断功能，可实现故障预警统计与分析'
      },
      {
        seen: false,
        capacityFactor: '资源',
        powerDpomain: '装备（设备）',
        abilitySubdomains: '装备（设备）',
        level: '4级',
        askFor: '设备应有预测性维护功能'
      },
      {
        seen: false,
        capacityFactor: '资源',
        powerDpomain: '装备（设备）',
        abilitySubdomains: '装备（设备）',
        level: '4级',
        askFor: '建立设备数字孪生模型，实现数据采集、设备控制和远程维护'
      },
      {
        seen: false,
        capacityFactor: '资源',
        powerDpomain: '装备（设备）',
        abilitySubdomains: '装备（设备）',
        level: '5级',
        askFor: '设备数据模型、机理模型应支持自适应和定制化功能，实现工业知识沉淀'
      },
      {
        seen: false,
        capacityFactor: '资源',
        powerDpomain: '网络',
        abilitySubdomains: '网络',
        level: '1级',
        askFor: '企业应实现办公区域网络覆盖'
      },
      {
        seen: false,
        capacityFactor: '资源',
        powerDpomain: '网络',
        abilitySubdomains: '网络',
        level: '2级',
        askFor: '企业应实现生产区域的网络覆盖'
      },
      {
        seen: false,
        capacityFactor: '资源',
        powerDpomain: '网络',
        abilitySubdomains: '网络',
        level: '3级',
        askFor: '企业应通过有线网络（现场总线、工业以太网）、无线网络等手段实现企业范围内网络覆盖'
      },
      {
        seen: false,
        capacityFactor: '资源',
        powerDpomain: '网络',
        abilitySubdomains: '网络',
        level: '4级',
        askFor: '企业应建立工业网络和办公网络的防护措施，包括不限于网络安全隔离、授权访问等手段'
      },
      {
        seen: false,
        capacityFactor: '资源',
        powerDpomain: '网络',
        abilitySubdomains: '网络',
        level: '4级',
        askFor: '网络应具备远程配置功能，包括不限于带宽、规模、关键节点的扩展功能和升级功能'
      },
      {
        seen: false,
        capacityFactor: '资源',
        powerDpomain: '网络',
        abilitySubdomains: '网络',
        level: '4级',
        askFor: '网络应能够保障关键业务数据传输的完整性'
      },
      {
        seen: false,
        capacityFactor: '资源',
        powerDpomain: '网络',
        abilitySubdomains: '网络',
        level: '5级',
        askFor: '企业应建立分布式工业控制网络，基于软件定义网络（SDN）的敏捷网络，实现网络资源编排能力'
      },
      {
        seen: false,
        capacityFactor: '资源',
        powerDpomain: '网络',
        abilitySubdomains: '网络',
        level: '5级',
        askFor: '基于柔性制造、自组织生产的需求，各网络设备进行网络资源调度和业务分发，实现工厂网络的柔性灵活组网'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '设计',
        abilitySubdomains: '产品设计',
        level: '1级',
        askFor: '应基于计算机辅助开展二维产品设计'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '设计',
        abilitySubdomains: '产品设计',
        level: '1级',
        askFor: '应根据用户需求，按照设计经验进行产品设计方案的策划'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '设计',
        abilitySubdomains: '产品设计',
        level: '1级',
        askFor: '应制定产品设计过程相关标准规范'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '设计',
        abilitySubdomains: '产品设计',
        level: '1级',
        askFor: '应根据理论或经验对产品设计进行推理验证'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '设计',
        abilitySubdomains: '产品设计',
        level: '2级',
        askFor: '应建立计算机辅助三维产品设计平台'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '设计',
        abilitySubdomains: '产品设计',
        level: '2级',
        askFor: '应通过设计管理软件实现产品设计数据或文档的结构化管理及数据共享，实现产品设计的流程、结构的统一管理，以及版本管理、权限控制、电子审批等'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '设计',
        abilitySubdomains: '产品设计',
        level: '2级',
        askFor: '应实现产品设计过程中不同专业或者组件之间的并行协同'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '设计',
        abilitySubdomains: '产品设计',
        level: '3级',
        askFor: '应建立典型产品组件的标准库及典型产品设计知识库，在产品设计时进行匹配和引用'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '设计',
        abilitySubdomains: '产品设计',
        level: '3级',
        askFor: '三维模型应集成产品设计信息（尺寸、公差、工程说明、材料需求等），确保产品研发过程中数据源的唯一性；'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '设计',
        abilitySubdomains: '产品设计',
        level: '3级',
        askFor: '应基于模型实现对外观、结构、性能等关键要素的设计仿真及迭代优化'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '设计',
        abilitySubdomains: '产品设计',
        level: '3级',
        askFor: '应建立产品设计与工艺设计的协同平台，通过工艺设计的介入与联动，实现产品设计与工艺设计间的信息交互、并行协同'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '设计',
        abilitySubdomains: '产品设计',
        level: '3级',
        askFor: '应基于产品组件的标准库、产品设计知识库的集成和应用，实现产品参数化、模块化设计；'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '设计',
        abilitySubdomains: '产品设计',
        level: '4级',
        askFor: '应将产品的设计信息、生产信息、检验信息、运维信息、销售信息、服务信息等集成于产品的三维数字化模型中，实现基于模型的产品数据归档和管理'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '设计',
        abilitySubdomains: '产品设计',
        level: '4级',
        askFor: '应构建完整的产品设计仿真分析平台，并对产品外观、结构、性能、工艺等进行仿真分析与迭代优化'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '设计',
        abilitySubdomains: '产品设计',
        level: '4级',
        askFor: '应通过产品设计、生产及支撑业务范围内的高度集成，实现设计、生产、检验、运维等业务之间的协同'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '设计',
        abilitySubdomains: '产品设计',
        level: '5级',
        askFor: '应基于参数化、模块化设计，建立个性化定制服务平台，具备个性化定制的接口与能力'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '设计',
        abilitySubdomains: '产品设计',
        level: '5级',
        askFor: '应基于三维模型，建立产品全生命周期的业务模型，满足设计、生产、检验、运维、销售、服务等应用需求'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '设计',
        abilitySubdomains: '产品设计',
        level: '5级',
        askFor: '应基于产品标准库和设计知识库的集成和应用，自动优化并实现产品智能设计'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '设计',
        abilitySubdomains: '产品设计',
        level: '5级',
        askFor: '应基于大数据、知识库建立产品设计云服务平台，进行产品设计周期动态管理，实现服务信息与用户实时交互、协同'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '设计',
        abilitySubdomains: '工艺设计',
        level: '1级',
        askFor: '基于设计经验，进行计算机辅助工艺规划及工艺设计'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '设计',
        abilitySubdomains: '工艺设计',
        level: '1级',
        askFor: '应建立产品设计与工艺设计之间的关联性'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '设计',
        abilitySubdomains: '工艺设计',
        level: '1级',
        askFor: '应根据理论或经验对产品工艺设计进行推理验证'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '设计',
        abilitySubdomains: '工艺设计',
        level: '2级',
        askFor: '应建立工艺设计规范和标准，指导计算机辅助工艺规划及工艺设计'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '设计',
        abilitySubdomains: '工艺设计',
        level: '2级',
        askFor: '应在关键工序实现工艺数据的模拟仿真'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '设计',
        abilitySubdomains: '工艺设计',
        level: '2级',
        askFor: '通过设计管理软件实现工艺设计数据或文档的结构化管理及数据共享，实现工艺设计的流程、结构的统一管理，以及版本管理、权限控制、电子审批等'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '设计',
        abilitySubdomains: '工艺设计',
        level: '2级',
        askFor: '实现工艺设计过程中不同专业之间的并行协同'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '设计',
        abilitySubdomains: '工艺设计',
        level: '3级',
        askFor: '应建立产品典型制造工艺流程、参数、资源等关键要素的知识库，并能以结构化的形式展现、查询与更新'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '设计',
        abilitySubdomains: '工艺设计',
        level: '3级',
        askFor: '应建立工艺设计与管理平台，实现工艺设计数据或文档的结构化管理及数据共享'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '设计',
        abilitySubdomains: '工艺设计',
        level: '3级',
        askFor: '基于在线知识库，实时为产品设计和工艺设计提供决策支持'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '设计',
        abilitySubdomains: '工艺设计',
        level: '3级',
        askFor: '实现设计平台与生产、物流等平台间产品设计信息、工艺设计信息的融合共享'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '设计',
        abilitySubdomains: '工艺设计',
        level: '4级',
        askFor: '应建立包含工装模型、工具模型、设备模型等信息的工艺模型，将完整的工艺信息集成于三维数字化模型中'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '设计',
        abilitySubdomains: '工艺设计',
        level: '4级',
        askFor: '应将知识库与工艺设计系统集成，优化工艺流程、工序内容、工艺资源等'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '设计',
        abilitySubdomains: '工艺设计',
        level: '4级',
        askFor: '应利用有限元分析、虚拟现实等技术，实现基于三维模型的制造工艺全过程的仿真分析及迭代优化'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '设计',
        abilitySubdomains: '工艺设计',
        level: '4级',
        askFor: '通过工艺设计系统与资源管理系统、制造执行系统、质量管理系统等高度集成，形成产品信息、物料清单、工艺路线、工艺设计要求与生产作业等信息下发、执行、反馈、监控、优化等闭环管控，实现工艺设计与生产协同'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '设计',
        abilitySubdomains: '工艺设计',
        level: '5级',
        askFor: '应基于知识库实现辅助工艺创新推理及在线自主优化'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '设计',
        abilitySubdomains: '工艺设计',
        level: '5级',
        askFor: '应实现设计、工艺、生产、检验、运维等信息动态协同'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '设计',
        abilitySubdomains: '工艺设计',
        level: '5级',
        askFor: '应基于云服务平台，围绕产业链实现多领域、多区域、跨平台的全面协同，提供即时的工艺设计服务'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '生产',
        abilitySubdomains: '采购',
        level: '1级',
        askFor: '应根据产品、物料需求和库存等信息制定采购计划'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '生产',
        abilitySubdomains: '采购',
        level: '1级',
        askFor: '应实现对采购订单、采购合同和供应商等信息管理'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '生产',
        abilitySubdomains: '采购',
        level: '1级',
        askFor: '应建立供应商评价体系，并记录评价结果'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '生产',
        abilitySubdomains: '采购',
        level: '2级',
        askFor: '应通过信息系统制定物料需求计划，生成采购计划，并管理和追踪采购执行全过程'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '生产',
        abilitySubdomains: '采购',
        level: '2级',
        askFor: '应通过信息技术手段，实现供应商的寻源、评估、认可和优化'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '生产',
        abilitySubdomains: '采购',
        level: '3级',
        askFor: '应将采购、生产（维修）和仓储等信息系统集成，自动生成采购计划，并实现流水、库存和单据的同步'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '生产',
        abilitySubdomains: '采购',
        level: '3级',
        askFor: '应实现企业和供应商在采购计划与采购执行流程中的信息共享'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '生产',
        abilitySubdomains: '采购',
        level: '3级',
        askFor: '应建立包含质量、技术、响应、交付、成本等要素的供应商评价系统，对供应商能力进行量化评价'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '生产',
        abilitySubdomains: '采购',
        level: '4级',
        askFor: '可通过数据模型优化供应商评价和选择'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '生产',
        abilitySubdomains: '采购',
        level: '4级',
        askFor: '通过与上游供应商的销售系统集成，实现协同供应链'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '生产',
        abilitySubdomains: '采购',
        level: '5级',
        askFor: '应通过大数据、云计算、机器学习等技术自优化供应商管理'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '生产',
        abilitySubdomains: '采购',
        level: '5级',
        askFor: '应实现企业与供应商在设计、生产、质量、库存、物流的自动协同，并实时监控采购环节的风险及变化，自主做出反馈和调整'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '生产',
        abilitySubdomains: '计划调度',
        level: '1级',
        askFor: '应基于销售订单和销售预测等信息，编制主生产计划'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '生产',
        abilitySubdomains: '计划调度',
        level: '1级',
        askFor: '应基于主生产计划进行调度排产，编制详细生产作业计划'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '生产',
        abilitySubdomains: '计划调度',
        level: '2级',
        askFor: '应基于销售订单和销售预测等信息，编制主生产计划'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '生产',
        abilitySubdomains: '计划调度',
        level: '2级',
        askFor: '应建立信息系统，基于生产数量、交期等约束条件自动生成主生产计划'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '生产',
        abilitySubdomains: '计划调度',
        level: '2级',
        askFor: '应基于安全库存、采购提前期、生产提前期等制约要素来实现物料需求计划的运算'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '生产',
        abilitySubdomains: '计划调度',
        level: '3级',
        askFor: '实现基于安全库存、采购提前期、生产提前期、制造过程数据等要素实现物料需求运算，形成最优的详细生产作业计划'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '生产',
        abilitySubdomains: '计划调度',
        level: '3级',
        askFor: '实时监控各生产环节的零件、部件、毛坯、半成品的投入和产出进度，实现系统自动预警和分析调度排产的异常情况（如：生产延时、产能不足），并支持人工方法对异常的调整'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '生产',
        abilitySubdomains: '计划调度',
        level: '4级',
        askFor: '基于协同透明的生产过程数据、生产调度算法和各类约束条件（工艺顺序、加工资源、工作时间等），实现高级排产与调度，处理生产过程中的波动和风险，实现优化的排产'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '生产',
        abilitySubdomains: '计划调度',
        level: '5级',
        askFor: '应建立基于智能算法并融合人工智能动态调整算法的新一代高级计划与高级排产系统'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '生产',
        abilitySubdomains: '计划调度',
        level: '5级',
        askFor: '应用大数据和人工智能，持续优化生产调度算法，实现动态实时的排产与调度，提前处理生产过程中的波动和风险，实现最优排产'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '生产',
        abilitySubdomains: '生产作业',
        level: '1级',
        askFor: '应有生产作业相关的标准化指导文件'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '生产',
        abilitySubdomains: '生产作业',
        level: '1级',
        askFor: '应采集生产过程中关键件、关键工艺信息以及过程信息'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '生产',
        abilitySubdomains: '生产作业',
        level: '2级',
        askFor: '应通过信息技术手段传输生产相关的图纸、工艺文件、作业指导书、配方等图文资料到各生产单元'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '生产',
        abilitySubdomains: '生产作业',
        level: '2级',
        askFor: '应实现生产过程关键物料、设备、人员等资源的数据采集，并上传到信息系统'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '生产',
        abilitySubdomains: '生产作业',
        level: '2级',
        askFor: '企业应在关键工序采用数字化质量检测设备，实现产品质量自动检测、报警和诊断分析'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '生产',
        abilitySubdomains: '生产作业',
        level: '2级',
        askFor: '应记录生产过程产品信息，每个批次产品实现生产过程追溯'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '生产',
        abilitySubdomains: '生产作业',
        level: '3级',
        askFor: '应根据在制品信息自动获取相关的图纸、工艺文件、作业指导书、配方等图文资料到各生产单元电子看板'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '生产',
        abilitySubdomains: '生产作业',
        level: '3级',
        askFor: '应集成生产作业、资源管理、工艺路线等关键业务数据，能并行实时可视化展示'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '生产',
        abilitySubdomains: '生产作业',
        level: '3级',
        askFor: '应在关键工位实现电子防呆防错管理'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '生产',
        abilitySubdomains: '生产作业',
        level: '3级',
        askFor: '应实现包含产品原料、质量特性、关键工序过程等信息的可追溯，并采用信息技术手段进行产品先期质量策划和失效模式分析'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '生产',
        abilitySubdomains: '生产作业',
        level: '3级',
        askFor: '应通过数字化检验设备及系统的集成，实现关键工序质量在线检测和在线分析，自动对检验结果判断和预警，实现检测数据共享至其他模块，并建立产品质量问题处置知识库'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '生产',
        abilitySubdomains: '生产作业',
        level: '4级',
        askFor: '应通过信息系统集成实现生产过程电子化作业指导，运行参数和生产指令可自动下发到数字化设备'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '生产',
        abilitySubdomains: '生产作业',
        level: '4级',
        askFor: '应实现生产作业数据的自动采集与在线分析，并能够优化生产作业工艺、工位布局、生产线布局等'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '生产',
        abilitySubdomains: '生产作业',
        level: '4级',
        askFor: '应实时收集产品原料、生产过程、客户使用的质量信息，实现产品质量的精确追溯，并通过数据分析和知识库的运用，进行产品的缺陷分析，提出改善方案'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '生产',
        abilitySubdomains: '生产作业',
        level: '4级',
        askFor: '应基于在线监测的质量数据，建立质量数据算法模型预测生产过程异常，实时驱动产线的预警'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '生产',
        abilitySubdomains: '生产作业',
        level: '5级',
        askFor: '基于云计算和大数据技术实现快速设备、工装、工艺切换，满足高度柔性化、个性化生产的需求'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '生产',
        abilitySubdomains: '生产作业',
        level: '5级',
        askFor: '应实现生产过程中异常预警和自动调整'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '生产',
        abilitySubdomains: '生产作业',
        level: '5级',
        askFor: '应实时收集产品全生命周期的质量信息，形成全过程产品质量履历'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '生产',
        abilitySubdomains: '生产作业',
        level: '5级',
        askFor: '应通过大数据、云计算、机器学习等技术对产品设计、工艺设计、质量方案等进行自优化并实施'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '生产',
        abilitySubdomains: '生产作业',
        level: '5级',
        askFor: '应基于大数据分析，进行产品质量异常预测，并自动修复和调校相关的生产参数'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '生产',
        abilitySubdomains: '设备管理',
        level: '1级',
        askFor: '应通过人工或手持仪器开展设备点巡检，辅助数据检测发现设备异常，并依据人工经验实现检修维护过程管理和故障消缺'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '生产',
        abilitySubdomains: '设备管理',
        level: '2级',
        askFor: '应通过信息技术手段实现对设备设施维护保养的预警，形成预防性的维护计划'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '生产',
        abilitySubdomains: '设备管理',
        level: '2级',
        askFor: '应采用预防性设备管理技术，制定设备维护周期'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '生产',
        abilitySubdomains: '设备管理',
        level: '2级',
        askFor: '应采用设备管理系统实现设备点巡检、润滑作业等日常维护工作的标准化'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '生产',
        abilitySubdomains: '设备管理',
        level: '3级',
        askFor: '应建立设备故障知识库'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '生产',
        abilitySubdomains: '设备管理',
        level: '3级',
        askFor: '应通过在线监测技术，开展远程诊断分析，实现设备状态的诊断分析'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '生产',
        abilitySubdomains: '设备管理',
        level: '3级',
        askFor: '应依据设备故障状态，自动生成备件目录、检修标准、检修人员等可执行工单，实现基于数据状态的检修维护闭环管理'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '生产',
        abilitySubdomains: '设备管理',
        level: '4级',
        askFor: '应基于设备状态的预测性分析，自动形成设备状态、维护计划、备件计划、检修标准等环节间匹配的检修维护策略优化，并实现设备运维生命周期管理'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '生产',
        abilitySubdomains: '设备管理',
        level: '5级',
        askFor: '应采用机器学习、神经网络等，实现设备状态预测模型的自学习、自适应'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '生产',
        abilitySubdomains: '仓储配送',
        level: '1级',
        askFor: '应制定仓储管理规范，实现出入库、盘点和安全库存管理'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '生产',
        abilitySubdomains: '仓储配送',
        level: '1级',
        askFor: '基于管理分类和认证规范实现仓储合理管理'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '生产',
        abilitySubdomains: '仓储配送',
        level: '1级',
        askFor: '应基于生产计划制定配送计划，实现原材料和中间产品定时定量配送'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '生产',
        abilitySubdomains: '仓储配送',
        level: '2级',
        askFor: '应基于条码、二维码、RFID等标识技术，实现货物的自动和半自动出入库管理'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '生产',
        abilitySubdomains: '仓储配送',
        level: '2级',
        askFor: '应建立仓储管理系统，实现货物库位分配、出入库和移库等管理'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '生产',
        abilitySubdomains: '仓储配送',
        level: '2级',
        askFor: '应基于生产单元物料消耗情况发起配送请求，并提示及时配送'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '生产',
        abilitySubdomains: '仓储配送',
        level: '3级',
        askFor: '应基于数字化仓储设备和信息系统集成，根据实际生产计划实现无人或少人化自动出入库管理'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '生产',
        abilitySubdomains: '仓储配送',
        level: '3级',
        askFor: '应采用射频遥控数据终端、声控或按灯拣货等手段进行入库和拣货'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '生产',
        abilitySubdomains: '仓储配送',
        level: '3级',
        askFor: '应通过数字化设备（AGV等）或配送人员和信息系统集成，实现关键件及时配送'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '生产',
        abilitySubdomains: '仓储配送',
        level: '4级',
        askFor: '应基于仓储配送系统与运输管理系统、企业资源管理系统、供应链管理系统和制造执行系统等集，形成仓储模型和配送模型，实现最小库存和方便快捷配送'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '生产',
        abilitySubdomains: '仓储配送',
        level: '4级',
        askFor: '应实现仓储和配送可视化管理，生产计划实现动态模拟拣货需求'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '生产',
        abilitySubdomains: '仓储配送',
        level: '4级',
        askFor: '应基于生产线实际生产计划实时拉动物料配送'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '生产',
        abilitySubdomains: '仓储配送',
        level: '5级',
        askFor: '应基于实际生产实现全流程自主实时分拣和配送'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '生产',
        abilitySubdomains: '仓储配送',
        level: '5级',
        askFor: '应运用大数据和云计算技术实现与计划和排产、生产作业、供应链集成优化，实现最优库存或即时供货'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '生产',
        abilitySubdomains: '安全环保',
        level: '1级',
        askFor: '应建立企业安全管理机制和环保管理机制，具备安全和环保操作规程'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '生产',
        abilitySubdomains: '安全环保',
        level: '2级',
        askFor: '应通过信息技术手段实现员工职业健康和安全作业管理'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '生产',
        abilitySubdomains: '安全环保',
        level: '2级',
        askFor: '应采用信息技术手段进行环保管理，环保数据可采集并记录'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '生产',
        abilitySubdomains: '安全环保',
        level: '3级',
        askFor: '应建立安全培训、风险管理等知识库；在现场作业端应用定位跟踪等方法，强化现场安全管控'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '生产',
        abilitySubdomains: '安全环保',
        level: '3级',
        askFor: '应实现环保数据的全面采集，实时监控及报警，并开展可视化分析。信息化系统覆盖从清洁生产到末端治理的全过程'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '生产',
        abilitySubdomains: '安全环保',
        level: '3级',
        askFor: '宜建立应急指挥中心，基于知识库自动给出管理建议，完善应急预案，缩短突发事件应急响应时间'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '生产',
        abilitySubdomains: '安全环保',
        level: '4级',
        askFor: '应基于安全作业、风险管控、设备维修作业等数据的分析，实现危险源的动态识别、评审和治理'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '生产',
        abilitySubdomains: '安全环保',
        level: '4级',
        askFor: '应实现环保监测数据和生产作业数据的集成应用，建立数据分析模型，开展排放分析及预测预警'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '生产',
        abilitySubdomains: '安全环保',
        level: '5级',
        askFor: '应综合应用知识库及大数据分析技术，实现生产安全一体化管理'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '生产',
        abilitySubdomains: '安全环保',
        level: '5级',
        askFor: '应实现环保、生产、设备等数据的全面实时监控，应用数据分析模型，预测生产排放并自动提供生产优化方案并予以执行'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '生产',
        abilitySubdomains: '能源管理',
        level: '1级',
        askFor: '应建立企业能源管理制度，开展主要能源的数据采集和计量'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '生产',
        abilitySubdomains: '能源管理',
        level: '2级',
        askFor: '应通过信息技术手段,对主要能源的产生、消耗点开展数据采集和计量'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '生产',
        abilitySubdomains: '能源管理',
        level: '2级',
        askFor: '应建立水电气等重点能源消耗的动态监控和计量'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '生产',
        abilitySubdomains: '能源管理',
        level: '2级',
        askFor: '应实现重点高能耗设备、系统等的动态运行监控'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '生产',
        abilitySubdomains: '能源管理',
        level: '2级',
        askFor: '应适时对有节能优化需求的设备和系统开展实时计量，并基于计量结果进行节能改造'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '生产',
        abilitySubdomains: '能源管理',
        level: '3级',
        askFor: '应利用能耗数据来统计生产运行时间、停机频率和停机时间，以分析设备的可用性，并输出能源数据报表'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '生产',
        abilitySubdomains: '能源管理',
        level: '3级',
        askFor: '应实现能源数据与其他系统数据共享，为业务管理系统和决策支持系统提供能源数据'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '生产',
        abilitySubdomains: '能源管理',
        level: '4级',
        askFor: '应建立节能模型，实现能流的精细化和可视化管理'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '生产',
        abilitySubdomains: '能源管理',
        level: '4级',
        askFor: '应根据能效评估结果及时对空压机、锅炉、工业窑炉等进行技术改造和更新'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '生产',
        abilitySubdomains: '能源管理',
        level: '5级',
        askFor: '应实现能源的在线动态预测和平衡，并指导生产'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '物流',
        abilitySubdomains: '物流',
        level: '1级',
        askFor: '应根据运输订单和经验，制定运输计划并配置调度'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '物流',
        abilitySubdomains: '物流',
        level: '1级',
        askFor: '应对车辆和驾驶员进行统一管理'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '物流',
        abilitySubdomains: '物流',
        level: '1级',
        askFor: '对物流信息进行简单跟踪'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '物流',
        abilitySubdomains: '物流',
        level: '2级',
        askFor: '应通过运输管理系统实现订单、运输计划、调度等合理管理'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '物流',
        abilitySubdomains: '物流',
        level: '2级',
        askFor: '应通过电话、短信等形式反馈配送运输关键节点信息给管理人员'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '物流',
        abilitySubdomains: '物流',
        level: '2级',
        askFor: '应通过信息系统，实现运力资源管理'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '物流',
        abilitySubdomains: '物流',
        level: '3级',
        askFor: '应实现配送运输关键节点信息跟踪，并通过信息系统将信息反馈给客户'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '物流',
        abilitySubdomains: '物流',
        level: '3级',
        askFor: '应通过运输管理系统实现拼单、拆单等功能'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '物流',
        abilitySubdomains: '物流',
        level: '4级',
        askFor: '应实现生产、仓储配送（管道运输）、运输管理多系统的集成优化'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '物流',
        abilitySubdomains: '物流',
        level: '4级',
        askFor: '能够实现配送运输全程信息跟踪，对轨迹异常进行报警'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '物流',
        abilitySubdomains: '物流',
        level: '4级',
        askFor: '应基于模型优化引擎实现配送运输线路优化管理'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '物流',
        abilitySubdomains: '物流',
        level: '5级',
        askFor: '应基于核心分拣算法和智能物流算法优化满足个性化、柔性化生产实时配送需求'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '物流',
        abilitySubdomains: '物流',
        level: '5级',
        askFor: '应通过物联网和数据模型分析，实现物、车、路、用户的最佳方案自主匹配'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '销售',
        abilitySubdomains: '销售',
        level: '1级',
        askFor: '应基于市场信息和销售历史数据（区域、型号、产品定位、数量等），通过人工方式进行市场预测，制定销售计划'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '销售',
        abilitySubdomains: '销售',
        level: '1级',
        askFor: '应对销售订单、销售合同、分销商、客户等信息进行统计和管理'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '销售',
        abilitySubdomains: '销售',
        level: '2级',
        askFor: '应通过信息技术手段实现销售计划、订单、销售历史数据的管理'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '销售',
        abilitySubdomains: '销售',
        level: '2级',
        askFor: '应通过信息技术手段实现分销商、客户静态信息和动态信息的管理，进行客户关系维护'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '销售',
        abilitySubdomains: '销售',
        level: '3级',
        askFor: '应根据数据模型进行市场预测，生成销售计划'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '销售',
        abilitySubdomains: '销售',
        level: '3级',
        askFor: '应与采购、生产、物流环节集成，实现客户实际需求拉动采购、生产和物流计划，通过与仓储管理系统集成，整合销售和产品仓储业务'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '销售',
        abilitySubdomains: '销售',
        level: '4级',
        askFor: '应通过对客户信息的挖掘、分析，优化客户需求预测，制定精准的销售计划'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '销售',
        abilitySubdomains: '销售',
        level: '4级',
        askFor: '企业应综合运用各种渠道，实现线上线下协同，统一管理所有销售方式，并与企业级信息系统集成，实现根据客户需求变化动态调整设计、采购、生产、物流方案'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '销售',
        abilitySubdomains: '销售',
        level: '5级',
        askFor: '应用大数据、云计算和机器学习等技术，通过数据挖掘、建模分析，全方位分析客户特征，实现满足客户需求的精准营销，并挖掘客户新的需求，促进产品创新'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '销售',
        abilitySubdomains: '销售',
        level: '5级',
        askFor: '宜通过虚拟现实技术，满足销售过程中客户对产品使用场景及使用方式的虚拟体验'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '销售',
        abilitySubdomains: '销售',
        level: '5级',
        askFor: '应实现产品从接单、答复交期、发货、回款全过程的自动管理'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '服务',
        abilitySubdomains: '客户服务',
        level: '1级',
        askFor: '应建立规范化客户服务制度'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '服务',
        abilitySubdomains: '客户服务',
        level: '1级',
        askFor: '应对客户服务及信息进行统计，并反馈给设计、生产、销售部门'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '服务',
        abilitySubdomains: '客户服务',
        level: '2级',
        askFor: '建立规范化服务体系，设立客户反馈渠道，建立服务满意度评价制度，实现客户服务闭环管理'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '服务',
        abilitySubdomains: '客户服务',
        level: '2级',
        askFor: '通过信息系统，实现客户服务管理，对客户服务信息进行统计，反馈给市场等相关部门，维护客户关系'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '服务',
        abilitySubdomains: '客户服务',
        level: '3级',
        askFor: '应通过云平台或移动客户端等实时提供在线客服'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '服务',
        abilitySubdomains: '客户服务',
        level: '3级',
        askFor: '应具备客户服务信息数据库及客户服务知识库，实现与客户关系管理系统的集成'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '服务',
        abilitySubdomains: '客户服务',
        level: '4级',
        askFor: '应实现面向客户的精细化知识管理，可通过移动客户端提供客户服务'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '服务',
        abilitySubdomains: '客户服务',
        level: '4级',
        askFor: '应建立客户服务数据模型，实现精准客服'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '服务',
        abilitySubdomains: '客户服务',
        level: '5级',
        askFor: '应基于大数据和人工智能，通过智能客服机器人实现自然语言交互、智能客户管理，并通过多维度的数据挖掘，进行自学习、自优化，实现智能客服'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '服务',
        abilitySubdomains: '产品服务',
        level: '1级',
        askFor: '应建立规范化产品服务制度，可提供现场运维及远程运维指导服务'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '服务',
        abilitySubdomains: '产品服务',
        level: '1级',
        askFor: '应对产品故障信息进行统计，并反馈给设计、生产、销售部门'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '服务',
        abilitySubdomains: '产品服务',
        level: '2级',
        askFor: '应采用信息技术手段对产品故障信息进行统计，并把统计结果反馈给相关的设计、生产部门，进行产品优化'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '服务',
        abilitySubdomains: '产品服务',
        level: '2级',
        askFor: '应具备产品故障知识库和维护方法知识库，服务人员可根据手册进行现场运维和远程运维指导服务'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '服务',
        abilitySubdomains: '产品服务',
        level: '3级',
        askFor: '产品应具有数据采集、存储、网络通信等功能'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '服务',
        abilitySubdomains: '产品服务',
        level: '3级',
        askFor: '产品服务系统应有产品运行信息管理、维修计划和执行管理、维修物料及寿命管理等功能，并与设计、生产等系统集成'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '服务',
        abilitySubdomains: '产品服务',
        level: '4级',
        askFor: '产品应有数据传输、故障预警、预测性维护等功能'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '服务',
        abilitySubdomains: '产品服务',
        level: '4级',
        askFor: '应建立远程运维服务平台，提供远程监测、故障预警、预测性维护等服务'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '服务',
        abilitySubdomains: '产品服务',
        level: '4级',
        askFor: '远程运维平台应对装备/产品上传的运行参数、维保、用户使用等数据进行挖掘分析，并与产品全生命周期管理系统、产品研发管理系统集成，实现产品性能优化与创新'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '服务',
        abilitySubdomains: '产品服务',
        level: '5级',
        askFor: '产品应具有自感知、自适应、自优化等功能'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '服务',
        abilitySubdomains: '产品服务',
        level: '5级',
        askFor: '应用物联网技术和增强现实/虚拟现实技术实现远程智能运维'
      },
      {
        seen: false,
        capacityFactor: '制造',
        powerDpomain: '服务',
        abilitySubdomains: '产品服务',
        level: '5级',
        askFor: '应通过云平台，整合全球服务资源，实现协同服务'
      }
      ],
      spanArr: [],//用于存放每一行记录的合并数
      url: {}
    }
  },
  mounted () {
    this.getSpanArr(this.tableData)
  },
  methods: {
    getSpanArr (data) {
      console.log(data)
      // data就是我们从后台拿到的数据
      for (var i = 0; i < data.length; i++) {
        if (i === 0) {
          this.spanArr.push(1)
          this.pos = 0
        } else {
          // 判断当前元素与上一个元素是否相同
          if (data[i].capacityFactor === data[i - 1].capacityFactor && data[i].powerDpomain === data[i - 1].powerDpomain && data[i].abilitySubdomains === data[i - 1].abilitySubdomains) {
            this.spanArr[this.pos] += 1
            this.spanArr.push(0)
          } else {
            this.spanArr.push(1)
            this.pos = i
          }
        }
        // console.log(this.spanArr)
      }
    },
    objectSpanMethod ({ row, column, rowIndex, columnIndex }) {
      console.log(row, column)
      if (columnIndex === 1 || columnIndex === 2 || columnIndex === 3) {
        if (this.spanArr[rowIndex]) {
          return {
            // rowspan 合并的行数
            rowspan: this.spanArr[rowIndex],
            colspan: 1
          }
        } else {
          return {
            rowspan: 0,
            colspan: 0
          }
        }
      }
    },
    handleEdit (index, row) {
      console.log(index, row)
    },
    loseFcous (index, row) {
      row.seen = false
    },
    cellClick (row) {
      row.seen = true
    },
    handleOk () {

    }
  }
}
</script>

<style>
.el-main {
  text-align: left !important;
  padding: 0 !important;
}
h2 {
  font-size: 24px;
}
.el-table td {
  padding: 0 !important;
}
.el-button {
  margin: 3px 5px !important;
}
</style>
