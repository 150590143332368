<template>
  <!-- 智能制造成熟度评价报告 -->
  <div id="appraisalReport">
    <div class="subject"
         id="pdfDom">
      <el-row style="text-align:center;">
        <h1><span>{{this.Name}}</span>智能制造能力成熟度评价报告</h1>
      </el-row>
      <el-row style="margin-top:20px">
        <h3>一、智能制造成熟度</h3>
      </el-row>
      <el-row style="font-size:18px">
        <span>{{this.Name}}</span><span>智能制造成熟度整体得分：</span> <span style="font-size:26px; color:#ff0000">{{this.num}}</span>（分）&nbsp;&nbsp; <span style="font-size:20px; color:#ff0000">{{this.level}}</span>
      </el-row>
      <el-row style="margin-top:20px">
        <el-col :span="8">
          <el-table :data="tableData"
                    :row-class-name="tableRowClassName"
                    border
                    style="width:342px;font-size:18px">
            <el-table-column prop="date"
                             align="center"
                             label="对应分值"
                             width="160">
            </el-table-column>
            <el-table-column prop="name"
                             align="center"
                             label="等级"
                             width="180">
            </el-table-column>
          </el-table>
        </el-col>
        <el-col :span="16">
          <div style="margin-top:100px; color:#ff0000;font-size:18px">
            {{this.remark}}
          </div>
        </el-col>
      </el-row>
      <h3>二、各要素成熟度得分状况</h3>
      <div v-if="this.num > 0.8">
        <el-table :data="tableData1"
                  border
                  :span-method="objectSpanMethod"
                  style="width: 100%; margin-bottom:10px">
          <el-table-column width="60"
                           align="center"
                           type="index">
          </el-table-column>
          <el-table-column label="能力要素"
                           align="center">
            <template slot-scope="scope">
              <span style="margin-left: 10px">{{ scope.row.optionContent }}</span>
            </template>
          </el-table-column>
          <el-table-column label="能力域"
                           align="center">
            <template slot-scope="scope">
              <span style="margin-left: 10px">{{ scope.row.powerDpomain }}</span>
            </template>
          </el-table-column>
          <el-table-column label="能力子域"
                           align="center">
            <template slot-scope="scope">
              <span style="margin-left: 10px">{{ scope.row.abilitySubdomains }}</span>
            </template>
          </el-table-column>
          <el-table-column label="权重"
                           header-align="center">
            <el-table-column label="能力要素"
                             align="center">
              <template slot-scope="scope">
                <span style="margin-left: 10px">{{ scope.row.factor }}</span>
              </template>
            </el-table-column>
            <el-table-column label="能力域"
                             align="center">
              <template slot-scope="scope">
                <span style="margin-left: 10px">{{ scope.row.dpomain }}</span>
              </template>
            </el-table-column>
            <el-table-column label="能力子域"
                             align="center">
              <template slot-scope="scope">
                <span style="margin-left: 10px">{{ scope.row.realWeight }}</span>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column label="得分"
                           header-align="center">
            <el-table-column label="能力子域"
                             align="center">
              <template slot-scope="scope">
                <span style="margin-left: 10px">{{ scope.row.subdomains1 }}</span>
              </template>
            </el-table-column>
            <el-table-column label="能力域"
                             align="center">
              <template slot-scope="scope">
                <span style="margin-left: 10px">{{ scope.row.dpomain1 }}</span>
              </template>
            </el-table-column>
            <el-table-column label="能力要素"
                             align="center">
              <template slot-scope="scope">
                <span style="margin-left: 10px">{{ scope.row.reactiveScore }}</span>
              </template>
            </el-table-column>
            <el-table-column label="智能制造"
                             align="center">
              <template slot-scope="scope">
                <span style="margin-left: 10px; color:#ff0000">{{ scope.row.manufacturing }}</span>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column label="成熟度级别"
                           align="center">
            <template slot-scope="scope">
              <span style="margin-left: 10px;color:#ff0000">{{ scope.row.maturityLevel }}</span>
            </template>
          </el-table-column>
        </el-table>
        <el-row style="margin-top: 20px">
          <el-col :span="12"
                  style="padding-right:20px;">
            <div class="backdrop">
              <el-row>
                <el-col :span="12">
                  <dimensionScores id="dimensionScores"
                                   :text="text"
                                   :indicator="indicator"
                                   :value="dimensionScoresValue" />
                </el-col>
                <el-col :span="12"
                        style="margin-top:50px">
                  <el-row>
                    <el-col :span="8"
                            v-for="(item,index) in legendList"
                            :key="index"
                            style="height: 30px;line-height: 30px;text-align: center;">
                      {{item}}
                    </el-col>
                  </el-row>
                  <el-row v-for="(item,index) in valueData"
                          :key="index"
                          style="height: 30px;line-height: 30px;text-align: center;">
                    <el-col :span="8">
                      {{item.name}}
                    </el-col>
                    <el-col :span="8">{{item.value}}</el-col>
                    <el-col :span="8">{{item.factor}}</el-col>
                  </el-row>
                </el-col>
              </el-row>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="backdrop">
              <el-row>
                <el-col :span="12">
                  <dimensionScores id="dimensionScores1"
                                   :text="text1"
                                   :indicator="indicator1"
                                   :value="dimensionScoresValue1" />
                </el-col>
                <el-col :span="12"
                        style="margin-top:50px">
                  <el-row>
                    <el-col :span="8"
                            v-for="(item,index) in legendList"
                            :key="index"
                            style="height: 30px;line-height: 30px;text-align: center;">
                      {{item}}
                    </el-col>
                  </el-row>
                  <el-row v-for="(item,index) in valueData1"
                          :key="index"
                          style="height: 30px;line-height: 30px;text-align: center;">
                    <el-col :span="8">
                      {{item.name}}
                    </el-col>
                    <el-col :span="8">{{item.value}}</el-col>
                    <el-col :span="8">{{item.dpomain}}</el-col>
                  </el-row>
                </el-col>
              </el-row>
            </div>
          </el-col>
        </el-row>
      </div>
      <div v-if="this.num < 0.8"
           style="font-size:20px">略~~</div>
      <h3>三、<span>{{this.Name}}</span>智能制造成熟度水平评价建议</h3>
      <div v-if="this.num > 0.8">
        <el-row style="line-height:36px;">
          <p style="font-size: 18px;text-indent:2em">a)&nbsp;<span style="color:#ff0000">人员方面</span>：{{this.commentAll.人员方面}}</p>
          <p style="font-size: 18px;text-indent:2em">b)&nbsp;<span style="color:#ff0000">技术方面</span>：{{this.commentAll.技术方面}}</p>
          <p style="font-size: 18px;text-indent:2em">c)&nbsp;<span style="color:#ff0000">资源方面</span>：{{this.commentAll.资源方面}}</p>
          <p style="font-size: 18px;text-indent:2em">d)&nbsp;<span style="color:#ff0000">制造方面</span>：{{this.commentAll.制造方面}}</p>
        </el-row>
      </div>
      <div v-if="this.num < 0.8"
           style="font-size:20px">略~~</div>
      <el-row style="margin-top:80px;line-height:30px;margin-bottom:30px">
        <p>本次测评已结束，感谢您的使用! <el-image style="width: 50px; height: 50px;vertical-align: middle;"
                    fit="contain"
                    :src="require('@/assets/smiling.jpeg')"></el-image>
        </p>
        <p>此报告仅依据本次填报资料进行智能制造成熟度评价，仅供参考。</p>
        <p>如需更详实评价报告或专业辅导服务，请联系我们。</p>
        <p>电话：<i style="color:#ff0000;padding-right:36px">13632960300</i><i style="color:#ff0000;">冷先生</i></p>
        <p>邮箱：<i style="color:#ff0000">jason.leng@szyanjia.com</i></p>
      </el-row>
    </div>
    <div class="subject">
      <el-row style="margin:20px 0"
              v-if="this.num > 0.8">
        <el-button type="primary"
                   @click="getPdf('#pdfDom')">下载报告</el-button>
      </el-row>
    </div>
    <el-dialog :visible.sync="dialogFormVisible"
               width="60%">
      <div slot="title"
           style="overflow: hidden;">
        <div style="height:28px"></div>
      </div>
      <span>
        <el-form :model="form"
                 ref="form">
          <el-row style="margin-bottom:20px; text-align:center; font-size:24px; font-weight:600">延嘉智能制造成熟度水平评价平台使用满意度调查</el-row>
          <p style="font-size: 18px">尊敬的客户您好，为了不断提升您的使用感受，在今后为您提供更优质高效的服务，我们现进行客户体验满意度调查，我们将惠存并分析您的反馈信息。请您在百忙之中抽出宝贵的3min时间填写问卷，并将您最真实的想法告诉我们。您的建议是我们奋进的动力，我们将虚心听取并及时改进。非常感谢您的支持与配合！</p>
          <div class="list">
            <el-row>
              <p>1、你是怎么了解到本平台的？</p>
              <template>
                <el-radio v-model="form.radio"
                          v-for="(item, index) in firstQuestion"
                          :key="index"
                          :value="item.choose"
                          :label="item.choose">{{item.chooseContent}}</el-radio>
              </template>
            </el-row>
            <el-row style="margin-top:20px">
              <p>2、延嘉智能制造成熟度水平评价平台的整体网页给您的愉悦程度如何？</p>
              <template>
                <el-radio v-model="form.radio1"
                          v-for="(item, index) in secondQuestion"
                          :key="index"
                          :value="item.choose"
                          :label="item.choose">{{item.chooseContent}}</el-radio>
              </template>
            </el-row>
            <el-row style="margin-top:20px">
              <p>3、请评价您对延嘉智能制造成熟度水平评价平台的操作步骤的满意程度。</p>
              <template>
                <el-radio v-model="form.radio2"
                          v-for="(item, index) in  thirdQuestion"
                          :key="index"
                          :value="item.choose"
                          :label="item.choose">{{item.chooseContent}}</el-radio>
              </template>
            </el-row>
            <el-row style="margin-top:20px">
              <p>4、延嘉智能制造成熟度水平评价平台的成熟度要求是否全面？</p>
              <template>
                <el-radio v-model="form.radio3"
                          v-for="(item, index) in  fourthQuestion"
                          :key="index"
                          :value="item.choose"
                          :label="item.choose">{{item.chooseContent}}</el-radio>
              </template>
            </el-row>
            <el-row style="margin-top:20px">
              <p>5、延嘉智能制造成熟度水平评价平台对企业评价全面性如何？</p>
              <template>
                <el-radio v-model="form.radio4"
                          v-for="(item, index) in  fifthQroblem"
                          :key="index"
                          :value="item.choose"
                          :label="item.choose">{{item.chooseContent}}</el-radio>
              </template>
            </el-row>
            <el-row style="margin-top:20px">
              <p>6、请问您对延嘉智能制造成熟度水平评价平台的综合评价及满意度如何？</p>
              <template>
                <el-radio v-model="form.radio5"
                          v-for="(item, index) in  sixthQuestion"
                          :key="index"
                          :value="item.choose"
                          :label="item.choose">{{item.chooseContent}}</el-radio>
              </template>
            </el-row>
            <el-row style="margin-top:20px">
              <p>7、请问您认为和延嘉进一步沟通合作的机会度。</p>
              <template>
                <el-radio v-model="form.radio6"
                          v-for="(item, index) in  seventhQuestion"
                          :key="index"
                          :value="item.choose"
                          :label="item.choose">{{item.chooseContent}}</el-radio>
              </template>
            </el-row>
            <el-row style="margin-top:20px">
              <p>8、您会向你的朋友推荐延嘉智能制造成熟度水平评价平台？</p>
              <template>
                <el-radio v-model="form.radio7"
                          v-for="(item, index) in  eighthQuestion"
                          :key="index"
                          :value="item.choose"
                          :label="item.choose">{{item.chooseContent}}</el-radio>
              </template>
            </el-row>
            <el-row style="margin-top:20px"
                    v-if="this.form.radio7 == 2">
              <p>9、您不愿意向他人推荐的理由是？</p>
              <el-form-item label-height="100px">
                <el-input type="textarea"
                          v-model="form.advice1"
                          :rows="3"
                          maxlength="2000"></el-input>
              </el-form-item>
            </el-row>
            <el-row style="margin-top:10px">
              <p>如您对延嘉智能制造成熟度水平评价平台尚有其他不满意的地方，请告诉我们您最真实的想法，我们虚心听取您的宝贵建议</p>
              <el-form-item label-height="100px">
                <el-input type="textarea"
                          v-model="form.advice2"
                          :rows="6"
                          maxlength="2000"></el-input>
              </el-form-item>
            </el-row>
          </div>
        </el-form>
      </span>
      <div slot="footer"
           class="dialog-footer">
        <el-button type="primary"
                   @click="handleOk('form')">提 交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import dimensionScores from '@views/public/components/dimensionScores'
import { getAction, postAction } from '@/api/manage'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import Vue from 'vue'
export default {
  name: 'appraisalReport',
  components: {
    dimensionScores
  },
  data () {
    return {
      form: {
        radio: '',
        radio1: '',
        radio2: '',
        radio3: '',
        radio4: '',
        radio5: '',
        radio6: '',
        radio7: '',
        advice1: '',
        advice2: ''
      },
      firstQuestion: [
        {
          propertyName: "radio",
          title: "你是怎么了解到本平台的？",
          choose: "1",
          chooseContent: "别人介绍"
        },
        {
          propertyName: "radio",
          title: "你是怎么了解到本平台的？",
          choose: "2",
          chooseContent: "自己网上搜索"
        }
      ],
      secondQuestion: [
        {
          propertyName: "radio1",
          title: "延嘉智能制造成熟度水平评价平台的整体网页给您的愉悦程度如何？",
          choose: "1",
          chooseContent: "不满意"
        },
        {
          propertyName: "radio1",
          title: "延嘉智能制造成熟度水平评价平台的整体网页给您的愉悦程度如何？",
          choose: "2",
          chooseContent: "一般"
        },
        {
          propertyName: "radio1",
          title: "延嘉智能制造成熟度水平评价平台的整体网页给您的愉悦程度如何？",
          choose: "3",
          chooseContent: "非常好"
        }
      ],
      thirdQuestion: [
        {
          propertyName: "radio2",
          title: "请评价您对延嘉智能制造成熟度水平评价平台的操作步骤的满意程度。",
          choose: "1",
          chooseContent: "不满意"
        },
        {
          propertyName: "radio2",
          title: "请评价您对延嘉智能制造成熟度水平评价平台的操作步骤的满意程度。",
          choose: "2",
          chooseContent: "一般"
        },
        {
          propertyName: "radio2",
          title: "请评价您对延嘉智能制造成熟度水平评价平台的操作步骤的满意程度。",
          choose: "3",
          chooseContent: "非常好"
        }
      ],
      fourthQuestion: [
        {
          propertyName: "radio3",
          title: "延嘉智能制造成熟度水平评价平台的成熟度要求是否全面？",
          choose: "1",
          chooseContent: "很全面"
        },
        {
          propertyName: "radio3",
          title: "延嘉智能制造成熟度水平评价平台的成熟度要求是否全面？",
          choose: "2",
          chooseContent: "一般"
        },
        {
          propertyName: "radio3",
          title: "延嘉智能制造成熟度水平评价平台的成熟度要求是否全面？",
          choose: "3",
          chooseContent: "不够全面"
        }
      ],
      fifthQroblem: [
        {
          propertyName: "radio4",
          title: "延嘉智能制造成熟度水平评价平台对企业评价全面性如何？",
          choose: "1",
          chooseContent: "很全面"
        },
        {
          propertyName: "radio4",
          title: "延嘉智能制造成熟度水平评价平台对企业评价全面性如何？",
          choose: "2",
          chooseContent: "一般"
        },
        {
          propertyName: "radio4",
          title: "延嘉智能制造成熟度水平评价平台对企业评价全面性如何？",
          choose: "3",
          chooseContent: "不够全面"
        }
      ],
      sixthQuestion: [
        {
          propertyName: "radio5",
          title: "请问您对延嘉智能制造成熟度水平评价平台的综合评价及满意度如何？",
          choose: "1",
          chooseContent: "不满意"
        },
        {
          propertyName: "radio5",
          title: "请问您对延嘉智能制造成熟度水平评价平台的综合评价及满意度如何？",
          choose: "2",
          chooseContent: "一般"
        },
        {
          propertyName: "radio5",
          title: "请问您对延嘉智能制造成熟度水平评价平台的综合评价及满意度如何？",
          choose: "3",
          chooseContent: "非常好"
        }
      ],
      seventhQuestion: [
        {
          propertyName: "radio6",
          title: "请问您认为和延嘉进一步沟通合作的机会度。",
          choose: "1",
          chooseContent: "40%以内"
        },
        {
          propertyName: "radio6",
          title: "请问您认为和延嘉进一步沟通合作的机会度。",
          choose: "2",
          chooseContent: "40%~70%"
        },
        {
          propertyName: "radio6",
          title: "请问您认为和延嘉进一步沟通合作的机会度。",
          choose: "3",
          chooseContent: "70%以上"
        }
      ],
      eighthQuestion: [
        {
          propertyName: "radio7",
          title: "您会向你的朋友推荐延嘉智能制造成熟度水平评价平台？",
          choose: "1",
          chooseContent: "会"
        },
        {
          propertyName: "radio7",
          title: "您会向你的朋友推荐延嘉智能制造成熟度水平评价平台？",
          choose: "2",
          chooseContent: "不会"
        }
      ],
      text: '要素维度得分',
      text1: '能力域维度得分',
      legendList: ['项目', '得分', '权重'],
      nameAll: [],
      name: '制造',
      indicator: [],
      indicator1: [],
      dimensionScoresValue: [],
      dimensionScoresValue1: [],
      valueData: [],
      valueData1: [],
      Name: '',
      num: '',
      level: '',
      manufacturing: [],
      spanArr: [],//用于存放每一行记录的合并数
      spanCodeArr: [],
      spanData: [],
      spanDataAll: [],
      pos: '',
      codePos: '',
      abPos: '',
      maPos: '',
      matPos: '',
      masterId: '',
      username: '',
      factor: [],
      dpomain: [],
      realWeight: [],
      tableData1: [],
      commentAll: [],
      remark: '',
      htmlTitle: '成熟度评价报告',
      tableData: [{
        date: '4.8≤X<5',
        name: '5级 引领级'
      }, {
        date: '3.8≤X<4.8',
        name: '4级 优化级'
      }, {
        date: '2.8≤X<3.8',
        name: '3级 集成级'
      }, {
        date: '1.8≤X<2.8',
        name: '2级 规范级'
      }, {
        date: '0.8≤X<1.8',
        name: '1级 规划级'
      }],
      url: {
        getSelf: '/enterprise/getSelf', // 获取用户信息
        historyRecordScore: '/recordMaster/history_record_score', // 获取得分
        maturityModel: '/recordMaster/maturity_model', // 图表
        scoreDescription: '/recordMaster/score_description', // 获取评语
        enterpriseAdvice: '/enterpriseAdvice' // 提交建议
      },
      isFixed: 0,
      dialogFormVisible: false
    }
  },
  created () {
    let that = this
    this.masterId = this.$route.query.masterId
    this.username = this.$route.query.username
    window.onscroll = function () {
      //变量scrollTop是滚动条滚动时，距离顶部的距离
      var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      //变量windowHeight是可视区的高度
      var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
      //变量scrollHeight是滚动条的总高度
      var scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
      //滚动条到底部的条件
      if (Math.ceil(scrollTop + windowHeight) == scrollHeight) {
        that.isFixed += 1
        if (that.isFixed == 1) {
          that.dialogFormVisible = true
        }
      }
    }
    // 获取单人总分及级别
    getAction(this.url.historyRecordScore, { masterId: this.masterId, username: this.username, type: 1 }).then(res => {
      if (res.success) {
        let tableData = []
        let arrs = null
        res.result.records.map(item => {
          this.manufacturing.push(item.reactiveScore)
          item.children.map((items, indexs) => {
            items.children.map((itemss, indexss) => {
              let str = Number(itemss.realWeight.toFixed(2).toString().split(".")[1])
              str += "%"
              let arr = Number(items.realWeight.toFixed(2).toString().split(".")[1])
              arr += "%"
              let strs = Number(item.realWeight.toFixed(2).toString().split(".")[1])
              strs += "%"
              itemss.children.map(itemes => {
                if (itemes.optionContent == '1级') {
                  itemss.level1 = itemes.reactiveScore.toFixed(1)
                }
                if (itemes.optionContent == '2级') {
                  itemss.level2 = itemes.reactiveScore.toFixed(1)
                }
                if (itemes.optionContent == '3级') {
                  itemss.level3 = itemes.reactiveScore.toFixed(1)
                }
                if (itemes.optionContent == '4级') {
                  itemss.level4 = itemes.reactiveScore.toFixed(1)
                }
                if (itemes.optionContent == '5级') {
                  itemss.level5 = itemes.reactiveScore.toFixed(1)
                }
              })
              let resData = null
              if (indexss === 0 && indexs === 0) {
                let sum = 0
                for (let i = 0; i < this.manufacturing.length; i++) {
                  sum += Number(this.manufacturing[i])
                }
                arrs = sum / this.manufacturing.length
                resData = {
                  isHidden: itemss.isHidden,
                  optionContent: item.optionContent,
                  powerDpomain: items.optionContent,
                  abilitySubdomains: itemss.optionContent,
                  realWeight: str,
                  dpomain: arr,
                  factor: strs,
                  reactiveScore: item.reactiveScore.toFixed(1),
                  dpomain1: items.reactiveScore.toFixed(1),
                  subdomains1: itemss.reactiveScore.toFixed(1)
                }
              } else {
                resData = {
                  isHidden: itemss.isHidden,
                  optionContent: item.optionContent,
                  powerDpomain: items.optionContent,
                  abilitySubdomains: itemss.optionContent,
                  realWeight: str,
                  dpomain: indexss >= 1 || indexs >= 5 ? "" : arr,
                  factor: indexss >= 1 || indexs >= 1 ? "" : strs,
                  dpomain1: indexss >= 1 || indexs >= 5 ? "" : items.reactiveScore.toFixed(1),
                  subdomains1: itemss.reactiveScore.toFixed(1)
                }
              }
              tableData.push(resData)
            })
          })
        })
        tableData = tableData.filter(item => {
          return item.isHidden
        })
        tableData[0].manufacturing = arrs.toFixed(2)
        let num = Number(tableData[0].manufacturing)
        this.num = num
        this.level = num < 0.8 ? '不参与评级' : num < 1.8 ? '规划级' : num < 2.8 ? '规范级' : num < 3.8 ? '集成级' : num < 4.8 ? '优化级' : '引领级'
        if (this.num < 0.8) {
          this.remark = '企业不具备实施智能制造基础和条件，整体水平太低'
        }
      } else {
        this.$message.error(res.message)
        if (res.message == '无法获得企业信息，请登记企业信息后再重试') {
          this.$router.push({ path: '/' })
          Vue.ls.remove(ACCESS_TOKEN)
          window.location.reload()
        }
      }
    })
    // 获取用户信息
    getAction(this.url.getSelf).then(res => {
      if (res.success) {
        this.Name = res.result.enterpriseName
      } else {
        this.$message.error(res.message)
        if (res.message == '无法获得企业信息，请登记企业信息后再重试') {
          this.$router.push({ path: '/' })
          Vue.ls.remove(ACCESS_TOKEN)
          window.location.reload()
        }
      }
    })
    // 获取评语
    getAction(this.url.scoreDescription).then(res => {
      if (res.success) {
        this.commentAll = res.result
      } else {
        this.$message.error(res.message)
        if (res.message == '无法获得企业信息，请登记企业信息后再重试') {
          this.$router.push({ path: '/' })
          Vue.ls.remove(ACCESS_TOKEN)
          window.location.reload()
        }
      }
    })
    this.handleEchart()
  },
  mounted () {
    let that = this
    that.handMany()
    that.handleEchart()
  },
  methods: {
    handMany () {
      // 获取多人表格数据
      getAction(this.url.historyRecordScore, { masterId: this.masterId, username: this.username, type: 2 }).then(res => {
        if (res.success) {
          let data = []
          let arrs = null
          res.result.records.map(item => {
            this.manufacturing.push(item.reactiveScore)
            item.children.map((items, indexs) => {
              items.children.map((itemss, indexss) => {
                let str = Number(itemss.realWeight.toFixed(2).toString().split(".")[1]) || Number(itemss.realWeight * 100).toFixed(0)
                str += "%"
                let arr = Number(items.realWeight.toFixed(2).toString().split(".")[1])
                arr += "%"
                let strs = Number(item.realWeight.toFixed(2).toString().split(".")[1])
                strs += "%"
                let resData = null
                if (indexss === 0 && indexs === 0) {
                  let sum = 0
                  for (let i = 0; i < this.manufacturing.length; i++) {
                    sum += Number(this.manufacturing[i])
                  }
                  arrs = sum / this.manufacturing.length
                  resData = {
                    isHidden: itemss.isHidden,
                    optionContent: item.optionContent,
                    powerDpomain: items.optionContent,
                    abilitySubdomains: itemss.optionContent,
                    realWeight: str,
                    dpomain: arr,
                    factor: strs,
                    reactiveScore: item.reactiveScore.toFixed(1),
                    dpomain1: items.reactiveScore.toFixed(1),
                    subdomains1: itemss.reactiveScore.toFixed(1),
                  }
                } else {
                  resData = {
                    isHidden: itemss.isHidden,
                    optionContent: item.optionContent,
                    powerDpomain: items.optionContent,
                    abilitySubdomains: itemss.optionContent,
                    realWeight: str,
                    dpomain: indexss >= 1 || indexs >= 5 ? "" : arr,
                    factor: indexss >= 1 || indexs >= 1 ? "" : strs,
                    dpomain1: indexss >= 1 || indexs >= 5 ? "" : items.reactiveScore.toFixed(1),
                    subdomains1: itemss.reactiveScore.toFixed(1),
                  }
                }
                data.push(resData)
              })
            })
          })
          this.tableData1 = data.filter(item => {
            return item.isHidden
          })
          data.map(item => {
            item.manufacturing = arrs.toFixed(2)
            item.maturityLevel = item.manufacturing < 0.8 ? '不参与评级' : item.manufacturing < 1.8 ? '1级 规划级' : item.manufacturing < 2.8 ? '2级 规范级' : item.manufacturing < 3.8 ? '3级 集成级' : item.manufacturing < 4.8 ? '4级 优化级' : '5级 引领级'
            if (item.factor !== '') {
              this.factor.push(item.factor)
            }
            if (item.dpomain !== '') {
              this.dpomain.push(item.dpomain)
            }
            if (item.realWeight !== '') {
              this.realWeight.push(item.realWeight)
            }
          })
          this.getSpanArr(this.tableData1)
        } else {
          this.$message.error(res.message)
          if (res.message == '无法获得企业信息，请登记企业信息后再重试') {
            this.$router.push({ path: '/' })
            Vue.ls.remove(ACCESS_TOKEN)
            window.location.reload()
          }
        }
      })
    },
    handleOk (formName) {
      this.$refs[formName].validate((err) => {
        if (err) {
          let data = []
          let strFormData = Object.assign({}, this.form)
          let errerArr = []
          if (strFormData.radio == "" || strFormData.radio1 == "" || strFormData.radio2 == "" || strFormData.radio3 == "" || strFormData.radio4 == "" || strFormData.radio5 == "" || strFormData.radio6 == "" || strFormData.radio7 == "") {
            errerArr.push('请填写完再进行提交！')
          }
          if (errerArr.length > 0) {
            this.$message.error(errerArr[0])
            return
          }
          this.firstQuestion.map(item => {
            if (item.choose == strFormData.radio) {
              data.push(item)
            }
          })
          this.secondQuestion.map(item => {
            if (item.choose == strFormData.radio1) {
              data.push(item)
            }
          })
          this.thirdQuestion.map(item => {
            if (item.choose == strFormData.radio2) {
              data.push(item)
            }
          })
          this.fourthQuestion.map(item => {
            if (item.choose == strFormData.radio3) {
              data.push(item)
            }
          })
          this.fifthQroblem.map(item => {
            if (item.choose == strFormData.radio4) {
              data.push(item)
            }
          })
          this.sixthQuestion.map(item => {
            if (item.choose == strFormData.radio5) {
              data.push(item)
            }
          })
          this.seventhQuestion.map(item => {
            if (item.choose == strFormData.radio6) {
              data.push(item)
            }
          })
          this.eighthQuestion.map(item => {
            if (item.choose == strFormData.radio7) {
              data.push(item)
            }
          })
          if (strFormData.advice1 != "") {
            data.push({ propertyName: "advice1", title: "您不愿意向他人推荐的理由是？", chooseContent: strFormData.advice1 })
          }
          if (strFormData.advice2 != "") {
            data.push({ propertyName: "advice2", title: "如您对延嘉智能制造成熟度水平评价平台尚有其他不满意的地方，请告诉我们您最真实的想法，我们虚心听取您的宝贵建议", chooseContent: strFormData.advice2 })
          }
          delete strFormData.radio
          delete strFormData.radio1
          delete strFormData.radio2
          delete strFormData.radio3
          delete strFormData.radio4
          delete strFormData.radio5
          delete strFormData.radio6
          delete strFormData.radio7
          delete strFormData.advice1
          delete strFormData.advice2
          strFormData = {
            advice: JSON.stringify(data),
            totalScore: JSON.stringify(this.num)
          }
          postAction(this.url.enterpriseAdvice, strFormData).then(res => {
            if (res.success) {
              this.$message.success("提交成功，感谢您的评价与建议，祝您生活愉快！")
              this.dialogFormVisible = false
              this.$refs.form.resetFields()
            } else {
              this.$message.error(res.message)
              if (res.message == '无法获得企业信息，请登记企业信息后再重试') {
                this.$router.push({ path: '/' })
                Vue.ls.remove(ACCESS_TOKEN)
                window.location.reload()
              }
            }
          })
        } else {
          return false
        }
      })
    },
    // 表格突出显示
    tableRowClassName ({ rowIndex }) {
      if (this.level === "规划级" && rowIndex === 4) {
        this.remark = '企业应开始对实施智能制造的基础和条件进行规划，能够对核心业务（设计、生产、物流、销售、服务）进行流程化管理'
        return 'success-row'
      }
      if (this.level === "规范级" && rowIndex === 3) {
        this.remark = '企业应采用自动化技术、信息技术手段对核心装备和业务等进行改造和规范，实现单一业务的数据共享'
        return 'success-row'
      }
      if (this.level === "集成级" && rowIndex === 2) {
        this.remark = '企业应对装备、系统等开展集成，实现跨业务间的数据共享'
        return 'success-row'
      }
      if (this.level === "优化级" && rowIndex === 1) {
        this.remark = '企业应对人员、资源、制造等进行数据挖掘，形成知识模型等，实现对核心业务的精准预测和优化'
        return 'success-row'
      }
      if (this.level === "引领级" && rowIndex === 0) {
        this.remark = '企业应基于模型持续驱动业务优化和创新，实现产业链协同并衍生新的制造模式和商业模式'
        return 'success-row'
      }
    },
    // 图表数据
    handleEchart () {
      getAction(this.url.maturityModel, { masterId: this.masterId, username: this.username }).then(res => {
        if (res.success) {
          this.indicator = res.result[0].indicator
          let value1 = []
          res.result[0].data[0].value.map(item => {
            value1.push(Number(item.toFixed(1)))
          })
          res.result[0].data[0].value = value1
          this.dimensionScoresValue = res.result[0].data
          let valData = []
          let resData = null
          this.indicator.map((item, index) => {
            this.dimensionScoresValue[0].value.map((items, indexs) => {
              this.factor.map((itemes, indexes) => {
                if (index === indexs && index === indexes) {
                  resData = {
                    name: item.name,
                    value: items.toFixed(1),
                    factor: itemes
                  }
                  valData.push(resData)
                }
              })
            })
          })
          this.valueData = valData
          this.indicator1 = res.result[1].indicator
          let value2 = []
          res.result[1].data[0].value.map(item => {
            value2.push(Number(item.toFixed(1)))
          })
          res.result[1].data[0].value = value2
          this.dimensionScoresValue1 = res.result[1].data
          let valData1 = []
          let resData1 = null
          this.indicator1.map((item, index) => {
            this.dimensionScoresValue1[0].value.map((items, indexs) => {
              this.dpomain.map((itemes, indexes) => {
                if (index === indexs && index === indexes) {
                  resData1 = {
                    name: item.name,
                    value: items.toFixed(1),
                    dpomain: itemes
                  }
                  valData1.push(resData1)
                }
              })
            })
          })
          this.valueData1 = valData1
        } else {
          this.$message.error(res.message)
          if (res.message == '无法获得企业信息，请登记企业信息后再重试') {
            this.$router.push({ path: '/' })
            Vue.ls.remove(ACCESS_TOKEN)
            window.location.reload()
          }
        }
      })
    },
    // 合并表格
    getSpanArr (data) {
      this.spanArr = []
      this.spanCodeArr = []
      this.spanData = []
      this.spanDataAll = []
      for (var i = 0; i < data.length; i++) {
        if (i === 0) {
          this.spanArr.push(1)
          this.spanCodeArr.push(1)
          this.spanData.push(1)
          this.spanDataAll.push(1)
          this.pos = 0
          this.codePos = 0
          this.abPos = 0
          this.maPos = 0
          this.matPos = 0
        } else {
          if (data[i].optionContent === data[i - 1].optionContent) {
            this.spanArr[this.pos] += 1
            this.spanArr.push(0)
          } else {
            this.spanArr.push(1)
            this.pos = i
          }
          if (data[i].powerDpomain === data[i - 1].powerDpomain) {
            this.spanCodeArr[this.codePos] += 1
            this.spanCodeArr.push(0)
          } else {
            this.spanCodeArr.push(1)
            this.codePos = i
          }
          if (data[i].manufacturing === data[i - 1].manufacturing) {
            this.spanData[this.maPos] += 1
            this.spanData.push(0)
          } else {
            this.spanData.push(1)
            this.maPos = i
          }
          if (data[i].maturityLevel === data[i - 1].maturityLevel) {
            this.spanDataAll[this.matPos] += 1
            this.spanDataAll.push(0)
          } else {
            this.spanDataAll.push(1)
            this.matPos = i
          }
        }
      }
    },
    objectSpanMethod ({ rowIndex, columnIndex }) {
      if (columnIndex == 1) {
        const _row = this.spanArr[rowIndex]
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
      if (columnIndex == 2) {
        const _row = this.spanCodeArr[rowIndex]
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
      if (columnIndex == 4) {
        const _row = this.spanArr[rowIndex]
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
      if (columnIndex == 5) {
        const _row = this.spanCodeArr[rowIndex]
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
      if (columnIndex == 8) {
        const _row = this.spanCodeArr[rowIndex]
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
      if (columnIndex == 9) {
        const _row = this.spanArr[rowIndex]
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
      if (columnIndex == 10) {
        const _row = this.spanData[rowIndex]
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
      if (columnIndex == 11) {
        const _row = this.spanDataAll[rowIndex]
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
    }
  }
}
</script>

<style>
.el-main {
  text-align: left !important;
  padding: 0 !important;
}
h2 {
  font-size: 24px;
}
.el-table__header tr,
.el-table__header th {
  padding: 0;
  height: 40px;
}
.el-table__body tr,
.el-table__body td {
  padding: 0;
  height: 40px;
}
.el-table .success-row {
  background-color: #409eff;
  color: #fff;
}
.el-dialog__title {
  font-weight: 600;
  font-size: 22px !important;
}
.el-textarea {
  font-size: 18px !important;
}
.backdrop {
  height: 440px;
  background: #f4f5f7;
}
.list {
  margin-top: 20px;
}
.list p {
  font-size: 18px;
  margin-bottom: 10px;
}
.list .el-radio__label {
  font-size: 18px;
}
.list .el-radio__inner {
  width: 20px !important;
  height: 20px !important;
  border: 1px solid #4d4b4b !important;
}
.el-dialog__footer {
  padding: 0 20px 20px !important;
}
</style>
