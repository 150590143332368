<template>
  <!-- 能力要素、能力（子）域权重表 -->
  <div id="weightChoose">
    <div class="subject">
      <h2 style="text-align:center; margin-bottom:20px">能力要素、能力（子）域权重表</h2>
      <div>
        <p style="margin-bottom:10px;font-size:14px;">若基本信息界面有能力子域未选择，则该能力子域不宣示，对应权重数值默认按比例分配至该能力域其他子域。</p>
        <p style="margin-bottom:20px;font-size:14px;">若某能力要素或能力域内能力子域在基本信息界面全部未选择，则该能力要素或能力域在本界面不显示，对应权重默认按比例分配至该能力要素其他能力域或其他能力要素。</p>
      </div>
      <el-table v-loading="loading"
                :data="tableData"
                border
                highlight-current-row
                :span-method="objectSpanMethod"
                style="width: 100%; margin-bottom:10px">
        <el-table-column align="center"
                         type="index">
        </el-table-column>
        <el-table-column label="能力要素"
                         align="center">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.optionContent }}</span>
          </template>
        </el-table-column>
        <el-table-column label="能力域"
                         align="center">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.powerDpomain }}</span>
          </template>
        </el-table-column>
        <el-table-column label="能力子域"
                         align="center">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.abilitySubdomains }}</span>
          </template>
        </el-table-column>
        <el-table-column label="权重"
                         align="center">
          <el-table-column label="能力要素"
                           align="center">
            <template slot-scope="scope">
              <el-input v-model="scope.row.factor"
                        @blur="BlurText($event, scope.row)"
                        v-if="scope.row.seen"
                        type="number"> </el-input>
              <span style="margin-left: 10px"
                    v-else>{{ scope.row.factor }}</span>
            </template>
          </el-table-column>
          <el-table-column label="能力域"
                           align="center">
            <template slot-scope="scope">
              <el-input v-model="scope.row.dpomain"
                        v-if="scope.row.seen"
                        @blur="BlurText($event, scope.row)"
                        type="number"> </el-input>
              <span style="margin-left: 10px"
                    v-else>{{ scope.row.dpomain }}</span>
            </template>
          </el-table-column>
          <el-table-column label="能力子域"
                           align="center">
            <template slot-scope="scope">
              <el-input v-model="scope.row.categoryDefaultWeight"
                        v-if="scope.row.seen"
                        @blur="BlurText($event, scope.row)"
                        type="number"> </el-input>
              <span style="margin-left: 10px"
                    v-else>{{ scope.row.categoryDefaultWeight }}</span>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column label="权重调整"
                         align="center">
          <template slot-scope="scope">
            <el-button size="mini"
                       style="color:#000"
                       @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="overflow: hidden;margin-bottom:20px;">
        <el-button type="primary"
                   @click="handleOk"
                   style="float:right;">确认权重</el-button>
      </div>
      <!-- <div style="overflow: hidden;">
        <router-link :to="{ path: '/weightAdjustment', query: {recordType: this.recordType} }">
          <el-button type="primary"
                     style="float:right;">能力子域级别权重调整</el-button>
        </router-link>
      </div>
      <div style="overflow: hidden;">
        <router-link :to="{ path: '/maturityAdjustment', query: {recordType: this.recordType} }">
          <el-button type="primary"
                     style="float:right;">成熟度要求权重调整</el-button>
        </router-link>
      </div> -->
    </div>
  </div>
</template>

<script>
import { getAction, postAction } from '@/api/manage'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import Vue from 'vue'
export default {
  name: 'weightChoose',
  components: {
  },
  data () {
    return {
      loading: true,
      tableData: [],
      dataSource: [],
      recordType: null,
      spanArr: [],//用于存放每一行记录的合并数
      spanCodeArr: [],
      pos: '',
      codePos: '',
      url: {
        list: '/maturityTemplate/statistical_table', // 获取表格数据
        submit: '/recordMaster/upload_record_table' // 提交数据
      }
    }
  },
  async created () {
    this.loading = true
    this.recordType = this.$route.query.recordType
    let tableData = JSON.parse(sessionStorage.getItem('tableData'))
    let dataSource = JSON.parse(sessionStorage.getItem('dataSource'))
    if (tableData) {
      this.tableData = tableData
      this.dataSource = dataSource
      return
    }
    await getAction(this.url.list, { templateType: this.recordType }).then(res => {
      if (res.success) {
        this.dataSource = res.result
        let tableData = []
        res.result.map((item, index) => {
          item.seen = false
          item.children.map((items, indexs) => {
            items.children.map((itemss, indexss) => {
              let str = Number(itemss.categoryDefaultWeight * 100).toFixed(0)
              str += "%"
              let arr = Number(items.categoryDefaultWeight * 100).toFixed(0)
              arr += "%"
              let strs = Number(item.categoryDefaultWeight * 100).toFixed(0)
              strs += "%"
              let resData = null
              if (indexss === 0 && indexs === 0) {
                resData = {
                  seen: false,
                  id: index + '-' + indexs + '-' + indexss,
                  isHidden: itemss.isHidden,
                  optionContent: item.optionContent,
                  powerDpomain: items.optionContent,
                  abilitySubdomains: itemss.optionContent,
                  categoryDefaultWeight: str,
                  dpomain: arr,
                  factor: strs
                }
              } else {
                resData = {
                  seen: false,
                  id: index + '-' + indexs + '-' + indexss,
                  isHidden: itemss.isHidden,
                  optionContent: item.optionContent,
                  powerDpomain: items.optionContent,
                  abilitySubdomains: itemss.optionContent,
                  categoryDefaultWeight: str,
                  dpomain: indexss >= 1 || indexs >= 5 ? "" : arr,
                  factor: indexss >= 1 || indexs >= 1 ? "" : strs
                }
              }
              tableData.push(resData)
            })
          })
        })
        this.tableData = tableData.filter(item => {
          return item.isHidden
        })
      } else {
        this.$message.error(res.message)
        if (res.message == '无法获得企业信息，请登记企业信息后再重试') {
          this.$router.push({ path: '/' })
          Vue.ls.remove(ACCESS_TOKEN)
          window.location.reload()
        }
      }
    })
  },
  mounted () {
    let that = this
    setTimeout(() => {
      that.getSpanArr(that.tableData)
      this.loading = false
    }, 500)
  },
  beforeDestroy () {
    sessionStorage.setItem("tableData", JSON.stringify(this.tableData))
    sessionStorage.setItem("dataSource", JSON.stringify(this.dataSource))
  },
  methods: {
    getSpanArr (data) {
      this.spanArr = []
      this.spanCodeArr = []
      for (var i = 0; i < data.length; i++) {
        if (i === 0) {
          this.spanArr.push(1)
          this.spanCodeArr.push(1)
          this.pos = 0
          this.codePos = 0
        } else {
          if (data[i].optionContent === data[i - 1].optionContent) {
            this.spanArr[this.pos] += 1
            this.spanArr.push(0)
          } else {
            this.spanArr.push(1)
            this.pos = i
          }

          if (data[i].powerDpomain === data[i - 1].powerDpomain) {
            this.spanCodeArr[this.codePos] += 1
            this.spanCodeArr.push(0)
          } else {
            this.spanCodeArr.push(1)
            this.codePos = i
          }
        }
      }
    },
    objectSpanMethod ({ rowIndex, columnIndex }) {
      if (columnIndex == 1) {
        const _row = this.spanArr[rowIndex]
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
      if (columnIndex == 2) {
        const _row = this.spanCodeArr[rowIndex]
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
      if (columnIndex == 4) {
        const _row = this.spanArr[rowIndex]
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
      if (columnIndex == 5) {
        const _row = this.spanCodeArr[rowIndex]
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
    },
    BlurText (e, row) {
      let boolean = new RegExp("^[1-9][0-9]*$").test(e.target.value)
      if (!boolean) {
        this.$message.warning('请输入正整数')
        if (row.factor == e.target.value) {
          row.factor = ""
        }
        if (row.factor == e.target.value) {
          row.dpomain = ""
        }
        if (row.factor == e.target.value) {
          row.categoryDefaultWeight = ""
        }
        e.target.value = ""
      }
    },
    handleEdit (index, row) {
      this.tableData.map(item => {
        if (item.seen) {
          let arr = item.id.split("-")
          if (!isNaN(item.factor)) {
            this.dataSource[arr[0]].categoryDefaultWeight = parseInt(item.factor) / 100
          }
          if (!isNaN(item.dpomain)) {
            this.dataSource[arr[0]].children[arr[1]].categoryDefaultWeight = parseInt(item.dpomain) / 100
          }
          // this.dataSource[arr[0]].categoryDefaultWeight = parseInt(item.factor) / 100
          // this.dataSource[arr[0]].children[arr[1]].categoryDefaultWeight = parseInt(item.dpomain) / 100
          this.dataSource[arr[0]].children[arr[1]].children[arr[2]].categoryDefaultWeight = parseInt(item.categoryDefaultWeight) / 100
          item.categoryDefaultWeight = item.categoryDefaultWeight.indexOf('%') !== -1 || !item.categoryDefaultWeight ? item.categoryDefaultWeight : item.categoryDefaultWeight + '%'
          item.dpomain = item.dpomain.indexOf('%') !== -1 || !item.dpomain ? item.dpomain : item.dpomain + '%'
          item.factor = item.factor.indexOf('%') !== -1 || !item.factor ? item.factor : item.factor + '%'
          if (item.categoryDefaultWeight == 'NaN%') {
            item.categoryDefaultWeight = ""
          }
          if (item.factor == 'NaN%') {
            item.factor = ""
          }
          if (item.dpomain == 'NaN%') {
            item.dpomain = ""
          }
          item.seen = false
        }
      })
      row.categoryDefaultWeight = parseInt(row.categoryDefaultWeight) + ""
      row.dpomain = parseInt(row.dpomain) + ""
      row.factor = parseInt(row.factor) + ""
      row.seen = true
    },
    handleOk () {
      let that = this
      let recordMaster = {}
      let records = []
      this.tableData.map(item => {
        if (item.seen) {
          let arr = item.id.split("-")
          if (!isNaN(item.factor)) {
            this.dataSource[arr[0]].categoryDefaultWeight = parseInt(item.factor) / 100
          }
          if (!isNaN(item.dpomain)) {
            this.dataSource[arr[0]].children[arr[1]].categoryDefaultWeight = parseInt(item.dpomain) / 100
          }
          this.dataSource[arr[0]].children[arr[1]].children[arr[2]].categoryDefaultWeight = parseInt(item.categoryDefaultWeight) / 100
          item.categoryDefaultWeight = item.categoryDefaultWeight.indexOf('%') !== -1 || !item.categoryDefaultWeight ? item.categoryDefaultWeight : item.categoryDefaultWeight + '%'
          item.dpomain = item.dpomain.indexOf('%') !== -1 || !item.dpomain ? item.dpomain : item.dpomain + '%'
          item.factor = item.factor.indexOf('%') !== -1 || !item.factor ? item.factor : item.factor + '%'
          if (item.categoryDefaultWeight == 'NaN%') {
            item.categoryDefaultWeight = ""
          }
          if (item.factor == 'NaN%') {
            item.factor = ""
          }
          if (item.dpomain == 'NaN%') {
            item.dpomain = ""
          }
          item.seen = false
        }
      })
      this.dataSource.map(item => {
        item.templateId = item.id
        item.children.map(items => {
          items.templateId = items.id
          items.children.map(itemes => {
            itemes.templateId = itemes.id
            itemes.children.map(itemess => {
              itemess.templateId = itemess.id
              itemess.children.map(itemeses => {
                itemeses.templateId = itemeses.id
              })
            })
          })
        })
      })
      let firstTotal = 0
      for (let i = 0; i < that.dataSource.length; i++) {
        // that.dataSource[i].categoryDefaultWeight = isNaN(that.dataSource[i].categoryDefaultWeight) ? 0 : that.dataSource[i].categoryDefaultWeight
        firstTotal += that.dataSource[i].categoryDefaultWeight * 100
        let secondTotal = 0
        for (let j = 0; j < that.dataSource[i].children.length; j++) {
          // that.dataSource[i].children[j].categoryDefaultWeight = isNaN(that.dataSource[i].children[j].categoryDefaultWeight) ? 0 : that.dataSource[i].children[j].categoryDefaultWeight
          secondTotal += that.dataSource[i].children[j].categoryDefaultWeight * 100
          let lastTotal = 0
          for (let k = 0; k < that.dataSource[i].children[j].children.length; k++) {
            // that.dataSource[i].children[j].children[k].categoryDefaultWeight = isNaN(that.dataSource[i].children[j].children[k].categoryDefaultWeight) ? 0 : that.dataSource[i].children[j].children[k].categoryDefaultWeight
            lastTotal += that.dataSource[i].children[j].children[k].categoryDefaultWeight * 100
          }
          if (parseInt(lastTotal) !== 100) {
            that.$message.error("请检查" + that.dataSource[i].children[j].optionContent + "中的能力子域总和是否已超过100%或者不足100%！")
            return
          }
        }
        if (parseInt(secondTotal) !== 100) {
          that.$message.error("请检查" + that.dataSource[i].optionContent + "中的能力域总和是否已超过100%或者不足100%！")
          return
        }
      }
      if (parseInt(firstTotal) !== 100) {
        that.$message.error("请检查能力要素总和是否已超过100%或者不足100%！")
        return
      }
      records = that.dataSource
      recordMaster = {
        recordType: that.recordType,
        records: records
      }
      this.loading = true
      postAction(that.url.submit, recordMaster).then(res => {
        if (res.success) {
          this.loading = false
          sessionStorage.removeItem('currentData')
          sessionStorage.removeItem('surveyDataSource')
          sessionStorage.removeItem('newPage')
          sessionStorage.setItem('surveyDataSource', JSON.stringify(res.result))
          that.$message.success("提交成功！")
          that.$router.push({ path: '/questionnaire', query: { recordType: res.result.recordType } })
        } else {
          this.loading = false
          that.$message.error(res.message)
          if (res.message == '无法获得企业信息，请登记企业信息后再重试') {
            this.$router.push({ path: '/' })
            Vue.ls.remove(ACCESS_TOKEN)
            window.location.reload()
          }
        }
      })
    }
  }
}
</script>

<style>
.el-main {
  text-align: left !important;
  padding: 0 !important;
}
h2 {
  font-size: 24px;
}
.el-table td {
  padding: 0 !important;
}
.el-button {
  margin: 3px auto !important;
}
.el-table--border th,
.el-table__fixed-right-patch {
  border-bottom: 1px solid #a1a3a6 !important;
  border-right: 1px solid #a1a3a6 !important;
}
</style>
