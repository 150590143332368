import Vue from 'vue'
import {
  SIDEBAR_TYPE,
  DEFAULT_THEME,
  DEFAULT_LAYOUT_MODE,
  DEFAULT_COLOR,
  DEFAULT_COLOR_WEAK,
  DEFAULT_FIXED_HEADER,
  DEFAULT_FIXED_SIDEMENU,
  DEFAULT_FIXED_HEADER_HIDDEN,
  DEFAULT_CONTENT_WIDTH_TYPE,
  DEFAULT_MULTI_PAGE,
  LOCALE,
  ENABLE_I18N,
  TABLE_BORDERED,
  MODAL_BTN_RIGHT,
  // IS_TAB_HOME_PAGE, // 福田项目用于筛选显示友情链接
  ENABLE_SCROLL_BAR,
  CORP_CHOOSE_TYPE
} from '@/store/mutation-types'

const app = {
  state: {
    sidebar: {
      opened: true,
      withoutAnimation: false
    },
    // device: 'desktop',
    theme: '',
    layout: '',
    contentWidth: '',
    fixedHeader: false,
    fixSiderbar: false,
    autoHideHeader: false,
    color: null,
    weak: false,
    multipage: true, // 默认多页签模式
    listRefresh: false,
    openFZFlag: false, // 是否需要调用方正接口
    FZLoginUrl: 'http://cloudepc.simulate.com:8080/cloudpm/servlet/LoginServlet.cmd', // 方正登录接口
    locale: null,
    enableI18n: false,
    tableBordered: true,
    modalBtnRight: true,
    isTabHomepage: false,
    enableScrollBar: true,
    corpChooseType: 'select',
    prjCode: ''
  },
  mutations: {
    SET_SIDEBAR_TYPE: (state, type) => {
      state.sidebar.opened = type
      Vue.ls.set(SIDEBAR_TYPE, type)
    },
    CLOSE_SIDEBAR: (state, withoutAnimation) => {
      Vue.ls.set(SIDEBAR_TYPE, true)
      state.sidebar.opened = false
      state.sidebar.withoutAnimation = withoutAnimation
    },
    // TOGGLE_DEVICE: (state, device) => {
    //   // state.device = device
    //   // 福田固定
    //   state.device = 'desktop'
    // },
    TOGGLE_THEME: (state, theme) => {
      // setStore('_DEFAULT_THEME', theme)
      Vue.ls.set(DEFAULT_THEME, theme)
      state.theme = theme
    },
    TOGGLE_LAYOUT_MODE: (state, layout) => {
      Vue.ls.set(DEFAULT_LAYOUT_MODE, layout)
      // state.layout = layout
      // 福田固定
      state.layout = 'topsidemenu_futian'
    },
    TOGGLE_FIXED_HEADER: (state, fixed) => {
      Vue.ls.set(DEFAULT_FIXED_HEADER, fixed)
      state.fixedHeader = fixed
    },
    TOGGLE_FIXED_SIDERBAR: (state, fixed) => {
      Vue.ls.set(DEFAULT_FIXED_SIDEMENU, fixed)
      state.fixSiderbar = fixed
    },
    TOGGLE_FIXED_HEADER_HIDDEN: (state, show) => {
      Vue.ls.set(DEFAULT_FIXED_HEADER_HIDDEN, show)
      state.autoHideHeader = show
    },
    TOGGLE_CONTENT_WIDTH: (state, type) => {
      Vue.ls.set(DEFAULT_CONTENT_WIDTH_TYPE, type)
      state.contentWidth = type
    },
    TOGGLE_COLOR: (state, color) => {
      Vue.ls.set(DEFAULT_COLOR, color)
      state.color = color
    },
    TOGGLE_WEAK: (state, flag) => {
      Vue.ls.set(DEFAULT_COLOR_WEAK, flag)
      state.weak = flag
    },
    SET_MULTI_PAGE (state, multipageFlag) {
      Vue.ls.set(DEFAULT_MULTI_PAGE, multipageFlag)
      state.multipage = multipageFlag
    },
    listUpdate (state) {
      console.log('更改前', state.listRefresh)
      state.listRefresh = !state.listRefresh
      console.log('更改后', state.listRefresh)
    },
    TOGGLE_LOCALE (state, locale) {
      // console.log('ccccccccccccccc')
      Vue.ls.set(LOCALE, locale)
      state.locale = locale
    },
    TOGGLE_I18N (state, enableI18n) {
      Vue.ls.set(ENABLE_I18N, enableI18n)
      state.enableI18n = enableI18n
    },
    TOGGLE_TABLE_BORDER (state, tableBordered) {
      Vue.ls.set(TABLE_BORDERED, tableBordered)
      state.tableBordered = tableBordered
    },
    TOGGLE_MODAL_BTN_RIGHT (state, modalBtnRight) {
      Vue.ls.set(MODAL_BTN_RIGHT, modalBtnRight)
      state.modalBtnRight = modalBtnRight
    },
    // IS_TAB_HOME_PAGE (state, isTabHomepage) {
    //   state.isTabHomepage = isTabHomepage
    // },
    ENABLE_SCROLL_BAR (state, enableScrollBar) {
      Vue.ls.set(ENABLE_SCROLL_BAR, enableScrollBar)
      state.enableScrollBar = enableScrollBar
    },
    TOGGLE_CORP_CHOOSE_TYPE (state, corpChooseType) {
      Vue.ls.set(CORP_CHOOSE_TYPE, corpChooseType)
      state.corpChooseType = corpChooseType
    }
  },
  actions: {
    setSidebar: ({ commit }, type) => {
      commit('SET_SIDEBAR_TYPE', type)
    },
    CloseSidebar ({ commit }, { withoutAnimation }) {
      commit('CLOSE_SIDEBAR', withoutAnimation)
    },
    // ToggleDevice ({ commit }, device) {
    //   commit('TOGGLE_DEVICE', device)
    // },
    ToggleTheme ({ commit }, theme) {
      commit('TOGGLE_THEME', theme)
    },
    ToggleLayoutMode ({ commit }, mode) {
      commit('TOGGLE_LAYOUT_MODE', mode)
    },
    ToggleFixedHeader ({ commit }, fixedHeader) {
      if (!fixedHeader) {
        commit('TOGGLE_FIXED_HEADER_HIDDEN', false)
      }
      commit('TOGGLE_FIXED_HEADER', fixedHeader)
    },
    ToggleFixSiderbar ({ commit }, fixSiderbar) {
      commit('TOGGLE_FIXED_SIDERBAR', fixSiderbar)
    },
    ToggleFixedHeaderHidden ({ commit }, show) {
      commit('TOGGLE_FIXED_HEADER_HIDDEN', show)
    },
    ToggleContentWidth ({ commit }, type) {
      commit('TOGGLE_CONTENT_WIDTH', type)
    },
    ToggleColor ({ commit }, color) {
      commit('TOGGLE_COLOR', color)
    },
    ToggleWeak ({ commit }, weakFlag) {
      commit('TOGGLE_WEAK', weakFlag)
    },
    ToggleMultipage ({ commit }, multipageFlag) {
      commit('SET_MULTI_PAGE', multipageFlag)
    },
    ToggleI18n ({ commit }, enableI18n) {
      commit('TOGGLE_I18N', enableI18n)
    },
    ToggleTableBorder ({ commit }, tableBordered) {
      commit('TOGGLE_TABLE_BORDER', tableBordered)
    },
    ToggleModalBtnRight ({ commit }, modalBtnRight) {
      commit('TOGGLE_MODAL_BTN_RIGHT', modalBtnRight)
    },
    ToggleCorpChooseType ({ commit }, corpChooseType) {
      commit('TOGGLE_CORP_CHOOSE_TYPE', corpChooseType)
    }
  }
}

export default app
