<template>
  <!-- 能力子域级别权重调整 -->
  <div id="weightAdjustment">
    <div class="subject">
      <h2 style="text-align:center; margin-bottom:20px">能力子域级别权重调整</h2>
      <div style="float: right;margin-bottom: 10px;">
        <el-button @click="handleEdit"
                   type="primary"
                   style="margin-right:10px">编辑</el-button>
        <el-button @click="handleOk"
                   type="primary">保存</el-button>
      </div>
      <el-table :data="tableData"
                border
                style="width: 100%; margin-bottom:10px">
        <el-table-column align="center"
                         type="index">
        </el-table-column>
        <el-table-column label="能力子域"
                         header-align="center">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.abilitySubdomains }}</span>
          </template>
        </el-table-column>
        <el-table-column label="能力子域级别权重"
                         header-align="center">
          <el-table-column label="1级"
                           align="center">
            <template slot-scope="scope">
              <el-input v-model="scope.row.level1"
                        v-if="scope.row.seen"
                        @blur="loseFcous(scope.$index, scope.row)"> </el-input>
              <span style="margin-left: 10px"
                    v-else>{{ scope.row.level1 }}</span>
            </template>
          </el-table-column>
          <el-table-column label="2级"
                           align="center">
            <template slot-scope="scope">
              <el-input v-model="scope.row.level2"
                        v-if="scope.row.seen"
                        @blur="loseFcous(scope.$index, scope.row)"> </el-input>
              <span style="margin-left: 10px"
                    v-else>{{ scope.row.level2 }}</span>
            </template>
          </el-table-column>
          <el-table-column label="3级"
                           align="center">
            <template slot-scope="scope">
              <el-input v-model="scope.row.level3"
                        v-if="scope.row.seen"
                        @blur="loseFcous(scope.$index, scope.row)"> </el-input>
              <span style="margin-left: 10px"
                    v-else>{{ scope.row.level3 }}</span>
            </template>
          </el-table-column>
          <el-table-column label="4级"
                           align="center">
            <template slot-scope="scope">
              <el-input v-model="scope.row.level4"
                        v-if="scope.row.seen"
                        @blur="loseFcous(scope.$index, scope.row)"> </el-input>
              <span style="margin-left: 10px"
                    v-else>{{ scope.row.level4 }}</span>
            </template>
          </el-table-column>
          <el-table-column label="5级"
                           align="center">
            <template slot-scope="scope">
              <el-input v-model="scope.row.level5"
                        v-if="scope.row.seen"
                        @blur="loseFcous(scope.$index, scope.row)"> </el-input>
              <span style="margin-left: 10px"
                    v-else>{{ scope.row.level5 }}</span>
            </template>
          </el-table-column>
        </el-table-column>
        <!-- <el-table-column label="操作"
                         align="center">
          <template slot-scope="scope">
            <el-button @click="handleEdit(scope.$index, scope.row)"
                       style="margin-right:10px">编辑</el-button>
            <el-button @click="handleOk(scope.$index, scope.row)">保存</el-button>
          </template>
        </el-table-column> -->
      </el-table>
      <p style="margin-top:20px; margin-bottom:20px">能力子域级别权重默认100%，表格内数据为不可编辑状态；若点击调整权重按钮，则表格内数据为可编辑状态；点击确认权重，记录数据，表格内数据为不可编辑状态。</p>
      <p style="margin-bottom:20px">此表格数据由延嘉内部人员修改，使用人员跳过该页面。</p>
      <el-backtop></el-backtop>
    </div>
  </div>
</template>

<script>
import { getAction, postAction } from '@/api/manage'
export default {
  name: 'weightAdjustment',
  components: {
  },
  data () {
    return {
      templateType: '',
      tableData: [],
      url: {
        list: '/maturityTemplate/statistical_table',
        edit: '/maturityTemplate/editAll'
      }
    }
  },
  created () {
    this.templateType = this.$route.query.recordType
    getAction(this.url.list, { templateType: this.templateType }).then(res => {
      let tableData = []
      this.dataSource = res.result
      res.result.map(item => {
        item.seen = false
        item.children.map(items => {
          items.children.map(itemss => {
            let resData = null
            itemss.children.map(itemes => {
              let str = Number(itemes.categoryDefaultWeight * 100)
              str += "%"
              if (itemes.optionContent == '1级') {
                itemss.level1 = str
              }
              if (itemes.optionContent == '2级') {
                itemss.level2 = str
              }
              if (itemes.optionContent == '3级') {
                itemss.level3 = str
              }
              if (itemes.optionContent == '4级') {
                itemss.level4 = str
              }
              if (itemes.optionContent == '5级') {
                itemss.level5 = str
              }
            })
            resData = {
              seen: false,
              abilitySubdomains: itemss.optionContent,
              level1: itemss.level1,
              level2: itemss.level2,
              level3: itemss.level3,
              level4: itemss.level4,
              level5: itemss.level5
            }
            tableData.push(resData)
          })
        })
      })
      this.tableData = tableData
    })
  },
  methods: {
    handleEdit () {
      this.tableData.map(item => {
        item.seen = true
      })
    },
    loseFcous (index, row) {
      console.log(index, row)
      this.dataSource.map(item => {
        item.children.map(items => {
          items.children.map(itemss => {
            delete itemss.level1
            delete itemss.level2
            delete itemss.level3
            delete itemss.level4
            delete itemss.level5
            itemss.children.map(itemes => {
              if (itemss.optionContent == row.abilitySubdomains) {
                itemes.categoryDefaultWeight = itemes.optionContent == '1级' ? parseInt(row.level1) / 100 : (itemes.optionContent == '2级' ? parseInt(row.level2) / 100 : (itemes.optionContent == '3级' ? parseInt(row.level3) / 100 : (itemes.optionContent == '4级' ? parseInt(row.level4) / 100 : parseInt(row.level5) / 100)))
              }
            })
          })
        })
      })
      row.level1 = row.level1.indexOf('%') !== -1 || !row.level1 ? row.level1 : row.level1 + '%'
      row.level2 = row.level2.indexOf('%') !== -1 || !row.level2 ? row.level2 : row.level2 + '%'
      row.level3 = row.level3.indexOf('%') !== -1 || !row.level3 ? row.level3 : row.level3 + '%'
      row.level4 = row.level4.indexOf('%') !== -1 || !row.level4 ? row.level4 : row.level4 + '%'
      row.level5 = row.level5.indexOf('%') !== -1 || !row.level5 ? row.level5 : row.level5 + '%'
      row.seen = false
    },
    handleOk () {
      this.tableData.map(item => {
        item.seen = false
      })
      let maturityTemplateList = this.dataSource
      postAction(this.url.edit, maturityTemplateList).then(res => {
        if (res.success) {
          this.$message.success("提交成功！")
        } else {
          this.$message.error(res.message)
        }
      })
    }
  }
}
</script>

<style>
.el-main {
  text-align: left !important;
  padding: 0 !important;
}
h2 {
  font-size: 24px;
}
.el-table td {
  padding: 0 !important;
}
.el-button {
  margin: 3px 5px !important;
}
</style>
