
const prj = {
  state: {
    prjInfo: {
      prjName: '',
      prjNo: '',
      // prjManagerName: '',
      // prjManagerFullname: '',
      // prjLevel: '',
      // prjArea: '',
      prjStartDate: '',
      // prjEndDate: '',
      // prjDeptName: '',
      // prjClass: '',
      prjCode: '',
      //  projectId: '',
      guid: '',
      prjType_dictText: '',
      prjLocation: '',
      createDepartmentName: '',
      prjLeader: '',
      prjLeaderFullname: '',
      constructionUnit: '',
      telNum: '',
      corpCode: '',
      prjSummary: '',
      investmentAmount: ''

    },
    contInfo: {
      contName: '',
      contNo: '',
      contAmount: '',
      contOwner: ''
    },
    sideMenu:''
  },
  mutations: {
    SET_PRJ_INFO: (state, prjInfo) => {
      state.prjInfo.prjName = prjInfo.prjName
      state.prjInfo.prjNo = prjInfo.prjNo
      state.prjInfo.prjLeader = prjInfo.prjLeader
      state.prjInfo.prjLeaderFullname = prjInfo.prjLeaderFullname
      //      state.prjInfo.prjLevel = prjInfo.prjLevel
      state.prjInfo.prjLocation = prjInfo.prjLocation
      state.prjInfo.prjStartDate = prjInfo.prjStartDate
      //    state.prjInfo.prjEndDate = prjInfo.prjEndDate
      state.prjInfo.createDepartmentName = prjInfo.createDepartmentName
      //    state.prjInfo.prjClass = prjInfo.prjClass
      state.prjInfo.prjCode = prjInfo.prjCode
      state.prjInfo.guid = prjInfo.guid
      state.prjInfo.prjType_dictText = prjInfo.prjType_dictText
    },
    SET_CONT_INFO: (state, contInfo) => {
      state.contInfo.contName = contInfo.contName
      state.contInfo.contNo = contInfo.contNo
      state.contInfo.contAmount = contInfo.contAmount
      state.contInfo.contOwner = contInfo.contOwner
    },
    SET_Side_MENU: (state, sideMenu) =>{
      state.sideMenu = sideMenu
    }
  },
  actions: {
  }
}

export default prj
